export type Seeker = {
  id: string;
  firstName: string;
  lastName: string;
  flexibleTransportation?: boolean;
  experiences?: Experience[];
  educations?: Education[];
  titles?: string[];
  locale?: string;
  lastActivity?: Date;
  lookingForWork?: boolean;
  displayProfilePicture?: boolean;
  lookingForWorkDisabledAt?: Date;
  lookingForWorkReminderSentAt?: Date;
  generatedData?: Date;
  deactivated?: boolean;
  scheduledDeletion?: boolean;
  newAvailabilities?: { [key: string]: { start_1: string; end_1: string } };
  nightAvailabilities?: { [key: string]: { start_1: string; end_1: string } };
  noticePeriod?: {
    unit: string;
    value: string;
  };
  expectedWorkHour?: {
    unit: string;
    value: string;
  };
  expectedSalary?: {
    unit: string;
    value: string;
  };
  offeredOrHiredAccounts?: string[];
  availabilityUpdatedAt?: Date;
  isStudent?: boolean;
  isAdult?: boolean;
};

export type Experience = {
  business?: string;
  current: boolean;
  duration: number;
  id: string;
  industry: number;
  job: string;
  jobTitle: string;
  occupation: number;
  startMonth?: string;
  startYear?: string;
};

export type Education = {
  id: string;
  city: string;
  schoolName: string;
  degree: string;
  otherDegree: string;
  fieldOfStudy: string;
  status: EducationStatus;
  endDate?: {
    year: string;
    month: string;
  };
};

export enum EducationStatus {
  ongoing = 'ongoing',
  completed = 'completed',
  dropout = 'dropout',
}

export const OTHER_DEGREE = 'EDUCATION_LEVEL_OTHERS';
