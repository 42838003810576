import { Action, action, Thunk, thunk } from 'easy-peasy';
import moment from 'moment-timezone';
import { AxiosResponse } from 'axios';
import { CalendarEventPresenter } from '../calendarEventPresenter';
import { HttpStoreModel } from '../../../http/models/httpModel';
import { CalendarController } from '../../../controllers/calendarController';
import HttpClientWithRetry from '../../../http/client/HttpClientWithRetry';
import { AppointmentType } from '../../../firebase/firestore/documents/appointmentAvaiability';
import { GroupEvent } from '../../../firebase/firestore/documents/groupEvent';
import CalendarPresenter from '../calendarPresenter';
import { EventTokenResponse, GroupEventResponse } from '../events/CalendarParticipantType';
import { UserController } from '../../../controllers/userController';
import {
  CalendarEventType,
  GoogleEventsType,
  OtherCalendarErrorResponseType,
  QuarterOnCalendarType,
  ValidateGoogleAuthType,
  ValidateMicrosoftAuthType,
} from '../CalendarEventType';
import { OtherAccountTypeEnum } from '../OtherAccountTypeEnum';

export type CalendarStoreModel = {
  calendar: CalendarModel;
};

export interface CalendarModel {
  calendarPresenter: CalendarPresenter;
  groupEventPresenter: CalendarEventPresenter;
  googleCalendarEvent: CalendarEventType[];
  microsoftCalendarEvent: CalendarEventType[];
  calendarloading: boolean;
  groupEventsloading: boolean;
  currentQuarterOnCalendar: QuarterOnCalendarType;
  setCalendarPresenter: Action<CalendarModel, CalendarPresenter>;
  setGroupEventPresenter: Action<CalendarModel, CalendarEventPresenter>;
  setGoogleCalendarEvent: Action<CalendarModel, CalendarEventType[]>;
  setMicrosoftCalendarEvent: Action<CalendarModel, CalendarEventType[]>;
  setCurrentQuarterOnCalendar: Action<CalendarModel, QuarterOnCalendarType>;

  saveSchedule: Thunk<
    CalendarModel,
    {
      fromDate: Date;
      toDate: Date;
      appointmentTypes: AppointmentType[];
      bookingCapacity: number;
      adhoc?: boolean;
      title?: string;
      location?: string;
      candidateId?: string;
      collaborators?: string[];
    },
    void,
    HttpStoreModel,
    Promise<any>
  >;
  updateSchedule: Thunk<
    CalendarModel,
    {
      id: string;
      payload: { fromDate: Date; toDate: Date; appointmentTypes: AppointmentType[]; bookingCapacity: number };
    },
    void,
    HttpStoreModel
  >;

  deleteSchedule: Thunk<CalendarModel, { id: string }, void, HttpStoreModel>;

  saveEvent: Thunk<
    CalendarModel,
    { accountId: string; groupEvent: GroupEvent; candidates: string[]; recruiters: string[] },
    void,
    HttpStoreModel,
    Promise<GroupEventResponse | null>
  >;

  updateEvent: Thunk<
    CalendarModel,
    { groupEventId: string; accountId: string; groupEvent: GroupEvent; candidates: string[]; recruiters: string[] },
    void,
    HttpStoreModel,
    Promise<GroupEventResponse | null>
  >;

  getEventToken: Thunk<
    CalendarModel,
    { groupEventId: string },
    void,
    HttpStoreModel,
    Promise<GroupEventResponse | EventTokenResponse>
  >;
  cancelIndividualBooking: Thunk<CalendarModel, { positionId: string; seekerId: string; reason: string }, void, HttpStoreModel>;
  deleteEvent: Thunk<CalendarModel, { groupEventId: string }, void, HttpStoreModel>;
  validateOtherCalendarAuthToken: Thunk<
    CalendarModel,
    { accountId: string; userId: string; code: string; calendarAccount: OtherAccountTypeEnum },
    void,
    HttpStoreModel,
    Promise<ValidateGoogleAuthType | ValidateMicrosoftAuthType | OtherCalendarErrorResponseType>
  >;
  getOtherCalendarEvent: Thunk<
    CalendarModel,
    { accountId: string; userId: string; email: string; fromDate: string; toDate: string; calendarAccount: OtherAccountTypeEnum },
    void,
    HttpStoreModel,
    Promise<GoogleEventsType | OtherCalendarErrorResponseType>
  >;
  updateCheckedEmailStatus: Thunk<
    CalendarModel,
    { accountId: string; userId: string; email: string; calendarAccount: OtherAccountTypeEnum },
    void,
    HttpStoreModel
  >;
  removeOtherCalendarAccountAuth: Thunk<
    CalendarModel,
    { accountId: string; userId: string; email: string; calendarAccount: OtherAccountTypeEnum },
    void,
    HttpStoreModel
  >;
}

export const calendarStoreModel: CalendarModel = {
  calendarPresenter: new CalendarPresenter([], [], [], 'en'),
  groupEventPresenter: new CalendarEventPresenter([], 'en'),
  googleCalendarEvent: [],
  microsoftCalendarEvent: [],
  calendarloading: false,
  groupEventsloading: false,
  currentQuarterOnCalendar: { quarter: moment().quarter(), date: moment().toDate() },

  setCalendarPresenter: action((state, payload) => {
    state.calendarPresenter = payload;
    state.calendarloading = false;
  }),

  setGroupEventPresenter: action((state, payload) => {
    state.groupEventPresenter = payload;
    state.groupEventsloading = false;
  }),

  setGoogleCalendarEvent: action((state, payload) => {
    state.googleCalendarEvent = payload;
  }),

  setMicrosoftCalendarEvent: action((state, payload) => {
    state.microsoftCalendarEvent = payload;
  }),

  setCurrentQuarterOnCalendar: action((state, payload) => {
    state.currentQuarterOnCalendar = payload;
  }),

  saveSchedule: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new CalendarController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.saveSchedule(
      payload.fromDate,
      payload.toDate,
      payload.appointmentTypes,
      payload.bookingCapacity,
      payload.adhoc,
      payload.title,
      payload.location,
      payload.candidateId,
      payload.collaborators,
    );
    return response?.data as AxiosResponse | undefined;
  }),

  updateSchedule: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new CalendarController(getStoreState().http.client as HttpClientWithRetry);
    await controller.updateSchedule(payload.id, payload.payload);
  }),

  deleteSchedule: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new CalendarController(getStoreState().http.client as HttpClientWithRetry);
    await controller.deleteSchedule(payload.id);
  }),

  saveEvent: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new CalendarController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.saveEvent(payload.accountId, payload.groupEvent, payload.candidates, payload.recruiters);
    return response?.data as GroupEventResponse | null;
  }),

  updateEvent: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new CalendarController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.updateEvent(
      payload.groupEventId,
      payload.groupEvent,
      payload.candidates,
      payload.recruiters,
    );
    return response?.data as GroupEventResponse | null;
  }),

  getEventToken: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new CalendarController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.getEventToken(payload.groupEventId);
    return response?.data as GroupEventResponse | EventTokenResponse;
  }),

  deleteEvent: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new CalendarController(getStoreState().http.client as HttpClientWithRetry);
    await controller.deleteEvent(payload.groupEventId);
  }),

  cancelIndividualBooking: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new CalendarController(getStoreState().http.client as HttpClientWithRetry);
    await controller.cancelIndividualBooking(payload.positionId, payload.seekerId, payload.reason);
  }),

  validateOtherCalendarAuthToken: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new UserController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.validateOtherCalendarAuthToken(
      payload.accountId,
      payload.userId,
      payload.code,
      payload.calendarAccount,
    );
    return response?.data as ValidateGoogleAuthType | ValidateMicrosoftAuthType | OtherCalendarErrorResponseType;
  }),

  getOtherCalendarEvent: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new UserController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.getOtherCalendarEvent(
      payload.accountId,
      payload.userId,
      payload.email,
      payload.fromDate,
      payload.toDate,
      payload.calendarAccount,
    );
    return response?.data as GoogleEventsType | OtherCalendarErrorResponseType;
  }),

  updateCheckedEmailStatus: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new UserController(getStoreState().http.client as HttpClientWithRetry);
    await controller.updateCheckedEmailStatus(payload.accountId, payload.userId, payload.email, payload.calendarAccount);
  }),

  removeOtherCalendarAccountAuth: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new UserController(getStoreState().http.client as HttpClientWithRetry);
    await controller.removeOtherCalendarAccountAuth(payload.accountId, payload.userId, payload.email, payload.calendarAccount);
  }),
};
