export default {
  cannotEngage: 'Not Enough Credits to Engage With Candidate',
  outOfCredit: 'Oops! Looks like you are out of credits.',
  purchaseMore: 'You`ve used up all your credits. Purchase more to continue engaging with new job seekers.',
  choosePackage: 'Choose Your Package',
  buyCredits: 'Buy Credits',
  recommend: 'Recommended',
  credits: 'Credits',
  paymentSuccessful: 'Payment Successful!',
  congrats: 'Congratulations! You can now engage with even more job seekers!',
  orderDetails: 'You can view your payment confirmation and order details by clicking',
  here: 'here.',
  goHome: 'Go Back Home',
};
