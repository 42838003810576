import { css } from '@emotion/core';

export const dashboardStyleCSS = css`
  padding-top: 64px;

  @media only screen and (max-width: 1800px) {
    padding-top: 90px;
  }

  .stickyTop {
    position: fixed;
    top: 64px;
    left: 0;
    width: 100%;
    z-index: 1;
  }
  .sticky .countBar {
    position: fixed;
    top: 64px;
    left: 0;
    width: 100%;
    z-index: 1;
  }

  .header-pagination > div > div {
    justify-content: flex-end;
  }
`;
