import { css } from '@emotion/core';
import colors from '../../../../styles/colors';
import Colors from '../../../../styles/colors';

export const createJobPositionStyle = css`
  .chakra-form-control {
    input[type='text'],
    input[type='email'],
    input[type='tel'],
    input[type='number'],
    textarea,
    select {
      background-color: ${colors.gray['150']};
      border-color: ${colors.gray['150']};
      box-shadow: 0 0 0 1px ${colors.gray['150']};
      height: 3rem;
      padding: 0.4rem 1rem;
      width: 100%;
      display: block;

      &:focus, &:active {
        background-color: ${colors.gray['150']};
        border: 1px solid ${colors.blue[500]};
        box-shadow:0 0 0 1px ${colors.blue[500]};
    }
  }
  .chakra-input__group .chakra-input__left-addon {
    height: 3rem;
    border: 0;
    background: #DEE1E3;
    border-radius: 3px 0px 0px 3px;
  }

`;

export const videoBlockStyle = css`
  text-align: center;
  padding: 2rem;
  background: linear-gradient(104.78deg, #367bb3 -2.14%, #29a7e0 100%);
  border-radius: 3px;

  .icon-block {
    background: rgb(255 255 255 / 40%);
    border: 3px solid white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;

    svg {
      fill: #fff;
      background: ${colors.blue[500]};
      height: 22px;
      width: 22px;
      border-radius: 50%;
      overflow: hidden;
    }
  }

  img {
    height: 78px;
    width: 78px;
    object-fit: cover;
    border-radius: 50%;
    border: 3px solid #fff;
`;

export const customStyledCheckbox = css`
  padding: 1.4rem;

  .chakra-checkbox {
     .chakra-checkbox__label {
        border: 1px solid #CFD8DC;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 5px 10px;
        width: 100px;
        display: flex;
        justify-content: center;
    }
    input[type='checkbox'] {
    ~ div {
      &.chakra-checkbox__control {
        display: none;
        }
       }

    &:checked {
    ~ div.chakra-checkbox__control {
      display: block;
      position: absolute;
      top: 20px;
      left: 0px;
      border-radius: 50%;
    }
    ~ div.chakra-checkbox__label {
        border: 1px solid ${colors.blue[500]};
        background-color: #E1F4FD;
        color: ${colors.blue[500]};
    }
  }
`;

export const IndustryRadiosStyle = css`
  .chakra-stack {
    @media (max-width: 480px) {
      margin-right: -20px;
    }
  }
  label.chakra-radio {
    position: relative;

    @media (max-width: 480px) {
      width: 30%;
    }

    @media (max-width: 415px) {
      width: 46%;
    }

    .chakra-radio__label {
      background: #fff;
      border-radius: 10px;
      border: 1px solid #CFD8DC;
      display: flex;
      flex-direction: column;
      height: 90px;
      width: 115px;
      align-items: center;
      justify-content: space-evenly;
      font-size: 12px;
      text-align: center;
      padding: 5px;
      margin-left: 0;

      @media (max-width: 480px) {
        width: 100%;
      }

      > div {
        background: #fff;
        height: 40px;
        width: 40px;
        display: flex !important;
        overflow: visible !important;
        align-items: center;
        border-radius: 50%;
        justify-content: center;

        img {
          min-width: 60% !important;
        }
      }
    }

    .chakra-radio__control {
      position: absolute;
      top: 7px;
      right: 7px;
      border-color: #c7c7c7;

      &[aria-checked=true], &[data-checked] {
        background: ${colors.blue[500]};
        border-color: ${colors.blue[500]};
      }
    }
    .chakra-radio__label {
      &[aria-checked=true], &[data-checked] {
        border-color: ${colors.blue[500]};
      }
    }
  }
}
`;

export const AdHocRaioStyle = css`
  width: 100%;
  label.chakra-radio {
    width: 31%;
  }
  label.chakra-radio:last-child {
    margin-right: 0;
  }
  .chakra-radio__label {
    flex-direction: row !important;
    height: 40px !important;
    border-radius: 8px !important;
    width: 100% !important;
    font-size: 14px !important;

    > div {
      height: 20px !important;
      width: 20px !important;
    }
  }
  .chakra-radio__control {
    display: none !important;
  }
  .chakra-radio__label {
    &[aria-checked='true'],
    &[data-checked] {
      color: ${colors.blue[500]};
    }
  }
`;

export const InterViewDurationStyle = css`
  .chakra-radio__label {
      background: #fff;
      border-radius: 5px;
      border: 1px solid #CFD8DC;
      padding: 5px 10px;
      margin: 0;

      &[aria-checked=true], &[data-checked] {
        background: #E1F4FD;
        border-color: ${colors.blue[500]};
        color: ${colors.blue[500]};
      }
  }
  .chakra-radio__control {
      display: none;
`;

export const ReactCustomSelectStyles = css`
  .react-select__control {
    background-color: ${colors.gray['150']};
    border-color: ${colors.gray['150']};
    height: 3rem;
    padding: 0.4rem 0.2rem;
    width: 100%;

    &:hover {
      border-color: ${colors.gray['150']};
    }
    &:focus,
    &:active,
    &.react-select__control--is-focused {
      border: 2px solid ${colors.blue[500]};
      outline: none;
      box-shadow: none;
    }
  }
  input {
    height: 1rem !important;
  }
`;

export const ReactCustomSelectStylesWhenDisabled = css`
  .react-select__control {
    background-color: ${colors.gray['150']};
    border-color: ${colors.gray['150']};
    height: 3rem;
    padding: 0.4rem 0.2rem;
    width: 100%;

    &:hover {
      border-color: ${colors.gray['150']};
    }
    &:focus,
    &:active,
    &.react-select__control--is-focused {
      border: 2px solid ${colors.blue[500]};
      outline: none;
      box-shadow: none;
    }
    .react-select__single-value {
      color: ${colors.gray[500]} !important;
    }
  }
  input {
    height: 1rem !important;
  }
`;

export const RCStepsStyles = css`
  .rc-steps-item {
    flex: 1 auto;
  }
  .cursorPointer .rc-steps-item-container[role='button'] {
    cursor: pointer !important;
    display: flex;
  }
  .cursorNotAllowed .rc-steps-item-container[role='button'] {
    cursor: not-allowed !important;
    display: flex;
  }
  .rc-steps-item-icon {
    width: 30px;
    height: 30px;
    line-height: 31px;
    font-size: 16px;
    border-color: ${Colors.gray[50]};
    background: ${colors.gray[50]};
    border-color: ${colors.gray[50]};
  }
  .rc-steps-item-title {
    font-size: 15px;
    font-weight: normal;
  }
  .rc-steps-item-process .rc-steps-item-icon {
    background: ${colors.blue[500]};
    border-color: ${colors.blue[500]};
  }

  .rc-steps-item-finish .rc-steps-item-icon {
    border-color: ${colors.blue[500]} !important;
    background-color: ${colors.blue[500]};

    svg {
      fill: #fff;
      width: 26px !important;
      height: 26px !important;
      margin-left: 1px;
    }
  }
`;
export const DateAndTimePickerCoustomCSS = css`
  .react-datepicker-wrapper {
    display: block !important;
    .customDatepicker {
      border-radius: 5px;
      height: 48px;
      align-items: center;
      background-color: ${colors.gray['150']};
      .chakra-button {
        border-color: ${colors.gray['150']} !important;
      }
    }
  }
`;
