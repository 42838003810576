import { AxiosResponse } from 'axios';
import HttpClientWithRetry from '../http/client/HttpClientWithRetry';

export class ProductController {
  constructor(private readonly httpClient: HttpClientWithRetry) {}

  async getProductList(): Promise<AxiosResponse> {
    const path = '/api/v1/stripe/getAllProduct';
    return this.httpClient.get(path);
  }

  async buyToken(productId: string, priceId: string, successUrl: string, cancelUrl: string): Promise<AxiosResponse> {
    const path = '/api/v1/stripe/makepayment';
    const body = { productId, priceId, successUrl, cancelUrl };
    return this.httpClient.post(path, body);
  }

  async getTokenReceipt(accountId: string): Promise<AxiosResponse> {
    const path = `/api/v1/stripe/${accountId}/getReceipt`;
    return this.httpClient.get(path);
  }
}
