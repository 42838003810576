import React from 'react';
import { Box, Button, Flex, Input, InputGroup, InputLeftElement, Spinner, Stack, Text, useDisclosure } from '@chakra-ui/core';
import { SearchIcon } from '@chakra-ui/icons';
import { MdFilterList } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import colors from '../../../styles/colors';
import { SelectedTab, useTopCandidatesContext } from './TopCandidatesContext';
import { TopCandidateDataType } from './TopCandidateDataType';
import { CandidateView } from './CandidateView';
import { ContactRequestModalView } from './ContactRequestModalView';

export type CandidatesListPros = {
  isLoading: boolean;
  topCandidates: TopCandidateDataType[];
};

export const CandidatesList = ({ isLoading, topCandidates }: CandidatesListPros): JSX.Element => {
  const {
    isFilterOpened,
    setIsFilterOpened,
    tab,
    setTab,
    selectedTopCandidate,
    setSelectedTopCandidate,
    filterParams,
    positionDocument,
  } = useTopCandidatesContext();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation('businessManagement');

  const getFilteredCandidate = (candidates: TopCandidateDataType[]): TopCandidateDataType[] => {
    let reformattedData = candidates.map((c) => ({
      ...c,
      withinRange: c.withinRange ? 'yes' : 'no',
    }));

    if (filterParams.salary) {
      reformattedData = reformattedData.filter((c) => c.withinRange === filterParams?.salary);
    }
    if (filterParams.salaryRange) {
      reformattedData = reformattedData.filter(
        (c) =>
          c.seeker.expectedSalary &&
          filterParams.salaryRange &&
          parseFloat(c.seeker.expectedSalary.value) >= filterParams?.salaryRange[0] &&
          parseFloat(c.seeker.expectedSalary.value) <= filterParams?.salaryRange[1],
      );
    }
    if (filterParams.otherFilterParams?.includes('hasFlexibleTransportation')) {
      reformattedData = reformattedData.filter((c) => c.hasFlexibleTransportation);
    }
    if (filterParams.experienceRange) {
      reformattedData = reformattedData.filter(
        (c) => filterParams.experienceRange && c.workExperience >= filterParams?.experienceRange[0],
      );
    }

    return reformattedData;
  };

  const handleContactRequest = (candidate: TopCandidateDataType) => {
    setSelectedTopCandidate(candidate);
    onOpen();
  };

  return (
    <Box height="100%" overflowY="auto" position="absolute" w="100%">
      <Flex alignItems="center" borderBottom="1px solid #E5E7EB">
        {!isFilterOpened && (
          <Box borderRight="1px solid #E5E7EB" p="6px 18px">
            <Flex
              alignItems="center"
              borderRadius="30px"
              background={colors.neutral[100]}
              p="6px 12px"
              _hover={{ backgroundColor: '#E5E7EB' }}
              onClick={() => setIsFilterOpened(true)}
              cursor="pointer"
            >
              <MdFilterList />
              <Text fontSize="18px" fontWeight={700} ml="8px">
                {t('topCandidate.filters')}
              </Text>
            </Flex>
          </Box>
        )}
        {/* Will add back button when total feature of routing of top candidate implements */}
        {/* <IconButton */}
        {/*  variant="outline" */}
        {/*  border="none" */}
        {/*  colorScheme="white" */}
        {/*  fontSize="20px" */}
        {/*  aria-label="Arrow-left" */}
        {/*  _hover={{ backgroundColor: '#E5E7EB' }} */}
        {/*  icon={<FaArrowLeft color={colors.blue.default} cursor="pointer" />} */}
        {/* /> */}
        <Text color={colors.blue.default} fontSize="18px" fontWeight={700} ml="6px" p={3}>
          {positionDocument?.customName}
        </Text>
      </Flex>
      <Flex alignItems="center" borderBottom="1px solid #E5E7EB" p="12px">
        <Box backgroundColor={colors.neutral[100]} w="40%">
          <Stack spacing={4}>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <SearchIcon />
              </InputLeftElement>
              <Input
                data-testid="searchInput"
                type="text"
                // value={queryParams.searchQuery}
                placeholder={t('topCandidate.searchCandidates')}
                // onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeSearchText(e.target.value)}
                // onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => e.key === 'Enter' && onGetBusinessList(false)}
              />
            </InputGroup>
          </Stack>
        </Box>
        <Flex w="60%" justifyContent="flex-end">
          {[
            { label: t('topCandidate.top'), type: SelectedTab.BEST_MATCH },
            { label: t('topCandidate.bookmarked'), type: SelectedTab.BOOKMARKED },
            { label: t('topCandidate.active'), type: SelectedTab.ACTIVE },
          ].map((btnTab, index) => (
            <Button
              key={btnTab.type}
              border="1px solid #E5E7EB"
              borderRight={index === 2 ? '1px solid #a1b7c2' : 'none'}
              variant="outline"
              fontSize="sm"
              _focus={{
                boxShadow: 'none',
              }}
              background={tab === btnTab.type ? '#263DBA1A' : '#ffffff'}
              color={tab === btnTab.type ? colors.blue.default : '#4D4D4D'}
              onClick={() => setTab(btnTab.type)}
              borderTopRightRadius={index === 2 ? '5px' : '0'}
              borderBottomRightRadius={index === 2 ? '5px' : '0'}
              borderTopLeftRadius={index === 0 ? '5px' : '0'}
              borderBottomLeftRadius={index === 0 ? '5px' : '0'}
            >
              {btnTab.label}
            </Button>
          ))}
        </Flex>
      </Flex>
      <Box>
        {isLoading ? (
          <Flex alignItems="center" justifyContent="center" h="100px">
            <Spinner size="md" colorScheme="blue.500" />
          </Flex>
        ) : (
          getFilteredCandidate(topCandidates).map((topCandidate) => (
            <CandidateView key={topCandidate.seeker.id} topCandidate={topCandidate} handleContactRequest={handleContactRequest} />
          ))
        )}
        {!isLoading && topCandidates.length <= 0 && (
          <Flex minH="200px" alignItems="center" justifyContent="center">
            <Text fontSize="lg" fontWeight="bold">
              {tab === SelectedTab.BEST_MATCH
                ? ` There are no candidates matched for your position, check back soon!`
                : `No candidates !`}
            </Text>
          </Flex>
        )}
        {isOpen && selectedTopCandidate && (
          <ContactRequestModalView isOpen={isOpen} onClose={onClose} selectedTopCandidate={selectedTopCandidate} />
        )}
        <Box h="30px" />
      </Box>
    </Box>
  );
};
