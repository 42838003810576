/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/core';
import i18next from 'i18next';
import moment from 'moment';
import { getTranslatedValue } from '../../../../../../utils/localizedString';
import { CandidateAvailabilityDuration } from './CandidateAvailabilities';

const weekDays = [
  { en: 'mon-tue', fr: 'lun-mar' },
  { en: 'tue-wed', fr: 'mar-mer' },
  { en: 'wed-thu', fr: 'mer-jeu' },
  { en: 'thu-fri', fr: 'jeu-ven' },
  { en: 'fri-sat', fr: 'ven-sam' },
];

const weekEnd = [
  { en: 'sat-sun', fr: 'sam-dim' },
  { en: 'sun-mon', fr: 'dim-lun' },
];

export type CandidateNightDuration = {
  start_1: string;
  end_1: string;
};

type CandidateNightProps = {
  nightAvailabilities: { [key: string]: { start_1: string; end_1: string } };
};

export const CandidateNightAvailabilities = ({ nightAvailabilities }: CandidateNightProps): JSX.Element => {
  let count = 0;
  return (
    <Flex>
      <Box w="50%" mr="10px">
        {weekDays.map((d) => {
          count += 1;
          const range = d.en;
          const startDay = range.split('-')[0];
          const availability = nightAvailabilities[startDay];

          if (availability as CandidateAvailabilityDuration) {
            return (
              <Flex
                justifyContent="space-between"
                key={d.en}
                w="100%"
                px={1}
                py={1}
                fontSize="13.5px"
                bg={count === 1 || count === 3 || count === 5 ? 'gray.150' : 'white'}
                border="1px solid"
                borderColor="gray.150"
              >
                <Box textTransform="capitalize" fontSize="11.5px">
                  {getTranslatedValue(d, i18next.language)}
                </Box>
                <Flex style={{ float: 'right', justifyContent: 'flex-end' }}>
                  <Text fontSize="11.5px">{moment(availability.start_1, ['HH:mm']).format('LT')}</Text>
                  <Text mr="5px" ml="5px" fontSize="12px">
                    -
                  </Text>
                  <Text fontSize="11.5px">{moment(availability.end_1, ['HH:mm']).format('LT')}</Text>
                </Flex>
              </Flex>
            );
          }
          return (
            <Flex
              justifyContent="space-between"
              key={d.en}
              w="100%"
              px={1}
              py={1}
              fontSize="13.5px"
              bg={count === 1 || count === 3 || count === 5 ? 'gray.150' : 'white'}
              border="1px solid"
              borderColor="gray.150"
            >
              <Box textTransform="capitalize" fontSize="11.5px">
                {getTranslatedValue(d, i18next.language)}
              </Box>
              <Box>---</Box>
            </Flex>
          );
        })}
      </Box>
      <Box w="50%">
        {weekEnd.map((d) => {
          count += 1;
          const range = d.en;
          const startDay = range.split('-')[0];
          const availability = nightAvailabilities[startDay];

          /* istanbul ignore else */

          if (availability as CandidateAvailabilityDuration) {
            return (
              <Flex
                justifyContent="space-between"
                key={d.en}
                w="99%"
                minWidth="165px"
                px={1}
                py={1}
                fontSize="13.5px"
                bg={count === 7 ? 'gray.150' : 'white'}
                border="1px solid"
                borderColor="gray.150"
              >
                <Box textTransform="capitalize" fontSize="11.5px">
                  {getTranslatedValue(d, i18next.language)}
                </Box>
                <Flex style={{ float: 'right', justifyContent: 'end' }}>
                  <Text fontSize="11.5px">{moment(availability.start_1, ['HH:mm']).format('LT')}</Text>
                  <Text mr="5px" ml="5px" fontSize="12px">
                    -
                  </Text>
                  <Text fontSize="11.5px">{moment(availability.end_1, ['HH:mm']).format('LT')}</Text>
                </Flex>
              </Flex>
            );
          }
          /* istanbul ignore next */
          return (
            <Flex
              justifyContent="space-between"
              key={d.en}
              w="99%"
              minWidth="165px"
              px={1}
              py={1}
              fontSize="13.5px"
              bg={count === 7 ? 'gray.150' : 'white'}
              border="1px solid"
              borderColor="gray.150"
            >
              <Box textTransform="capitalize" fontSize="11.5px">
                {getTranslatedValue(d, i18next.language)}
              </Box>
              <Box>---</Box>
            </Flex>
          );
        })}
      </Box>
    </Flex>
  );
};
