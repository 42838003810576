export type OnBoardingUserInfoType = {
  firstName?: string;
  lastName?: string;
  email: string;
  phone: string | null;
  account?: string;
  candidateId?: string;
  anonymousUser?: boolean;
};

export interface EmployerInitializeData {
  phoneNumber: string | null;
  locale: string;
  timeOffset: number;
  smb?: boolean;
}

export enum ActiveTabEnum {
  LOGIN,
  SIGN_UP,
}
