import { Box, Button, Checkbox, Text } from '@chakra-ui/core';
import React, { useEffect, useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';

export type Option = {
  value: string;
  label: string;
};

interface DropDownCheckBoxProps {
  options: Option[];
  dropDownPlaceholder?: string;
  status: string;
  checkedItems: {
    [key: string]: boolean;
  };
  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const DropDownCheckBox: React.FC<DropDownCheckBoxProps> = ({
  options,
  dropDownPlaceholder,
  status,
  checkedItems,
  handleCheckboxChange,
}) => {
  const dropdownRef = React.useRef<HTMLDivElement | null>(null);
  const [showCheckBox, setShowCheckBox] = useState(false);

  const handleToggleDropDown = () => setShowCheckBox((prev) => !prev);

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setShowCheckBox(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Box position="relative" ref={dropdownRef}>
      <Button
        data-testid="DropDownCheckBoxBtn"
        w="100%"
        size="md"
        rightIcon={<IoIosArrowDown />}
        onClick={handleToggleDropDown}
        variant="outline"
        justifyContent="space-between"
        sx={{
          transition: 'transform 0.1s ease-in-out',
          _hover: {
            borderColor: '#1F3CBA',
            boxShadow: '0 0 6px #1F3CBA',
          },
        }}
      >
        <Text fontWeight="bold" fontSize="md">
          {dropDownPlaceholder}
        </Text>
      </Button>

      {showCheckBox && (
        <Box
          data-testid="OptionsCheckBoxCanvas"
          position="absolute"
          top="100%"
          left={0}
          bg="#fff"
          zIndex={99}
          border="1px solid #ccc"
          borderRadius="md"
          boxShadow="md"
          w="100%"
        >
          {options.map((option) => (
            <Box
              key={option.value}
              data-testid="DropDownCheckBoxCustom"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              px={6}
              py={2}
            >
              <Checkbox
                data-testid={`CheckBoxCustom-${option.value}`}
                name={option.value}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheckboxChange(e)}
                isChecked={checkedItems[option.value]}
                defaultChecked={status === option.value}
              >
                {option.label}
              </Checkbox>
              {/* <Box as="span" bg="red.500" color="white" borderRadius="full" px={2} py={1} fontSize="12px">
                {option.badge}
              </Box> */}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default DropDownCheckBox;
