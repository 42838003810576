export default {
  login: 'Login',
  phoneNumberInputPlaceholder: 'Your mobile number',
  confirmationCodeInputPlaceholder: 'Confirmation code',
  sendCodeButton: 'Send code',
  sendCodeTitle: {
    1: 'You have an ',
    2: 'Appyhere for Recruiters ',
    3: 'mobile account ?',
  },
  sendCodeSubtitle: 'Enter the same mobile number used to receive a code',
  sendCodeSignUpSubtitle: 'Enter mobile number to receive a code',
  sendCodeFooterLabel: {
    1: 'You ',
    2: 'MUST ',
    3: 'setup a mobile account before using the application',
  },
  sendCodeFooterButton: 'Click here for information',
  loginTitle: 'You have received a code on your mobile',
  loginSubtitle: 'Enter it, and all your new messages will appear',
  loginFooterLabel: 'You did not receive a code on your mobile?',
  loginFooterButton: 'Send again',
  wrongCode: '* WRONG CODE, TRY AGAIN',
  wrongCountryCode: 'AppyHere is not currently available in your country.',
  invalid_user_alert_title: 'Somethings Wrong',
  invalid_user_alert_description: 'We did not find an account, please try again',
  signUpLabel: 'Signup',
  signUpSendCodeTitle: 'Sign up to AppyHere',
  signUpSendCodeSubTitle: 'Simplify your hiring process and recruit faster',
  continue: 'Continue',
  termsOfService:
    'By using this service, you are agreeing to our  <1> terms of service</1>. By signing up for AppyHere, you are agreeing to our terms of service.',
  privacyTerms1: 'We only use your phone number to verify your identity.',
  privacyTerms2: 'We do not sell or use phone numbers for marketing purposes.',
  signUp: {
    signUpTitle: 'Welcome to AppyHere',
    signUpSubTitle: 'Enter your information',
    firstName: 'First name',
    lastName: 'Last name',
    emailAddress: 'Email address',
    submitText: 'Join Appyhere',
    firstNameRequiredMessage: 'First Name is required',
    lastNameRequiredMessage: 'Last Name is required',
    emailAddressRequiredMessage: 'Email Address is required',
    emailAddressValidateMessage: 'Email Address must be valid',
  },
};
