import { EducationStatus } from '../../firebase/firestore/documents/seeker';

export default {
  send: 'Envoyer',
  email: 'Courriel',
  search: {
    label: 'Rechercher',
  },
  sorting: {
    label: 'Tri :', // ← hair space U+200A
    chronological: 'Date',
    alphabetical: 'A-Z',
  },
  grouping: {
    label: 'Regroupement :', // ← hair space U+200A
    none: 'Aucun',
    position: 'Poste',
    businessLocation: 'Site',
  },
  tabs: {
    profile: 'Profil',
    audioRecording: 'Enregistrement audio',
    messaging: 'Messagerie',
    questions: 'Questions',
    notes: 'Notes',
    history: 'Historique',
    PASS: 'Réussie',
    FAIL: 'Échouée',
    NEEDS_REVIEW: "Besoin d'entrevue",
  },
  account: {
    switchLang: 'English',
    logout: 'Se déconnecter',
    switchColorMode: {
      dark: 'Passer en mode claire',
      light: 'Passer en mode sombre',
    },
    administration: 'Administration',
    recruiter: 'Recruteur',

    myProfile: 'Mon profil',
  },
  logoutConfirmation: {
    message: 'Voulez-vous vraiment vous déconnecter d’AppyHere?',
    logout: 'Déconnecter',
    cancel: 'Annuler',
  },
  profile: {
    joinDate: 'A rejoint APPYHERE : {{date}}', // ← hair space U+200A
    isAStudent: 'Étudiant',
    isStudentInfo: 'Ce candidat est un étudiant.',
    adult: 'Adulte',
    adultInfo: 'Ce candidat est agé de 18 ans ou plus.',
    appyPros: {
      title: 'Aperçu du candidat',
      flexibleTransportation: 'A accès à un transport flexible',
      positivePoint: 'Point positif',
    },
    appyNeg: {
      highSalaryDemand: 'Attente salariale élevée',
      negativePoint: 'Point négatif',
    },
    matchingRequisition: {
      title: 'Réquisitions idéales',
      availabilityFitBand: 'Compabilité de la disponibilité:',
      goodFit: 'Bon',
      mediumFit: 'Moyen',
      poorFit: 'Faible',
      badFit: 'Faible',
      noFit: 'Aucun match calculé',
      not_fitFit: 'Aucun match calculé',
      offerMade: 'Faire une offre',
    },
    experience: {
      title: 'Expérience',
      current: 'Actuel',
      none: 'Aucune expérience',
      fetching: "Extraction de l'expérience...",
    },
    resume: 'Resume',
    noResumeFound: 'User has not yet uploaded their resume in resume section',
    lastUpdatedResume: 'Last updated at:',
    noResumePreviewAvailable: 'No Preview Available',
    education: {
      title: 'Formations',
      none: 'Aucune Formation',
      fetching: 'Extraction des formations...',
      [EducationStatus.completed]: 'Complété',
      [EducationStatus.ongoing]: 'En cours',
      [EducationStatus.dropout]: 'Pas complété',
    },
    availability: {
      title: 'Disponibilité',
    },
    appliedPositions: 'Postes où le candidat a appliqué',
    openRequisitions: 'Réquisitions ouvertes',
    noRequisition: 'No Réquisition',
    updatedAt: 'Mise à jour à:',
  },
  requisitions: 'Réquisitions',
  allQuestions: {
    title: 'Toutes les questions',
  },
  questions: {
    title: 'Questions spécifiques',
    answerPositive: 'Oui',
    answerNegative: 'Non',
    positiveCount: '{{count}} bonne réponse sur un total de {{total}}',
    positiveCount_plural: '{{count}} bonnes réponses sur un total de {{total}}',
    emptyQuestions: `Aucune question pour la position {{positionName}}. Vous pouvez aller dans l'application AppyHere Employeur pour en ajouter.`,
    noAnswers: `Ce poste n'a aucune question de ce type.`,
    preferredAnswer: 'Réponse préférée',
    knockout: 'Knockout',
    pass: 'RÉUSSI',
    fail: 'ÉCHEC',
  },
  freeFormQuestions: {
    title: 'Questions libres',
    positiveCount: 'Réponse à {{count}} questions sur {{total}}',
    unanswered: 'Cette question est sans réponse.',
  },
  time: {
    relative: {
      lessThan: 'Moins de 6 mois',
      multipleMonths: '{{value}} mois',
      oneYear: '1 an',
      multipleYears: '{{value}} ans',
      moreThan: 'Plus de 10 ans',
    },
  },
  appointmentTypes: {
    inPerson: 'en personne',
    phone: 'Téléphone',
    video: 'vidéo',
    inPhone: 'Téléphone',
  },
  noMessage: 'Pas de message, lancez la conversation!',
  noMessageUnfollowed: `L'historique de messages pour ce candidat est vide.`,
  months: {
    January: 'Janvier',
    February: 'Février',
    March: 'Mars',
    April: 'Avril',
    May: 'Mai',
    June: 'Juin',
    July: 'Juillet',
    August: 'Août',
    September: 'Septembre',
    October: 'Octobre',
    November: 'Novembre',
    December: 'Décembre',
  },
  monthsSmall: {
    jan: 'Jan',
    feb: 'Fév',
    mar: 'Mar',
    apr: 'Avr',
    may: 'Mai',
    jun: 'Jui',
    jul: 'Jui',
    aug: 'Aoû',
    sep: 'Sep',
    oct: 'Oct',
    nov: 'Nov',
    dec: 'Déc',
  },
  informationTab: {
    toastMessage: 'URL copiée',
    headerText: 'URL de la liste des emplois de WebApply',
  },
  tooltip: {
    followedPosition: 'Poste suivi',
    ghostedCandidatesList: `Montrer d'autres listes de candidats`,
    favoriteCandidates: 'Mes favoris',
    archivedCandidates: 'Liste des candidats archivés',
  },
  candidates: {
    favoriteCandidatesList: 'Liste des candidats favoris',
    candidatesList: 'Liste des candidats',
    unresponsiveList: 'Liste qui ne répond pas',
    archivedList: 'Liste des candidats archivés',
    ghostingList: 'Candidats qui ne répond pas',
    archiveList: 'Archivé',
    dismissedList: `Rejeté`,
    agedList: `Candidats âgés plus de {{config}} jours`,
    recentList: `Recent List`,
  },
  button: {
    cancel: 'Annuler',
    okay: 'Ok',
    create: 'Créer',
    add: 'Ajouter',
    later: 'Plus tard',
  },

  searchAndSort: {
    advanceSearch: 'Recherche avancée',
    candidateEngagement: `Niveau d'engagement du candidat`,
    backgroundCheck: `Statut de la vérification des antécédents`,
    ratings: `Évaluation`,
    experience: `Expérience minimale`,
    salary: `Salaire`,
    expectedQuestions: `Questions du poste`,
    availability: 'Disponibilité',
    clearAll: `Réinitialiser les filtres`,
    saveButton: 'Sauvegarder',
    filter: 'Filtre',
    hireStatus: "Statut de l'embauche",
    modernHireStatus: 'Évaluation des comptétences',
    availabilityFit: 'Match des disponibilités',
    priority: 'Candidat référé',
    kiosk: 'Candidats kiosque',
    actionRequiredCandidates: "Besoin d'intervention recruteur",
  },
  favoriteCandidate: {
    favoriteCandidate: `Candidats favoris`,
  },
  cadidateEngagementView: {
    engaged: `Sauvegardé`,
    appointmentRequested: `Entrevue demandée`,
    interviewCompleted: `Entrevue complétée`,
    OfferMade: `Offre faite`,
    hired: `Embauché`,
    dismissed: `Rejeté`,
    offerRejected: 'Offre rejetée',
    offerAccepted: 'Offre acceptée',
    appoinmentAccepted: 'Entrevue acceptée',
    appoinmentDeclined: 'Entrevue refusée',
    appoinmentCancled: 'Entrevue annulée',
    noAppointmentRequest: 'Présélectionné',
    interestRevoked: `Pas intéressé`,
    contactRequestDeclined: `Demande contact refusé`,
  },
  backgroundCheck: {
    done: `Complétée`,
    partiallyDone: `Partiellement complétée`,
    notDone: `Pas complétée`,
    Sent: `Envoyé`,
    IN_PROGRESS: `En cours`,
    DISPUTE: `Contesté`,
  },
  availability: {
    day: 'Jour',
    night: 'Nuit',
    evening: 'Soirée',
  },
  salary: {
    hourly: `Horaire`,
    annual: `Annuel`,
    weekly: `Hebdomadaire`,
    monthly: `Mensuel`,
    unit: `Unité`,
    min: `Min`,
    max: `Max`,
  },
  experience: {
    yr: `année`,
    yrs: `années`,
  },
  expectedQuestions: {
    typeYourQuestion: `Choisir une question`,
    expectedQuestion: `Questions`,
    expectedAnswer: `Réponse attendue`,
    yes: `Oui`,
    no: `Non`,
  },
  unresponsivePeriod: {
    day: `jour`,
    days: `jours`,
    agedPeriod: `Délai avant que le candidat est considéré comme candidat qui ne répond pas`,
    agedPeriodInfo: `Un candidat est considéré non-répondant s'il n'a pas répondu, suite à une demande d'entrevue, à l'intérieur d'un nombre de jours défini par ce paramètre. Une fois ce délai passé, le candidat sera déplacé vers la liste des non-répondants.`,
    agedToDismissedPeriod: `Délai avant que le candidat non répondant est rejeté`,
    agedToDismissedPeriodInfo: `Ce curseur définit le délai avant qu'un candidat considéré non-répondant soit rejeté par le système.`,
    configUpdateFailed: `La modification des paramètres a échoué.`,
    configUpdateScuccess: `Les paramètres sont sauvegardés.`,
  },
  interviewFeedBack: 'Évaluation du candidat',
  earliestAvailabilityDate: 'Earliest Available Date',
  noticePeriod: "Avis pour l'employeur actuel",
  expectedWork: 'Heures de travail disponibles',
  immediately: 'Immédiatement',
  videoInterviewReminder: {
    candidateInfo: "Renseignements sur l'entrevue",
    name: 'Nom du candidat',
    position: 'Poste',
    location: 'Site',
    email: 'Courriel',
    close: 'Fermer',
    joinInterview: "Joindre l'entrevue",
  },
  candidateDetailsDrawer: {
    details: 'Détails',
    positionDetails: 'Renseignements sur le poste',
    candidateDetail: 'Renseignements sur le candidat',
    candidateName: 'Nom du candidat',
    email: 'Courriel',
    phone: 'Téléphone',
  },
  hrsWeek: 'hrs/semaine',
  days: {
    mon: 'lun',
    tue: 'mar',
    wed: 'mer',
    thu: 'jeu',
    fri: 'ven',
    sat: 'sam',
    sun: 'dim',
  },
  candidatesHours: 'Heures du candidat',
  analytics: {
    notAllowed: "Ce compte n'a pas de rapports analytiques de configurés",
    listOfAccessiblePages: 'Liste des pages',
  },
  pagination: {
    showingResult: "Affiche présentement {{currentItemsLength}} d'un total de {{totalItemsLength}} résultat",
  },
};
