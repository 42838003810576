import { useQuerySnapshot } from '../../firebase/firestore/hooks';
import { GroupEventConfiguration } from '../../firebase/firestore/documents/groupEvent';
import { Collections } from '../../firebase/firestore/collections';
import { BUNDLE_ID_GLOBAL, DAYS_UPTO_CANDIDATE_INTEACT_AFTER_HIRE } from '../../firebase/firestore/constants';
import { Query } from '../../firebase/firestore/query/query';

export const useDaysUptoCandidateInteractAfterHire = () => {
  const days = useQuerySnapshot<GroupEventConfiguration>(Collections.CONFIGURATION, [
    Query.field('bundleId').equals(BUNDLE_ID_GLOBAL),
    Query.field('name').equals(DAYS_UPTO_CANDIDATE_INTEACT_AFTER_HIRE),
  ]);
  if (days) {
    return days[0]?.value;
  }
  return undefined;
};

export default useDaysUptoCandidateInteractAfterHire;
