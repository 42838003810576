import { Button, HStack, Input, useNumberInput } from '@chakra-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { GetCandidateNumberInPreferencesType } from '../ProfileTypes';
import { useStoreActions, useStoreState } from '../../../models/hooks';
import useMaxCandidateLimit from '../../../app/hooks/useMaxCandidateLimit';

export interface PreferenceDialProps {
  defaultValue: number;
  onHandleChange: (event: string, id: string) => void;
  setCandidateNumberResponse: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const PreferenceDial: React.FC<PreferenceDialProps> = ({ defaultValue, onHandleChange, setCandidateNumberResponse }) => {
  const [value, setValue] = useState(defaultValue);
  const [responseCount, setResponseCount] = useState<GetCandidateNumberInPreferencesType>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { getCandidateNumberInPreferences } = useStoreActions((actions) => actions.profileManagement);
  const accountId = useStoreState((state) => state.app.accounts?.id);
  const maxCandidateLimit = useMaxCandidateLimit();

  const isMaximumLimit = Boolean(responseCount && maxCandidateLimit && responseCount?.count > maxCandidateLimit);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const onGetCandidateNumberInPreferences = useCallback(
    async (localAccountId: string, localValueDays: number) => {
      setIsLoading(true);
      const list = await getCandidateNumberInPreferences({ accountId: localAccountId, daysFilter: localValueDays });
      setIsLoading(false);
      setResponseCount(list);
      setCandidateNumberResponse(list?.count);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    /* istanbul ignore else */
    if (value && accountId) {
      /* eslint-disable @typescript-eslint/no-floating-promises */
      onGetCandidateNumberInPreferences(accountId, value);
    }
  }, [onGetCandidateNumberInPreferences, value, accountId]);

  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } = useNumberInput({
    step: 1,
    defaultValue,
    value: defaultValue,
    min: 1,
    max: 90,
    precision: 0,
    onChange: (e) => {
      onHandleChange(e, 'filterCandidateByDays');
      setValue(parseInt(e, 10));
    },
  });

  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();

  const incDisabled = {
    disabled: isLoading || isMaximumLimit,
  };
  const decDisabled = {
    disabled: isLoading,
  };

  return (
    <>
      <HStack style={{ width: '100%' }}>
        <Button
          {...dec}
          {...decDisabled}
          isLoading={isLoading}
          style={{ pointerEvents: decDisabled.disabled ? 'none' : 'auto', background: '#EDF2F7', border: '1px solid #E2E8F0' }}
        >
          -
        </Button>
        <Input
          {...input}
          htmlSize={4}
          width="auto"
          isReadOnly
          textAlign="center"
          data-testid="filterCandidateByDays"
          style={{ maxHeight: '40px' }}
        />
        <Button
          {...inc}
          {...incDisabled}
          isLoading={isLoading}
          style={{ pointerEvents: incDisabled.disabled ? 'none' : 'auto', background: '#EDF2F7', border: '1px solid #E2E8F0' }}
        >
          +
        </Button>
      </HStack>
    </>
  );
};

export default PreferenceDial;
