import { useQuerySnapshot } from '../../firebase/firestore/hooks';
import { GroupEventConfiguration } from '../../firebase/firestore/documents/groupEvent';
import { Collections } from '../../firebase/firestore/collections';
import { BUNDLE_ID_GLOBAL, MAX_CANDIDATE_LIMIT } from '../../firebase/firestore/constants';
import { Query } from '../../firebase/firestore/query/query';

export const useMaxCandidateLimit = () => {
  const maxCandidateLimit = useQuerySnapshot<GroupEventConfiguration>(Collections.CONFIGURATION, [
    Query.field('bundleId').equals(BUNDLE_ID_GLOBAL),
    Query.field('name').equals(MAX_CANDIDATE_LIMIT),
  ]);
  if (maxCandidateLimit) {
    return maxCandidateLimit[0]?.value;
  }
  return undefined;
};

export default useMaxCandidateLimit;
