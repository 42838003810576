import { BackgroundChecks } from './candidate';

export type Position = {
  appointment?: PositionAppointment;
  businessName: string;
  business?: string;
  id: string;
  interview?: PositionInterviewConfig;
  job: string;
  noOfOpenings?: number;
  account?: string;
  open?: boolean;
  customName?: string;
  assignedRecruiters?: string[];
  specificQuestions?: SpecificQuestion[];
  freeFormQuestions?: FreeFormQuestion[];
  screeningOptions?: BackgroundChecks;
  proposedSalary?: ProposedSalary;
  requiredExperience?: number;
  automated?: boolean;
  automationType?: string;
  isPaused?: boolean;
  virtualRecruiterEnabled?: boolean;
  languageEvaluationPassThreshold?: number;
  enableScreeningOptions?: boolean;
  requisitionActiveThresholdDays?: number;
  recruiterNotes?: {
    id: string;
    notes: string;
  }[];
  resumeEnabled?: boolean;
};

export type RecruiterNotes = {
  id: string;
  notes: string;
};

export type ProposedSalary = {
  customUnit: string | null;
  maxValue: string;
  minValue: string | undefined;
  unit: string | undefined;
};

export type SpecificQuestion = {
  id: string;
  text: string;
  preferredAnswer: boolean;
  knockout: boolean;
  isActive?: boolean;
};

export type FreeFormQuestion = {
  id: string;
  text: string;
  knockout: boolean;
  isActive?: boolean;
};

export type PositionInterviewConfig = {
  interviewType: AppointmentType;
  interviewDuration: 15 | 30 | 60;
  interviewMessage?: string;
  interviewLocation?: string;
  ratingFor?: RatingForType;
};

export type RatingForType = {
  positionId: string;
  seekerId: string;
  rating: number;
  review: string;
  assessmentReason: string;
};

export enum AppointmentType {
  inPerson = 'inPerson',
  phone = 'phone',
  video = 'video',
}

export type PositionAppointment = {
  duration: number;
  type: AppointmentType;
  location?: string;
};
