import { Candidate } from './candidate';
// eslint-disable-next-line import/no-cycle
import { CalendarEventType } from '../../../modules/calendar/CalendarEventType';

export type AppointmentAvailability = {
  id: string;
  accountId: string;
  appointmentTypes: AppointmentType[];
  createdAt: Date;
  fromDate: Date;
  toDate: Date;
  userId: string;
  bookings: {
    [key: string]: Booking;
  };
  bookingCapacity?: number;
};

export type Booking = {
  id?: string;
  availabilityId: string;
  businessId: string;
  candidateId: string;
  createdAt: Date;
  date: Date;
  duration: number;
  positionId: string;
  seekerId: string;
  type: AppointmentType | AppointmentType[];
  location?: string;
  recruiterName?: string;
  recruiterId?: string;
  joinedRecruiterId?: string[];
};

export type CalendarEvents = {
  activeDate: Date;
  bookings: AvailableBookingWithPosition[];
  groupEvents: CalendarEventType[];
};

export type ActiveAppointmentAvailabilitySlot = {
  fromDate: Date;
  toDate: Date;
};

export type AvailableBookingWithPosition = {
  candidate: Candidate;
  booking: Booking;
};

export enum AppointmentType {
  inPerson = 'inPerson',
  phone = 'phone',
  video = 'video',
}
