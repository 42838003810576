import { Action, action, thunk, Thunk } from 'easy-peasy';

import {
  Candidate,
  CandidateHireFormDetailResponse,
  CandidateHireFormDetailResponseWithError,
  EngagedCandidate,
  EngagedCandidateNotification,
  LanguageEvaluationRating,
  LanguageEvaluationStatus,
} from '../../../firebase/firestore/documents/candidate';
import { SeekersPresenter } from '../sidebar/seekerList/seekersPresenter';
import { HttpStoreModel } from '../../../http/models/httpModel';
import { CandidateController } from '../../../controllers/candidateController';
import HttpClientWithRetry from '../../../http/client/HttpClientWithRetry';

export type CandidateStoreModel = {
  candidate: CandidateModel;
};

export interface CandidateModel {
  allCandidates: SeekersPresenter | undefined;
  selectedCandidates: Candidate[];
  setAllCandidates: Action<CandidateModel, SeekersPresenter>;
  setSelectedCandidates: Action<CandidateModel, Candidate[]>;
  loadHireDetailOfCandidate: Thunk<
    CandidateModel,
    { candidateId: string },
    void,
    HttpStoreModel,
    Promise<CandidateHireFormDetailResponse | CandidateHireFormDetailResponseWithError>
  >;
  reviewLanguageEvaluation: Thunk<
    CandidateModel,
    {
      positionId: string;
      seekerId: string;
      language: string;
      phraseId: string;
      rating: LanguageEvaluationRating;
      fluency: number;
      pronunciation: number;
      accuracy: number;
      status: LanguageEvaluationStatus;
    },
    void,
    HttpStoreModel
  >;
  requestLanguageEvaluationResubmit: Thunk<
    CandidateModel,
    {
      candidateId: string;
      phraseId: string;
      recruiterId: string;
    },
    void,
    HttpStoreModel
  >;
  loadCandidate: Thunk<CandidateModel, { accountId: string }, void, HttpStoreModel, Promise<EngagedCandidate[]>>;
  loadCandidatesOfPositions: Thunk<
    CandidateModel,
    { accountId: string; positionIds: string[] },
    void,
    HttpStoreModel,
    Promise<EngagedCandidate[]>
  >;
  loadCandidatesForNotifications: Thunk<
    CandidateModel,
    { accountId: string; after: string; limit: number },
    void,
    HttpStoreModel,
    Promise<EngagedCandidateNotification>
  >;
}

export const candidateModel: CandidateModel = {
  allCandidates: undefined,
  selectedCandidates: [],

  setAllCandidates: action((state, payload) => {
    state.allCandidates = payload;
  }),

  setSelectedCandidates: action((state, payload) => {
    state.selectedCandidates = payload;
  }),

  loadHireDetailOfCandidate: thunk(async (actions, payload, { getStoreState }) => {
    const controller = new CandidateController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.loadHireDetailOfCandidate(payload.candidateId);
    return response?.data as CandidateHireFormDetailResponse;
  }),

  loadCandidate: thunk(async (actions, payload, { getStoreState }) => {
    const controller = new CandidateController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.loadCandidates(payload.accountId);
    return response?.data as EngagedCandidate[];
  }),

  loadCandidatesOfPositions: thunk(async (actions, payload, { getStoreState }) => {
    const controller = new CandidateController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.loadCandidatesOfPositions(payload.accountId, payload.positionIds);
    return response?.data as EngagedCandidate[];
  }),

  loadCandidatesForNotifications: thunk(async (actions, payload, { getStoreState }) => {
    const controller = new CandidateController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.loadCandidatesForNotifications(payload.accountId, payload.after, payload.limit);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return response?.data as EngagedCandidateNotification;
  }),

  requestLanguageEvaluationResubmit: thunk(async (actions, payload, { getStoreState }) => {
    const controller = new CandidateController(getStoreState().http.client as HttpClientWithRetry);
    await controller.requestLanguageEvaluationResubmit(payload.phraseId, payload.candidateId, payload.recruiterId);
  }),

  reviewLanguageEvaluation: thunk(async (actions, payload, { getStoreState }) => {
    const controller = new CandidateController(getStoreState().http.client as HttpClientWithRetry);
    await controller.reviewLanguageEvaluation(
      payload.positionId,
      payload.seekerId,
      payload.language,
      payload.phraseId,
      payload.rating,
      payload.fluency,
      payload.pronunciation,
      payload.accuracy,
      payload.status,
    );
  }),
};
