export default {
  cannotEngage: 'Pas assez de crédits pour contacter le candidat',
  outOfCredit: `Oops! Il semble que vous n'avez plus de crédits.`,
  purchaseMore: `Vous avez utilisé tous vos crédits. Vous devez en acheter plus pour continuer à rencontrer des nouveaux candidats.`,
  choosePackage: 'Choisir votre forfait',
  buyCredits: `Acheter des crédits`,
  recommend: 'Recommandé',
  credits: 'Crédits',
  paymentSuccessful: 'Payment réussi!',
  congrats: 'Félicitations! Vous pouvez maintenant contacter encore plus de nouveaux candidats!',
  orderDetails: 'Vous pouvez voir votre confirmation de paiement ainsi que les détails de votre commande en cliquant',
  here: 'ici.',
  goHome: `Retourner à l'accueil`,
};
