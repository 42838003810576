import { AxiosError, AxiosResponse } from 'axios';
import HttpClientWithRetry from '../http/client/HttpClientWithRetry';
import SeekerListGroupType from '../modules/messaging/sidebar/filters/seekerListGroupType';
import SeekerListSortType from '../modules/messaging/sidebar/filters/seekerListSortType';
import { EmployerInitializeData, OnBoardingUserInfoType } from '../modules/auth/userProfileInfoType';
import { OtherAccountTypeEnum } from '../modules/calendar/OtherAccountTypeEnum';

export class UserController {
  constructor(private readonly httpClient: HttpClientWithRetry) {}

  async updateConfig(
    userId: string,
    selectedGroup?: SeekerListGroupType,
    selectedSort?: SeekerListSortType,
    selectedPreference?: boolean,
  ): Promise<void> {
    const path = `/api/v1/user/${userId}/config`;
    const body = { selectedGroup, selectedSort, showWarningOnCandidateHire: selectedPreference };
    await this.httpClient.post(path, body);
  }

  async updateLocale(userId: string, locale: string): Promise<void> {
    const path = `/api/v1/user/${userId}/locale`;
    const body = {
      locale,
    };
    await this.httpClient.post(path, body);
  }

  async updateNotificationStatus(userId: string, notificationId: string): Promise<void> {
    const path = `/api/v1/user/${userId}/notification/${notificationId}/readStatus`;
    const body = {};
    await this.httpClient.post(path, body);
  }

  async updateUserDetail(userId: string, userDetail: OnBoardingUserInfoType): Promise<void> {
    const path = `/api/v1/user/${userId}`;
    const body = { ...userDetail };
    await this.httpClient.post(path, body);
  }

  async initializeAsEmployer(userId: string, employerInitializeData: EmployerInitializeData): Promise<void> {
    const path = `/api/v1/user/${userId}/initializeAsEmployer`;
    await this.httpClient.post(path, employerInitializeData);
  }

  async employerOnboarded(userId: string): Promise<void> {
    const path = `/api/v1/user/${userId}/employerOnboarded`;
    await this.httpClient.post(path, {});
  }

  async validateOtherCalendarAuthToken(
    accountId: string,
    userId: string,
    code: string,
    calendarAccount: OtherAccountTypeEnum,
  ): Promise<AxiosResponse | undefined> {
    const path = `/api/v1/account/${accountId}/user/${userId}/calendar/${
      calendarAccount === OtherAccountTypeEnum.GOOGLE ? 'google' : 'microsoft'
    }`;
    return this.httpClient
      .post(path, { code })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((error: AxiosError) => {
        return error.response;
      });
  }

  async getOtherCalendarEvent(
    accountId: string,
    userId: string,
    email: string,
    fromDate: string,
    toDate: string,
    calendarAccount: OtherAccountTypeEnum,
  ): Promise<AxiosResponse | undefined> {
    const path = `/api/v1/account/${accountId}/user/${userId}/calendar/${
      calendarAccount === OtherAccountTypeEnum.GOOGLE ? 'google' : 'microsoft'
    }/${email}/?fromDate=${fromDate}&toDate=${toDate}`;
    return this.httpClient
      .get(path)
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((error: AxiosError) => {
        return error.response;
      });
  }

  async updateCheckedEmailStatus(
    accountId: string,
    userId: string,
    email: string,
    calendarAccount: OtherAccountTypeEnum,
  ): Promise<void> {
    const path = `/api/v1/account/${accountId}/user/${userId}/calendar/${
      calendarAccount === OtherAccountTypeEnum.GOOGLE ? 'google' : 'microsoft'
    }/${email}/status`;
    await this.httpClient.post(path, {
      active: false,
    });
  }

  async removeOtherCalendarAccountAuth(
    accountId: string,
    userId: string,
    email: string,
    calendarAccount: OtherAccountTypeEnum,
  ): Promise<void> {
    const path = `/api/v1/account/${accountId}/user/${userId}/calendar/${
      calendarAccount === OtherAccountTypeEnum.GOOGLE ? 'google' : 'microsoft'
    }/${email}`;
    await this.httpClient.delete(path);
  }
}
