/** @jsx jsx */
import { jsx } from 'theme-ui';
import Slider from 'react-slick';

/* index.css */
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Box } from '@chakra-ui/core';

import sliderOne from '../../../assets/img/slider-1.png';
import { sliderStyle } from './LoginView.styles';

export const CarouselView: React.FC = () => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Box w="100%" css={sliderStyle}>
      <Slider {...settings}>
        <Box backgroundImage={`url(${sliderOne})`} height="100vh" d="flex !important" alignItems="center" justifyContent="center">
          <h3>
            Hire Hourly Workers
            <br />
            <span>In Minutes</span>
            Not Days
          </h3>
        </Box>
        <Box backgroundImage={`url(${sliderOne})`} height="100vh" d="flex !important" alignItems="center" justifyContent="center">
          <h3>
            Hire Hourly Workers
            <br />
            <span>In Minutes</span>
            Not Days
          </h3>
        </Box>
      </Slider>
    </Box>
  );
};
