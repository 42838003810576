import { AxiosResponse } from 'axios';
import HttpClientWithRetry from '../http/client/HttpClientWithRetry';
import { UserProfileType } from '../modules/profile/ProfileTypes';
import { setProfilePictureRef } from '../firebase/firestore/storage';
import { TeamMemberInfoType } from '../modules/administration/team/TeamType';

export class ProfileManagementController {
  constructor(private readonly httpClient: HttpClientWithRetry) {}

  async updateProfile(userId: string, userProfile: UserProfileType): Promise<AxiosResponse> {
    const path = `/api/v1/user/${userId}`;
    return this.httpClient.post(path, userProfile);
  }

  async uploadProfilePicture(fileName: string, profilePicture: File): Promise<void> {
    await setProfilePictureRef(fileName, profilePicture);
  }

  async createTeam(accountId: string, name: string): Promise<AxiosResponse> {
    const path = `api/v1/account/${accountId}/createTeam`;
    return this.httpClient.post(path, { name });
  }

  async updateTeam(accountId: string, name: string): Promise<AxiosResponse> {
    const path = `api/v1/account/${accountId}/update`;
    return this.httpClient.put(path, { name });
  }

  async removeTeamMember(accountId: string, userId: string) {
    const path = `/api/v1/account/${accountId}/user/${userId}/remove`;
    return this.httpClient.post(path, {});
  }

  async invite(accountId: string, teamMemberInfo: TeamMemberInfoType): Promise<AxiosResponse> {
    const path = `api/v1/account/${accountId}/invite`;
    return this.httpClient.post(path, teamMemberInfo);
  }

  async resendInvite(accountInviteId: string, userId: string): Promise<AxiosResponse> {
    const path = `/api/v1/accountInvite/${accountInviteId}/user/${userId}/reinvite`;
    return this.httpClient.post(path, {});
  }

  async cancelInvite(accountInviteId: string, userId: string) {
    const path = `/api/v1/accountInvite/${accountInviteId}/user/${userId}/cancel`;
    return this.httpClient.post(path, {});
  }

  async promoteTeamMember(accountId: string, userId: string) {
    const path = `/api/v1/account/${accountId}/user/${userId}/promote`;
    await this.httpClient.post(path, {});
  }

  async demoteTeamMember(accountId: string, userId: string) {
    const path = `/api/v1/account/${accountId}/user/${userId}/demote`;
    await this.httpClient.post(path, {});
  }

  async getCandidateNumberInPreferences(accountId: string, daysFilter: number): Promise<AxiosResponse> {
    const path = `/api/v1/account/${accountId}/candidates/count?daysFilter=${daysFilter}`;
    return this.httpClient.get(path);
  }
}
