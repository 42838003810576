import { Thunk, thunk } from 'easy-peasy';
import { AxiosResponse } from 'axios';
import { HttpStoreModel } from '../../../http/models/httpModel';
import HttpClientWithRetry from '../../../http/client/HttpClientWithRetry';
import { ProductController } from '../../../controllers/productController';
import { GetProductResponse, ReceiptType } from '../TokenTypes';

export type ProductStoreModel = {
  product: ProductModel;
};

export interface ProductModel {
  getProducts: Thunk<ProductModel, null, void, HttpStoreModel, Promise<GetProductResponse>>;
  buyToken: Thunk<
    ProductModel,
    { productId: string; priceId: string; successUrl: string; cancelUrl: string },
    void,
    HttpStoreModel,
    Promise<AxiosResponse>
  >;
  getReceipt: Thunk<ProductModel, { accountId: string }, void, HttpStoreModel, Promise<ReceiptType>>;
}

export const productStoreModel: ProductModel = {
  getProducts: thunk(async (actions, payload, { getStoreState }) => {
    const controller = new ProductController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.getProductList();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return response?.data?.data as GetProductResponse;
  }),

  buyToken: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new ProductController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.buyToken(payload.productId, payload.priceId, payload.successUrl, payload.cancelUrl);
    return response;
  }),

  getReceipt: thunk(async (actions, payload, { getStoreState }) => {
    const controller = new ProductController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.getTokenReceipt(payload.accountId);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return response?.data?.data as ReceiptType;
  }),
};
