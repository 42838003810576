import administration from './administration';
import app from './app';
import appointmentMessages from './appointmentMessages';
import audioEvaluation from './audioEvaluation';
import browserAlertDialog from './browserAlertDialog';
import businessManagement from './businessManagement';
import calendar from './calendar';
import candidateHistory from './candidateHistory';
import candidateStatus from './candidateStatus';
import certn from './certn';
import common from './common';
import createJobPosition from './createJobPosition';
import error from './error';
import hireDetail from './hireDetail';
import jobPosition from './jobPosition';
import login from './login';
import lowAvailability from './lowAvailability';
import managementOverview from './managementOverview';
import meet from './meet';
import messaging from './messaging';
import modernHire from './modernHire';
import noSeekerMsg from './noSeekerMsg';
import notes from './notes';
import notification from './notification';
import offerAndHireProcess from './offerAndHireProcess';
import profile from './profile';
import recruiterManagement from './recruiterManagement';
import requisition from './requisition';
import support from './support';
import team from './team';
import toDoList from './toDoList';
import tokenPayment from './tokenPayment';
import unSubscribe from './unSubscribe';
import uploadCompanyLogo from './uploadCompanyLogo';

export default {
  app,
  common,
  login,
  candidateStatus,
  messaging,
  appointmentMessages,
  error,
  browserAlertDialog,
  noSeekerMsg,
  notes,
  candidateHistory,
  calendar,
  meet,
  certn,
  notification,
  uploadCompanyLogo,
  createJobPosition,
  businessManagement,
  jobPosition,
  administration,
  lowAvailability,
  recruiterManagement,
  profile,
  team,
  hireDetail,
  support,
  unSubscribe,
  modernHire,
  requisition,
  audioEvaluation,
  toDoList,
  offerAndHireProcess,
  managementOverview,
  tokenPayment,
};
