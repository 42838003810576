export default {
  login: 'Connexion',
  phoneNumberInputPlaceholder: 'Votre cellulaire',
  confirmationCodeInputPlaceholder: 'Code de confirmation',
  sendCodeButton: 'Envoyer code',
  sendCodeTitle: {
    1: 'Vous avez un compte ',
    2: 'Appyhere pour Recruteurs',
    3: '?',
  },
  sendCodeSubtitle: 'Entrez le même numéro de cellulaire utilisé pour recevoir un code',
  sendCodeSignUpSubtitle: 'Entrez votre numéro de téléphone mobile pour recevoir un code',
  sendCodeFooterLabel: {
    1: 'Vous ',
    2: 'DEVEZ ',
    3: "configurer un compte employeur avant d'utiliser l'application",
  },
  sendCodeFooterButton: "Appuyez ici pour plus d'informations",
  loginTitle: 'Vous avez reçu un code sur votre téléphone cellulaire',
  loginSubtitle: 'Entrez-le et tous vos nouveaux messages apparaîtront',
  loginFooterLabel: "Vous n'avez pas reçu de code sur votre téléphone cellulaire?",
  loginFooterButton: 'Renvoyer le code',
  wrongCountryCode: "AppyHere n'est pas disponible dans votre pays.",
  wrongCode: '* MAUVAIS CODE, RÉESSAYER',
  invalid_user_alert_title: 'Quelque chose ne va pas',
  invalid_user_alert_description: "Nous n'avons pas trouvé de compte, veuillez réessayer",
  signUpLabel: 'Inscription',
  signUpSendCodeTitle: "S'inscrire à Appyhere",
  signUpSendCodeSubTitle: "Simplifiez votre processus d'embauche et recrutez plus rapidement",
  continue: 'Continue',
  termsOfService: `En vous inscrivant à AppyHere, vous acceptez nos <1>  conditions d'utilisation</1>.`,
  privacyTerms1: 'Nous utilisons votre numéro de téléphone seulement pour vous identifier.',
  privacyTerms2: `Nous ne vendons ni n'utilisons votre numéro de téléphone à des fins de commercialisation.`,
  signUp: {
    signUpTitle: 'Bienvenue chez AppyHere',
    signUpSubTitle: 'Entrez vos renseignements',
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    emailAddress: 'Adresse courriel',
    submitText: 'Joindre Appyhere',
    firstNameRequiredMessage: 'Le prénom est requis',
    lastNameRequiredMessage: 'Le nom de famille est requis',
    emailAddressRequiredMessage: 'Le courriel est requis',
    emailAddressValidateMessage: 'Le courriel doit être un format valide',
  },
};
