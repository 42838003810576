import firebase from 'firebase/app';
import 'firebase/storage';

import { useMemo } from 'react';
import { useDownloadURL } from 'react-firebase-hooks/storage';

export const useSeekerResume = (seekerId?: string, fileName?: string): string | undefined => {
  const [downloadUrl, loading] = useDownloadURL(firebase.storage().ref(`/resumes/seekers/${seekerId}/${fileName}`));

  return useMemo(() => {
    if (!loading && downloadUrl) {
      return downloadUrl;
    }

    return undefined;
  }, [downloadUrl, loading]);
};
