/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { Fragment, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { RiUserFill } from 'react-icons/ri';
import { IoMdMail } from 'react-icons/io';
import Joi from 'joi';
import { loginButtonStyle, loginFormTopText } from '../login/loginForm/LoginForm.styles';
import { Label } from '../../common';
import colors from '../../../styles/colors';
import i18n from '../../../locales/i18n';
import {
  clearErrorMessage,
  errorFormat,
  FormattedError,
  getErrorMessage,
  hasErrorMessage,
  updateObject,
} from '../../../utils/FormErrorUtils';
import { OnBoardingUserInfoType } from '../userProfileInfoType';
import { useStoreActions, useStoreState } from '../../../models/hooks';

export type SignUpFormProps = {
  isSMB?: boolean;
};

export const SignUpView = ({ isSMB }: SignUpFormProps): JSX.Element => {
  const { t } = useTranslation('login');
  const [isLoadingBtn, setIsLoadingBtn] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<FormattedError[]>([]);
  const [onBoardingUserInfo, setOnBoardingUserInfo] = useState<OnBoardingUserInfoType>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });

  const { updateUserDetail, initializeAsEmployer, employerOnboarded } = useStoreActions((actions) => actions.user);
  const appUserId = useStoreState((s) => s.auth.firebaseUser?.uid);
  const appUserPhoneNumber = useStoreState((s) => s.auth.firebaseUser?.phoneNumber);

  const schema = Joi.object()
    .options({ abortEarly: false })
    .keys({
      firstName: Joi.string()
        .max(30)
        .required()
        .messages({
          'string.empty': t('signUp.firstNameRequiredMessage'),
        }),
      lastName: Joi.string()
        .max(30)
        .required()
        .messages({
          'string.empty': t('signUp.lastNameRequiredMessage'),
        }),
      email: Joi.string()
        .required()
        .messages({
          'string.empty': t('signUp.emailAddressRequiredMessage'),
          'string.email': t('signUp.emailAddressValidateMessage'),
        })
        .email({ minDomainSegments: 2, tlds: { allow: ['com', 'net'] } }),

      phone: Joi.string().allow('').allow(null).optional(),
    });

  const formValidation = (formData: OnBoardingUserInfoType) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { error } = schema.validate(formData);

    if (error) {
      setFormErrors(errorFormat(error.details));
      return false;
    }

    setFormErrors([]);
    return true;
  };

  const handleChange = (fieldName: string, event: React.ChangeEvent<HTMLInputElement>) => {
    updateObject(onBoardingUserInfo, fieldName, event.target.value);

    setFormErrors(clearErrorMessage(formErrors, fieldName));
    setOnBoardingUserInfo({ ...onBoardingUserInfo });
  };

  const submitOnBoardingUserInformation = async () => {
    if (formValidation(onBoardingUserInfo) && appUserId && appUserPhoneNumber) {
      onBoardingUserInfo.phone = appUserPhoneNumber;

      try {
        setIsLoadingBtn(true);
        await initializeAsEmployer({
          userId: appUserId,
          employerInitializeData: {
            phoneNumber: appUserPhoneNumber,
            locale: moment.locale(i18n.language),
            timeOffset: moment.parseZone(new Date()).utcOffset(),
            smb: isSMB || undefined,
          },
        });
        await employerOnboarded({ userId: appUserId });
        await updateUserDetail({ userId: appUserId, userDetail: onBoardingUserInfo });
      } finally {
        setIsLoadingBtn(false);
      }
    }
  };
  return (
    <Fragment>
      <Box data-testid="SignUpForm" mt={10}>
        <Flex flexDirection="column">
          <Box css={loginFormTopText} mb={8}>
            <Label size="lg" whiteSpace="initial" mb={1}>
              {t('signUp.signUpTitle')}
            </Label>
            <Label size="base" color={colors.blue.default} whiteSpace="initial">
              {t('signUp.signUpSubTitle')}
            </Label>
          </Box>
          <Box>
            <Flex mb={4}>
              <FormControl mr={4} isInvalid={hasErrorMessage(formErrors, 'firstName')}>
                <FormLabel>{t('signUp.firstName')}</FormLabel>
                <InputGroup>
                  <InputLeftElement p="0" pointerEvents="none">
                    <RiUserFill color={colors.gray['300']} />
                  </InputLeftElement>
                  <Input
                    className="input-type"
                    key="FirstNameInput"
                    autoFocus
                    data-testid="FirstNameInput"
                    type="text"
                    maxLength={14}
                    placeholder={t('signUp.firstName')}
                    onChange={(e) => handleChange('firstName', e)}
                  />
                </InputGroup>
                <FormErrorMessage>{getErrorMessage(formErrors, 'firstName')}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={hasErrorMessage(formErrors, 'lastName')}>
                <FormLabel>{t('signUp.lastName')}</FormLabel>
                <Input
                  className="input-type"
                  key="LastNameInput"
                  data-testid="LastNameInput"
                  type="text"
                  maxLength={14}
                  placeholder={t('signUp.lastName')}
                  onChange={(e) => handleChange('lastName', e)}
                />
                <FormErrorMessage>{getErrorMessage(formErrors, 'lastName')}</FormErrorMessage>
              </FormControl>
            </Flex>
            <FormControl isInvalid={hasErrorMessage(formErrors, 'email')}>
              <FormLabel>{t('signUp.emailAddress')}</FormLabel>
              <InputGroup mb={4}>
                <InputLeftElement p="0" pointerEvents="none">
                  <IoMdMail color={colors.gray['300']} />
                </InputLeftElement>
                <Input
                  className="input-type"
                  key="EmailAddressInput"
                  data-testid="EmailAddressInput"
                  type="email"
                  placeholder={t('signUp.emailAddress')}
                  onChange={(e) => handleChange('email', e)}
                />
              </InputGroup>
              <FormErrorMessage>{getErrorMessage(formErrors, 'email')}</FormErrorMessage>
            </FormControl>
            <Flex justifyContent="end">
              <Button
                w="100%"
                isLoading={isLoadingBtn}
                data-testid="SubmitUserInfoButton"
                css={loginButtonStyle}
                onClick={submitOnBoardingUserInformation}
              >
                {t('signUp.submitText')}
              </Button>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </Fragment>
  );
};
