import React, { useCallback, useEffect, useState } from 'react';

import { Box, Flex, Select, SimpleGrid, Stat, Text, Stack } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { FaArrowDown } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import isUndefined from 'lodash/isUndefined';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import round from 'lodash/round';
import { Header } from '../../app/topBar/Header';
import colors from '../../styles/colors';
import HireOfferLineChart from './hireOfferLineChart/HireOfferLineChart';
import AgedRequisitionBarChart from './agedRequisitionBarChart/AgedRequisitionBarChart';
import i18n from '../../locales/i18n';
import { reactDatePickerCSS } from '../calendar/scheduleSidebar/ReactDatePicker.Style';
import { useStoreActions, useStoreState } from '../../models/hooks';
import { DateSelectRange, ExtendedGaugeNeedleType, GaugeNeedleType } from './ManagementOverviewType';
import GaugeChart from '../common/GaugeNeedle/GaugeNeedle';
import {
  AccountOfferStats,
  AccountRequisitionStats,
  AgedRequisitionDetails,
  DayWiseReport,
} from '../../firebase/firestore/documents/managementOverview';
import { AppLoadingSpinner } from '../../app/components';

const renderLabelAndValue = (label: string, value: number, isDarkMode: boolean) => {
  return (
    <Flex alignItems="flex-start" direction="column">
      <Text color={isDarkMode ? colors.white : colors.black} fontSize={14}>
        {label}
      </Text>
      <Text color={isDarkMode ? colors.white : colors.black} fontSize={30} fontWeight={600}>
        {value}
      </Text>
    </Flex>
  );
};

const HireOfferStatBox = ({
  marginTop,
  comparisonPercentage,
  titleLabel,
  count,
  total,
  isDarkMode,
}: {
  marginTop?: number | undefined;
  comparisonPercentage: number | undefined;
  titleLabel: string | undefined;
  count: number | undefined;
  total?: number | undefined;
  isDarkMode: boolean;
}) => {
  const isComparisonPositive = comparisonPercentage && comparisonPercentage >= 0;
  return (
    <Box bg={isDarkMode ? colors.dimWhite : colors.white} borderRadius="md" width="100%">
      <Stat>
        <Box borderRadius="md" mt={marginTop}>
          <Flex direction="column" alignItems="center" p="0px 16px">
            <Text color={isDarkMode ? colors.neutral[200] : colors.black} fontWeight="bold" fontSize="14px" mb={4}>
              {titleLabel}
            </Text>
            <Flex alignItems="center">
              <Text color={isDarkMode ? colors.neutral[200] : colors.black} fontWeight="bold" fontSize="35px" mr={4}>
                {count && round(count, 2)}
              </Text>

              <Flex direction="column">
                <Box
                  display="flex"
                  alignItems="center"
                  backgroundColor={isComparisonPositive ? colors.green[400] : colors.red[500]}
                  padding={1}
                  borderRadius="sm"
                >
                  <FaArrowDown
                    data-testid={`comparisonIcon-${isComparisonPositive ? 'positive' : 'negative'}`}
                    fontSize={16}
                    color={isComparisonPositive ? colors.white : colors.customRed}
                    style={{ transform: isComparisonPositive ? 'rotate(180deg)' : '' }}
                    fontWeight="bold"
                  />

                  <Text fontWeight="bold" fontSize={12}>
                    {!isNil(comparisonPercentage) ? `${comparisonPercentage.toFixed(1)} %` : '-- %'}
                  </Text>
                </Box>
                {!isNil(total) && (
                  <Text
                    fontSize={20}
                    fontWeight={700}
                    color={isDarkMode ? colors.whiteAlpha700 : colors.black}
                    textAlign="center"
                  >
                    {round(total, 2)}
                  </Text>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Stat>
    </Box>
  );
};

HireOfferStatBox.defaultProps = {
  marginTop: 4,
  total: undefined,
};

export const ManagementOverviewView = (): JSX.Element => {
  const { t } = useTranslation('managementOverview');

  const [startDate, setStartDate] = useState(moment().subtract(9, 'days').toDate());
  const [endDate, setEndDate] = useState(moment().subtract(3, 'days').toDate());

  const [isDarkMode, setisDarkMode] = useState<boolean>(false);
  const [gaugeNeedles, setGaugeNeedles] = useState<GaugeNeedleType[] | undefined>(undefined);
  const [openRequisitions, setOpenRequisitions] = useState<ExtendedGaugeNeedleType | undefined>(undefined);
  const [totalAgedRequisitions, setTotalAgedRequisitions] = useState('--');
  const [responseAgedRequisitionDetails, setResponseAgedRequisitionDetails] = useState<AgedRequisitionDetails | undefined>(
    undefined,
  );

  const [hiringOverviewLoading, setHiringOverviewLoading] = useState(false);
  const [accountOfferStatsLoading, setAccountOfferStatsLoading] = useState(false);

  const [hireAndOfferStats, setHireAndOfferData] = useState<
    | {
        count: number;
        title: string;
        percentage: number;
        total?: number;
      }[]
    | undefined
  >(undefined);
  const [dayWiseReport, setDayWiseReport] = useState<DayWiseReport[] | undefined>(undefined);
  const [accountRequisitionStats, setAccountRequisitionStats] = useState<AccountRequisitionStats>();
  const [accountOfferStats, setAccountOfferStats] = useState<AccountOfferStats>();

  const accountId = useStoreState((s) => s.app.accounts?.id);

  const managementOverviewConfig = useStoreState((s) => s.app.accounts?.configuration?.managementOverview);

  const { getAccountRequisitionStats, getAccountOfferStats } = useStoreActions((actions) => actions.managementOverview);

  const buildDataForGaugeAndChart = useCallback(() => {
    if (!isNil(accountRequisitionStats)) {
      const { recruitmentProgressStats, requisitionsOverallCount, agedRequisitionDetails } = accountRequisitionStats;

      const gaugeNeedlesTemplate = [
        {
          title: t('hiringOverview.outstandingOffers'),
          value: recruitmentProgressStats?.outstandingOffersCount,
          minValue: managementOverviewConfig?.outstandingOffers?.minValue || 0,
          maxValue: managementOverviewConfig?.outstandingOffers?.maxValue || 50,
          subArc: [
            {
              limit: managementOverviewConfig?.outstandingOffers?.subArcLimits?.safe || 35,
              color: colors.green[400],
              tooltip: { text: 'SAFE' },
            },
            {
              limit: managementOverviewConfig?.outstandingOffers?.subArcLimits?.normal || 45,
              color: colors.yellow[500],
              tooltip: { text: 'NORMAL' },
            },
            {
              limit: managementOverviewConfig?.outstandingOffers.subArcLimits?.danger || 50,
              color: colors.red[500],
              tooltip: { text: 'DANGER' },
            },
          ],
        },
        {
          title: t('hiringOverview.hiresProcessing'),
          value: recruitmentProgressStats?.hireProcessStatsCount,
          minValue: managementOverviewConfig?.hiresProcessing?.minValue || 0,
          maxValue: managementOverviewConfig?.hiresProcessing?.maxValue || 100,
          subArc: [
            {
              limit: managementOverviewConfig?.hiresProcessing?.subArcLimits?.safe || 45,
              color: colors.green[400],
              tooltip: { text: 'SAFE' },
            },
            {
              limit: managementOverviewConfig?.hiresProcessing?.subArcLimits?.normal || 75,
              color: colors.yellow[500],
              tooltip: { text: 'NORMAL' },
            },
            {
              limit: managementOverviewConfig?.hiresProcessing?.subArcLimits?.danger || 100,
              color: colors.red[500],
              tooltip: { text: 'DANGER' },
            },
          ],
        },
        {
          title: t('hiringOverview.backgroundChecksDelayed'),
          value: recruitmentProgressStats?.backGroundDelayedStatsCount,
          minValue: managementOverviewConfig?.backgroundChecksDelayed?.minValue || 0,
          maxValue: managementOverviewConfig?.backgroundChecksDelayed?.maxValue || 10,
          subArc: [
            {
              limit: managementOverviewConfig?.backgroundChecksDelayed?.subArcLimits?.safe || 5,
              color: colors.green[400],
              tooltip: { text: 'SAFE' },
            },
            {
              limit: managementOverviewConfig?.backgroundChecksDelayed?.subArcLimits?.normal || 8,
              color: colors.yellow[500],
              tooltip: { text: 'NORMAL' },
            },
            {
              limit: managementOverviewConfig?.backgroundChecksDelayed?.subArcLimits?.danger || 10,
              color: colors.red[500],
              tooltip: { text: 'DANGER' },
            },
          ],
        },
        {
          title: t('hiringOverview.overdueApprovals'),
          value: recruitmentProgressStats?.overdueApprovalsCount,
          minValue: managementOverviewConfig?.overdueApprovals?.minValue || 0,
          maxValue: managementOverviewConfig?.overdueApprovals?.maxValue || 30,
          subArc: [
            {
              limit: managementOverviewConfig?.overdueApprovals?.subArcLimits?.safe || 15,
              color: colors.green[400],
              tooltip: { text: 'SAFE' },
            },
            {
              limit: managementOverviewConfig?.overdueApprovals?.subArcLimits?.normal || 24,
              color: colors.yellow[500],
              tooltip: { text: 'NORMAL' },
            },
            {
              limit: managementOverviewConfig?.overdueApprovals?.subArcLimits?.danger || 30,
              color: colors.red[500],
              tooltip: { text: 'DANGER' },
            },
          ],
        },
        {
          title: t('hiringOverview.stalledCandidates'),
          value: recruitmentProgressStats?.stalledCandidatesCount,
          minValue: managementOverviewConfig?.stalledCandidates?.minValue || 0,
          maxValue: managementOverviewConfig?.stalledCandidates?.maxValue || 50,
          subArc: [
            {
              limit: managementOverviewConfig?.stalledCandidates?.subArcLimits?.safe || 36,
              color: colors.green[400],
              tooltip: { text: 'SAFE' },
            },
            {
              limit: managementOverviewConfig?.stalledCandidates?.subArcLimits?.normal || 45,
              color: colors.yellow[500],
              tooltip: { text: 'NORMAL' },
            },
            {
              limit: managementOverviewConfig?.stalledCandidates?.subArcLimits?.danger || 50,
              color: colors.red[500],
              tooltip: { text: 'DANGER' },
            },
          ],
        },
      ];

      const openRequisitionsTemplate = {
        title: t('hiringOverview.openRequisitions'),
        value: requisitionsOverallCount?.unfilled,
        minValue: managementOverviewConfig?.openRequisitions?.minValue || 0,
        maxValue: managementOverviewConfig?.openRequisitions?.maxValue || 500,
        subArc: [
          {
            limit: managementOverviewConfig?.openRequisitions?.subArcLimits?.safe || 250,
            color: colors.green[400],
            tooltip: { text: 'SAFE' },
          },
          {
            limit: managementOverviewConfig?.openRequisitions?.subArcLimits?.normal || 400,
            color: colors.yellow[500],
            tooltip: { text: 'NORMAL' },
          },
          {
            limit: managementOverviewConfig?.openRequisitions?.subArcLimits?.danger || 500,
            color: colors.red[500],
            tooltip: { text: 'DANGER' },
          },
        ],
        atRisk: requisitionsOverallCount?.atRisk,
        positionsImpacted: requisitionsOverallCount?.positionImpacted,
        unfilled: requisitionsOverallCount.unfilled,
      };
      setOpenRequisitions(openRequisitionsTemplate);
      setGaugeNeedles(gaugeNeedlesTemplate);
      setResponseAgedRequisitionDetails(agedRequisitionDetails);
    }
  }, [accountRequisitionStats, t, managementOverviewConfig]);

  const buildDataForHireAndOffer = useCallback(() => {
    if (!isNil(accountOfferStats)) {
      const {
        hireProposedCandidate,
        hireAcceptedCandidate,
        hireRejectedCandidate,
        offerMadeCandidate,
        offerAcceptedCandidate,
        offerRejectedCandidate,
        offerRevokedCandidate,
        averageHrSubmissionReport,
        averageTimeReportToFillForm,
        dayWiseReport: responseDayWiseReport,
      } = accountOfferStats;
      const hireAndOfferDataArray = [
        {
          count: hireProposedCandidate.count,
          title: t('hireOfferStat.hireProposed'),
          percentage: hireProposedCandidate.percentage,
          total: hireProposedCandidate?.total,
        },
        {
          count: hireAcceptedCandidate.count,
          title: t('hireOfferStat.hireApproved'),
          percentage: hireAcceptedCandidate.percentage,
          total: hireAcceptedCandidate?.total,
        },
        {
          count: hireRejectedCandidate.count,
          title: t('hireOfferStat.hireRejected'),
          percentage: hireRejectedCandidate.percentage,
          total: hireRejectedCandidate?.total,
        },
        {
          count: offerMadeCandidate.count,
          title: t('hireOfferStat.offersMade'),
          percentage: offerMadeCandidate.percentage,
          total: offerMadeCandidate?.total,
        },
        {
          count: offerAcceptedCandidate.count,
          title: t('hireOfferStat.offersAccepted'),
          percentage: offerAcceptedCandidate.percentage,
          total: offerAcceptedCandidate?.total,
        },
        {
          count: offerRejectedCandidate.count,
          title: t('hireOfferStat.offersRejected'),
          percentage: offerRejectedCandidate.percentage,
          total: offerRejectedCandidate?.total,
        },
        {
          count: offerRevokedCandidate.count,
          title: t('hireOfferStat.offersRevoked'),
          percentage: offerRevokedCandidate.percentage,
          total: offerRevokedCandidate?.total,
        },
        {
          count: averageHrSubmissionReport.count,
          title: t('hireOfferStat.hRSubmission'),
          percentage: averageHrSubmissionReport.percentage,
        },
        {
          count: averageTimeReportToFillForm.count,
          title: t('hireOfferStat.timeToFill'),
          percentage: averageTimeReportToFillForm.percentage,
        },
      ];

      setHireAndOfferData(hireAndOfferDataArray);
      setDayWiseReport(responseDayWiseReport);
    }
  }, [accountOfferStats, t]);

  const onGetHiringOverviewData = useCallback(
    async (account: string) => {
      setHiringOverviewLoading(true);
      const accountRequisitionStatsRes = await getAccountRequisitionStats({ accountId: account });
      setAccountRequisitionStats(accountRequisitionStatsRes);
      setHiringOverviewLoading(false);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [managementOverviewConfig],
  );

  useEffect(() => {
    /* istanbul ignore else */
    if (accountId) {
      /* eslint-disable @typescript-eslint/no-floating-promises */
      onGetHiringOverviewData(accountId);
    }
  }, [onGetHiringOverviewData, accountId]);

  // eslint-disable-next-line no-shadow
  const onGetAccountOfferStats: (userAccountId: string) => void = useCallback(
    async (userAccountId) => {
      setAccountOfferStatsLoading(true);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const data: AccountOfferStats = await getAccountOfferStats({ accountId: userAccountId, startDate, endDate });
      // istanbul ignore else
      if (data) {
        setAccountOfferStats(data);
      }
      setAccountOfferStatsLoading(false);
    },
    [getAccountOfferStats, startDate, endDate],
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    accountId && onGetAccountOfferStats(accountId);
  }, [accountId, onGetAccountOfferStats, startDate, endDate]);

  useEffect(() => {
    buildDataForGaugeAndChart();
  }, [t, accountRequisitionStats, buildDataForGaugeAndChart]);

  useEffect(() => {
    buildDataForHireAndOffer();
  }, [t, accountOfferStats, buildDataForHireAndOffer]);

  const handleDateOptionChange = (value: DateSelectRange) => {
    if (!isEmpty(value)) {
      // eslint-disable-next-line default-case
      switch (value) {
        case DateSelectRange.ONE_WEEK:
          setStartDate(moment().subtract(1, 'week').toDate());
          break;

        case DateSelectRange.TWO_WEEKS:
          setStartDate(moment().subtract(2, 'weeks').toDate());
          break;

        case DateSelectRange.ONE_MONTH:
          setStartDate(moment().subtract(1, 'month').toDate());
          break;

        case DateSelectRange.THREE_MONTHS:
          setStartDate(moment().subtract(3, 'months').toDate());
          break;
      }
      setEndDate(moment().toDate());
    }
  };

  return (
    <Flex backgroundColor={isDarkMode ? colors.darkBg : colors.antiFlashWhite} direction="column" overflow="hidden">
      <Header showMenu showPageTitle={t('pageTitle')} setDarkModeTo={setisDarkMode} data-testid="HeaderContainer" />

      <Box
        p={1}
        height="calc(100vh - 64px)"
        overflowY="scroll"
        display="flex"
        flexDirection="column"
        justifyContent="space-around"
      >
        {!hiringOverviewLoading ? (
          <>
            <Stack spacing="4px" direction="row">
              {gaugeNeedles?.map(({ minValue, maxValue, value, subArc, title }) => (
                <Box
                  width="calc(20% - 4px)"
                  background={isDarkMode ? colors.dimWhite : colors.white}
                  borderRadius="md"
                  display="flex"
                  flexDirection="column"
                  key={title}
                >
                  <Box>
                    <Box justifyContent="center" alignItems="center" flex={1}>
                      <Text
                        textAlign="center"
                        color={isDarkMode ? colors.white : colors.black}
                        fontWeight="bold"
                        mr={3}
                        pt={1}
                        fontSize={14}
                      >
                        {title}
                      </Text>
                    </Box>
                    <Box>
                      <GaugeChart
                        value={value}
                        subArcs={subArc}
                        minValue={minValue}
                        maxValue={maxValue}
                        isDarkMode={isDarkMode}
                      />
                    </Box>
                  </Box>
                </Box>
              ))}
            </Stack>

            {/* OPEN REQUISITION AND CHART */}
            {!isUndefined(openRequisitions) && (
              <Box mt={1}>
                <Stack spacing="4px" direction="row" height="25vh" minH="230px">
                  <Flex
                    width="max-content"
                    background={isDarkMode ? colors.dimWhite : colors.white}
                    borderRadius="md"
                    p={5}
                    flexWrap="nowrap"
                  >
                    <Box>
                      <Flex justifyContent="center" alignItems="center">
                        <Text textAlign="center" color={isDarkMode ? colors.white : colors.black} fontWeight="bold" fontSize={14}>
                          {openRequisitions.title}
                        </Text>
                      </Flex>
                      <Box>
                        <GaugeChart
                          value={openRequisitions.value}
                          subArcs={openRequisitions.subArc}
                          minValue={openRequisitions.minValue}
                          maxValue={openRequisitions.maxValue}
                          isDarkMode={isDarkMode}
                        />
                      </Box>
                    </Box>

                    <Box display="flex" justifyContent="space-around" p={6} pl={0}>
                      <Flex justifyContent="space-around" alignItems="flex-start" direction="column">
                        {renderLabelAndValue(t('hiringOverview.atRisk'), openRequisitions?.atRisk, isDarkMode)}
                        {renderLabelAndValue(
                          t('hiringOverview.positionsImpacted'),
                          openRequisitions?.positionsImpacted,
                          isDarkMode,
                        )}
                      </Flex>
                    </Box>
                  </Flex>

                  <Flex
                    width="max-content"
                    background={isDarkMode ? colors.dimWhite : colors.white}
                    borderRadius="md"
                    p={5}
                    flexWrap="wrap"
                  >
                    <Flex alignItems="start" justifyContent="space-between" width="100%">
                      <Flex justifyContent="start" alignItems="center">
                        <Text textAlign="start" color={isDarkMode ? colors.white : colors.black} fontWeight="bold" fontSize={14}>
                          {t('hiringOverview.distributionOfAgedRequisitions')}
                        </Text>
                      </Flex>

                      <Flex alignItems="center">
                        <Text textAlign="center" color={isDarkMode ? colors.white : colors.black} fontWeight="bold" fontSize={14}>
                          {t('hiringOverview.past10DaysNeedDate')}
                        </Text>
                        <Text color={colors.shinyYellow} fontWeight="bold" fontSize={24} pl={1}>
                          {totalAgedRequisitions}
                        </Text>
                      </Flex>
                    </Flex>

                    {!isUndefined(responseAgedRequisitionDetails) && (
                      <AgedRequisitionBarChart
                        agedRequisitionDetails={responseAgedRequisitionDetails}
                        setTotalAgedRequisitions={setTotalAgedRequisitions}
                        isDarkMode={isDarkMode}
                      />
                    )}
                  </Flex>
                </Stack>
              </Box>
            )}
          </>
        ) : (
          <AppLoadingSpinner
            loadingText={t('hiringOverview.loadingHiringOverview')}
            height="50vh"
            color={isDarkMode ? colors.white : colors.black}
          />
        )}

        <Box color="white" maxH="100vh" pr={4}>
          {/* hire and offer section */}
          <Flex alignItems="center" justify="space-between" margin="20px 10px">
            <Text fontSize={18} fontWeight="bold" color={isDarkMode ? colors.neutral[200] : colors.black}>
              {t('hireOfferStat.hireOfferStatistics')}
            </Text>

            <Box css={reactDatePickerCSS} background={colors.dimWhite}>
              <Flex alignItems="center" fontSize="sm" mr={5}>
                <Text fontSize="sm" fontWeight="bold" mx={2} color={isDarkMode ? colors.white : colors.black}>
                  {t('hireOfferStat.from')}
                </Text>
                <DatePicker
                  locale={i18n.language}
                  className="react-datepicker-input react-datepicker-input-dark react-datepicker-input-small  bold blackColor"
                  selected={startDate}
                  onChange={/* istanbul ignore next */ (date) => setStartDate(date as Date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  maxDate={moment(endDate).subtract(1, 'days').toDate()}
                  dateFormat="dd MMM, yyyy"
                />
                <Text fontWeight="bold" mx={2} color={colors.black}>
                  {t('hireOfferStat.to')}
                </Text>
                <DatePicker
                  locale={i18n.language}
                  className="react-datepicker-input react-datepicker-input-dark react-datepicker-input-small  bold blackColor"
                  selected={endDate}
                  onChange={/* istanbul ignore next */ (date) => setEndDate(date as Date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={moment(startDate).add(1, 'days').toDate()}
                  maxDate={moment().toDate()}
                  dateFormat="dd MMM, yyyy"
                />

                <Select
                  className="blackColor"
                  data-testid="selectDateRange"
                  placeholder={t('hireOfferStat.selectDate')}
                  ml={6}
                  height={6}
                  onChange={(e) => handleDateOptionChange(e.target.value as DateSelectRange)}
                  iconColor="black"
                  background={colors.white}
                  minHeight="30px"
                >
                  <option value={DateSelectRange.ONE_WEEK}>{t('hireOfferStat.oneWeek')}</option>
                  <option value={DateSelectRange.TWO_WEEKS}>{t('hireOfferStat.twoWeek')}</option>
                  <option value={DateSelectRange.ONE_MONTH}>{t('hireOfferStat.oneMonth')}</option>
                  <option value={DateSelectRange.THREE_MONTHS}>{t('hireOfferStat.threeMonth')}</option>
                </Select>
              </Flex>
            </Box>
          </Flex>

          {!accountOfferStatsLoading ? (
            <>
              <SimpleGrid columns={{ base: 1, md: 3, lg: 7 }} spacing={1} mb={1}>
                {hireAndOfferStats?.slice(0, 7).map((hireOfferStat) => (
                  <HireOfferStatBox
                    comparisonPercentage={hireOfferStat.percentage}
                    titleLabel={hireOfferStat?.title}
                    count={hireOfferStat?.count}
                    total={hireOfferStat?.total}
                    key={hireOfferStat.title}
                    isDarkMode={isDarkMode}
                  />
                ))}
              </SimpleGrid>
              <SimpleGrid columns={{ base: 1, md: 3, lg: 7 }} spacing={1}>
                <Box gridColumn={{ base: 'span 1', md: 'span 1', lg: 'span 1' }}>
                  <HireOfferStatBox
                    marginTop={0}
                    comparisonPercentage={hireAndOfferStats?.[7]?.percentage}
                    titleLabel={hireAndOfferStats?.[7]?.title}
                    count={hireAndOfferStats?.[7]?.count}
                    isDarkMode={isDarkMode}
                  />
                  <Box mt={1}>
                    <HireOfferStatBox
                      comparisonPercentage={hireAndOfferStats?.[8]?.percentage}
                      titleLabel={hireAndOfferStats?.[8]?.title}
                      count={hireAndOfferStats?.[8]?.count}
                      marginTop={1}
                      isDarkMode={isDarkMode}
                    />
                  </Box>
                </Box>

                <Box
                  gridColumn={{ base: 'span 1', md: 'span 2', lg: 'span 6' }}
                  background={isDarkMode ? colors.dimWhite : colors.white}
                  borderRadius="md"
                >
                  <HireOfferLineChart dayWiseReport={dayWiseReport} isDarkMode={isDarkMode} />
                </Box>
              </SimpleGrid>
            </>
          ) : (
            <AppLoadingSpinner loadingText={t('hireOfferStat.loadingOfferStatistics')} height="50vh" color={colors.black} />
          )}
        </Box>
      </Box>
    </Flex>
  );
};
