import { Alert, AlertIcon, Box, Flex, Heading, ScaleFade, Spinner, Text, useDisclosure } from '@chakra-ui/core';
import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/table';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiWalk } from 'react-icons/bi';
import { GiPathDistance } from 'react-icons/gi';
import { IoIosBicycle, IoMdCar } from 'react-icons/io';
import { MdTrain } from 'react-icons/md';
import { Cell, Column, useTable } from 'react-table';
import { CandidateMessagingViewModal } from '../../../../app/components/CandidateMessagingViewModal';
import {
  CommuteMode,
  CurrentStatus,
  OfferAndHireProcessData,
} from '../../../../firebase/firestore/documents/offerAndHireProcess';
import { Requisition } from '../../../../firebase/firestore/documents/requisition';
import i18n from '../../../../locales/i18n';
import colors from '../../../../styles/colors';
import { secondsToTime } from '../../../../utils/positionUtils';
import { ViewRequisitionDetail } from '../../../messaging/seeker/candidate/history/ViewRequisitionDetail';
import useSingleRequisitionByPosition from '../../../messaging/seeker/candidate/profile/components/useSingleRequisitionByPosition';
import { CommuteData } from './offerAndHireProcessTableType';

const getCommuteIcon = (mode: string): JSX.Element => {
  switch (mode) {
    case CommuteMode.BICYCLE:
    case CommuteMode.TWO_WHEELER:
      return <IoIosBicycle style={{ color: '#4B5563' }} />;
    case CommuteMode.DRIVE:
      return <IoMdCar style={{ color: '#4B5563' }} />;
    case CommuteMode.TRANSIT:
      return <MdTrain style={{ color: '#4B5563' }} />;
    case CommuteMode.WALK:
      return <BiWalk style={{ color: '#4B5563' }} />;
    default:
      return <GiPathDistance style={{ color: '#4B5563' }} />;
  }
};

const milesToKm = (distance: number, km: string): string => {
  return `${(distance * 1.60934).toLocaleString(i18n.language, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })} ${km}`;
};

export type OfferAndHireProcessTableProps<Data extends Record<string, unknown>> = {
  data: Data[];
  columns: Column<Data>[];
  isLoading: boolean;
};

const OfferAndHireProcessTable = <Data extends Record<string, unknown>>({
  isLoading,
  columns,
  data,
}: OfferAndHireProcessTableProps<Data>) => {
  const { t } = useTranslation('offerAndHireProcess');
  const km = t('km');
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  const [currentCandidateId, setCurrentCandidateId] = useState('');
  const [positionId, setPositionId] = useState('');
  const [requisitionId, setRequisitionId] = useState('');
  const [requisition, setRequisition] = React.useState<Requisition | undefined>(undefined);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenRequisition, onOpen: onOpenRequisition, onClose: onCloseRequisition } = useDisclosure();

  const onActionModalOpen = (candidateId: string) => {
    setCurrentCandidateId(candidateId);
    onOpen();
  };

  const singleRequisition = useSingleRequisitionByPosition(positionId, requisitionId);

  useEffect(() => {
    if (singleRequisition) {
      setRequisition(singleRequisition[0]);
      onOpenRequisition();
    }
  }, [singleRequisition, onOpenRequisition]);

  const handleRequisitionModalOpen = useCallback(
    (clickedCandidateId: string, clickedRequisitionId: string) => {
      setPositionId(clickedCandidateId.split('_')[0]);
      setRequisitionId(clickedRequisitionId);
    },
    [setPositionId, setRequisitionId],
  );

  const onRequisitionModalClose = () => {
    onCloseRequisition();
    setPositionId('');
    setRequisitionId('');
  };

  const renderCell = (cellData: Cell<Data>) => {
    const columnData = (cellData.row.original as unknown) as OfferAndHireProcessData;

    const getCurrentStatus = (status: string) => {
      switch (status) {
        case 'HIRE_PROCESSING':
          return { status: t(`filterOption.${CurrentStatus.HIRE_PROCESSING}`), middleStatus: columnData?.offer?.offerMadeAt };

        case 'HIRED':
          return { status: t(`filterOption.${CurrentStatus.HIRED}`), middleStatus: columnData?.hiredAt };

        case 'OFFER_PROCESSING':
          return {
            status: t(`filterOption.${CurrentStatus.OFFER_PROCESSING}`),
            middleStatus: columnData?.offer?.offerMadeAt,
            madeBy: columnData?.offer?.offerMadeByFullName || 'AppyHere Virtual Recruiter',
          };

        case 'OFFER_REJECTED':
          return {
            status: t(`filterOption.${CurrentStatus.OFFER_REJECTED}`),
            middleStatus: columnData?.computedStatus?.lastUpdate,
            madeBy: columnData?.offer?.offerMadeByFullName || 'AppyHere Virtual Recruiter',
            reason: columnData?.offer?.reason ? columnData?.offer?.reason.toLowerCase().replace(/_/g, ' ') : ' - - ',
          };

        case 'OFFER_REVOKED':
          return {
            status: t(`filterOption.${CurrentStatus.OFFER_REVOKED}`),
            middleStatus: columnData?.computedStatus?.lastUpdate,
            madeBy: columnData?.offer?.offerMadeByFullName || 'AppyHere Virtual Recruiter',
            reason: columnData?.offer?.reason ? columnData?.offer?.reason.toLowerCase().replace(/_/g, ' ') : ' - - ',
          };

        default:
          return {};
      }
    };

    switch (cellData.column.id) {
      case 'businessName':
        return (
          <Box className={cellData.column.id} data-testid={cellData.column.id} py="6px">
            <Text fontSize="14px" color={colors.gray[600]} mb={1}>
              {cellData.value}
            </Text>
            <Heading fontSize="15px" color={colors.gray[800]} mb={1} lineHeight="1.2">
              {columnData?.positionName ? columnData?.positionName : ' - - '}
            </Heading>
          </Box>
        );

      case 'candidateName':
        return (
          <Box
            className={cellData.column.id}
            data-testid={`${cellData.column.id}-${columnData.candidateId}`}
            py="6px"
            fontSize="15px"
            style={{ color: colors.linkBlue, textDecoration: 'underline' }}
            onClick={() => onActionModalOpen(columnData.candidateId)}
          >
            {cellData.value}
          </Box>
        );

      case 'requisitionId':
        return (
          <Box className={cellData.column.id} data-testid={cellData.column.id} py="6px" fontSize="15px">
            {cellData.value ? (
              <Box
                as="span"
                style={{ color: colors.linkBlue, textDecoration: 'underline' }}
                data-testid={`${cellData.column.id}-${cellData.value}`}
                onClick={() => handleRequisitionModalOpen(columnData.candidateId, cellData.value)}
              >
                {cellData.value}
              </Box>
            ) : (
              <Text fontSize="15px" color={colors.gray[1000]}>
                - -
              </Text>
            )}
          </Box>
        );

      case 'commuteData':
        return (
          <Box className={cellData.column.id} data-testid={cellData.column.id} py="6px">
            {cellData.value ? (
              <>
                <Text fontSize="14px" color={colors.gray[600]}>
                  {milesToKm((cellData.value as CommuteData).distanceInMiles, km)}
                </Text>
                <Flex mt={1} fontSize="16px" align="center">
                  <span style={{ marginRight: '6px' }}>{getCommuteIcon((cellData.value as CommuteData).travelMode)}</span>

                  <Heading fontSize="15px" color={colors.gray[800]} lineHeight={1.2}>
                    {secondsToTime((cellData.value as CommuteData).durationInSeconds)}
                  </Heading>
                </Flex>
              </>
            ) : (
              <Text fontSize="15px" color={colors.gray[1000]}>
                - -
              </Text>
            )}
          </Box>
        );

      case 'status':
        return (
          <Box className={cellData.column.id} data-testid={cellData.column.id} py="6px">
            <Text fontSize="14px" color={colors.gray[600]}>
              {getCurrentStatus(cellData.value)?.middleStatus
                ? moment(getCurrentStatus(cellData.value)?.middleStatus).format('DD/MM/YYYY hh:mm A')
                : ' - - '}
            </Text>
            {getCurrentStatus(cellData.value)?.madeBy && (
              <Text fontSize="14px" color={colors.gray[600]}>
                {getCurrentStatus(cellData.value)?.madeBy}
              </Text>
            )}
            <Heading fontSize="15px" color={colors.gray[800]} my={1} lineHeight="1.2">
              {getCurrentStatus(cellData.value)?.status}
            </Heading>
            {getCurrentStatus(cellData.value)?.reason && (
              <Text fontSize="14px" color={colors.gray[600]}>
                {getCurrentStatus(cellData.value)?.reason}
              </Text>
            )}
          </Box>
        );

      default:
        return false;
    }
  };
  return (
    <ScaleFade initialScale={0.7} in unmountOnExit={false}>
      <Box height="100%">
        <Table
          {...getTableProps()}
          className="mainTable"
          key="mainTable"
          border="1px solid #E5E7EB"
          borderCollapse="collapse"
          width="100%"
          height="100%"
          background={colors.white}
          bg="#fff"
        >
          <Thead className="tableHeading" bg="blue.100">
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()} textAlign="left" key={headerGroup.getHeaderGroupProps().key}>
                {headerGroup.headers.map((column) => (
                  <Th
                    {...column.getHeaderProps()}
                    isNumeric={column.isNumeric}
                    key={column.getHeaderProps().key}
                    className="fixBorder"
                    style={{ borderColor: '#d0e9f4' }}
                    px={3}
                    py={2}
                    border="1px solid #E5E7EB"
                    borderCollapse="collapse"
                  >
                    <Box style={{ display: 'flex', maxWidth: '100%' }}>
                      <Text textTransform="none" display="inline">
                        {column.render('Header')}
                      </Text>
                    </Box>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {/* eslint-disable-next-line no-nested-ternary */}
            {isLoading ? (
              <Td colSpan={5}>
                <Flex alignItems="center" justifyContent="center" p={5}>
                  <Spinner colorScheme="blue" size="lg" data-testid="offerAndHireProcessTable-loader" />
                </Flex>
              </Td>
            ) : data && data.length > 0 ? (
              rows.map((row) => {
                prepareRow(row);
                return (
                  <Tr
                    {...row.getRowProps()}
                    style={{ height: '40px' }}
                    key={row.getRowProps().key}
                    cursor="pointer"
                    _hover={{ background: '#f7fafc' }}
                  >
                    {row.cells.map((cell) => (
                      <Td
                        {...cell.getCellProps()}
                        isNumeric={cell.column.isNumeric}
                        key={cell.getCellProps().key}
                        data-testid="tableRow"
                        px={3}
                        border="1px solid #E5E7EB"
                        borderCollapse="collapse"
                      >
                        {renderCell(cell)}
                      </Td>
                    ))}
                  </Tr>
                );
              })
            ) : (
              <Td colSpan={5}>
                <Alert status="info" backgroundColor="gray.400" color="white" data-testid="noDataFoundAlert-OfferAndProcessTable">
                  <AlertIcon color="white" />
                  {t('noDataFound')}
                </Alert>
              </Td>
            )}
          </Tbody>
        </Table>
        {isOpen && <CandidateMessagingViewModal isOpen={isOpen} onClose={onClose} currentCandidateId={currentCandidateId} />}
        {isOpenRequisition && (
          <ViewRequisitionDetail
            isOpen={isOpenRequisition}
            onClose={onRequisitionModalClose}
            requisition={requisition}
            shouldCompare={false}
            dashboard
          />
        )}
      </Box>
    </ScaleFade>
  );
};

export default OfferAndHireProcessTable;
