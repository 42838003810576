import { css } from '@emotion/core';
import colors from '../../../styles/colors';

export const loginViewWrapper = css`
  position: absolute;
  height: 100vh;
  width: 100vw;
  margin: 0;
  background: linear-gradient(270.02deg, ${colors.blue.a50} -6.62%, ${colors.blue.default} 99.45%);
  z-index: 0;
`;

export const loginView = css`
  height: 100%;
  z-index: 2;
`;

export const smbLoginView = css`
  width: 100%;
  height: 100%;
  z-index: 2;
`;

export const alertDialog = css`
  position: absolute;
`;

export const sliderStyle = css`
  .slick-dots {
    position: absolute;
    bottom: 25px;

    li {
      margin: 0;
      button:before {
        font-size: 10px;
        color: #fff;
      }
      &.slick-active {
        button:before {
          font-size: 14px;
        }
      }
    }
  }
  h3 {
    color: #fff;
    font-size: 72px;
    font-weight: 700;
    line-height: 72px;
    text-align: left;

    span {
      color: #ff6600;
    }
  }
`;
