/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  useToast,
} from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import _isNil from 'lodash/isNil';
import { RiCalendar2Line, RiPhoneFill, RiUserVoiceFill, RiVidiconFill } from 'react-icons/ri';
import i18next from 'i18next';
import moment from 'moment';
import { IoMdCalendar } from 'react-icons/io';
import { MdBorderAll, MdLanguage, MdOutlineSchedule } from 'react-icons/md';
import Joi from 'joi';
import { useStoreActions } from '../../../../../models/hooks';
import { useSeekerContainerContext } from '../../SeekerContainerContext';
import { AppointmentType } from '../../../../../firebase/firestore/documents/position';
import {
  AdHocRaioStyle,
  IndustryRadiosStyle,
} from '../../../../administration/positionManagement/createJobs/CreateJobPositionStyle';
import colors from '../../../../../styles/colors';
import { getTranslatedValue } from '../../../../../utils/localizedString';
import { reactDatePickerCSS } from '../../../../calendar/scheduleSidebar/ReactDatePicker.Style';
import { adhocFormCSS, customDatePickerCss, customTimePickerCss } from '../SeekerHeader.styles';
import i18n from '../../../../../locales/i18n';
import {
  clearErrorMessage,
  errorFormat,
  FormattedError,
  getErrorMessage,
  hasErrorMessage,
} from '../../../../../utils/FormErrorUtils';

export type AdHocInterviewModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const interviewTypes = [
  { label: { en: 'In Person', fr: 'Sur place' }, value: 'inPerson' },
  { label: { en: 'Video', fr: 'Vidéo' }, value: 'video' },
  { label: { en: 'Phone', fr: 'Téléphone' }, value: 'phone' },
];

const loadInterviewIcon = (iconType: string) => {
  switch (iconType) {
    case 'inPerson':
      return <RiUserVoiceFill style={{ width: '20px', height: '20px' }} />;
    case 'video':
      return <RiVidiconFill style={{ width: '20px', height: '20px' }} />;
    default:
      return <RiPhoneFill style={{ width: '20px', height: '20px' }} />;
  }
};

export const AdHocInterviewModal = ({ isOpen, onClose }: AdHocInterviewModalProps): JSX.Element => {
  const { t } = useTranslation();
  const toast = useToast();
  const { selectedCandidate } = useSeekerContainerContext();
  const [isLoading, setIsLoading] = useState(false);
  const [collaboratorEmail, setCollaboratorEmail] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [startDate, setStartDate] = useState<Date | null>();
  const [startTime, setStartTime] = useState<Date | null>();
  const [endTime, setEndTime] = useState<Date | null>();
  const [selectedTimeZone, setSelectedTimeZone] = useState<string>('');
  const [appointmentTypes, setAppointmentTypes] = useState<AppointmentType[]>([]);
  const [collaborators, setCollaborators] = useState<string[]>([]);
  const [inValidError, setInValidEmailError] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<FormattedError[]>([]);
  const { saveSchedule } = useStoreActions((actions) => actions.calendar);

  // Custom validation function to ensure startDate is not greater than endDat

  const schema = Joi.object()
    .options({ abortEarly: false })
    .keys({
      title: Joi.string()
        .required()
        .messages({
          'string.required': t('messaging:actions.adHoc.confirmationModal.titleRequired'),
          'string.empty': t('messaging:actions.adHoc.confirmationModal.titleRequired'),
        }),
      appointmentTypes: Joi.array()
        .items(Joi.string())
        .min(1)
        .required()
        .messages({
          'string.required': t('messaging:actions.adHoc.confirmationModal.appointmentTypesRequired'),
          'array.min': t('messaging:actions.adHoc.confirmationModal.appointmentTypesRequired'),
        }),
      timeZone: Joi.string()
        .required()
        .messages({
          'string.required': t('messaging:actions.adHoc.confirmationModal.timeZoneRequired'),
          'string.empty': t('messaging:actions.adHoc.confirmationModal.timeZoneRequired'),
        }),
      startDate: Joi.date()
        .required()
        .messages({
          'any.required': t('messaging:actions.adHoc.confirmationModal.startDateRequired'),
        }),
      startTime: Joi.date()
        .required()
        .messages({
          'any.required': t('messaging:actions.adHoc.confirmationModal.startTimeRequired'),
          'date.base': t('messaging:actions.adHoc.confirmationModal.startTimeRequired'),
        }),
      endTime: Joi.date()
        .required()
        .greater(moment(startTime).toDate())
        .messages({
          'any.required': t('messaging:actions.adHoc.confirmationModal.endTimeRequired'),
          'date.base': t('messaging:actions.adHoc.confirmationModal.endTimeRequired'),
          'date.greater': t('messaging:actions.adHoc.confirmationModal.endTimeGreater'),
        }),
      collaborators: Joi.array().items(
        Joi.string()
          .optional()
          .email({ minDomainSegments: 2, tlds: { allow: ['com', 'net'] } }),
      ),
    });

  // Get all time zones
  const allTimeZones = moment.tz.names();

  // List of US and Canadian time zones
  const usAndCanadaTimeZones = allTimeZones.filter(
    (timeZone) =>
      timeZone.startsWith('America/') &&
      [
        'New_York',
        'Los_Angeles',
        'Chicago',
        'Denver',
        'Toronto',
        'Vancouver',
        'Edmonton',
        'Winnipeg',
        'Montreal',
        'Halifax',
      ].some((city) => timeZone.includes(city)),
  );

  type TimeZoneFormat = {
    label: string;
    value: string;
  };

  const formatTimeZones = (): TimeZoneFormat[] => {
    const formattedTimeZones: TimeZoneFormat[] = [];
    usAndCanadaTimeZones.forEach((timeZone) => {
      const now = moment.tz(timeZone);
      const offset = now.utcOffset(); // Offset in minutes
      const sign = offset >= 0 ? /* istanbul ignore next */ '+' : '-';
      const hours = Math.abs(Math.floor(offset / 60));
      const minutes = Math.abs(offset % 60);
      const offsetFormatted = `${sign}${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
      formattedTimeZones.push({
        label: `${timeZone}: UTC${offsetFormatted}`,
        value: timeZone,
      });
    });
    return formattedTimeZones;
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleStartTimeOfEvent = (fieldName: string, date: Date) => {
    setStartTime(date);
    setEndTime(null);
    setFormErrors(clearErrorMessage(formErrors, fieldName));
  };

  const handleEndTimeOfEvent = (fieldName: string, date: Date) => {
    setEndTime(date);
    setFormErrors(clearErrorMessage(formErrors, fieldName));
  };

  const handleStartDate = (fieldName: string, date: Date) => {
    setStartDate(date);
    setStartTime(null);
    setEndTime(null);
    setFormErrors(clearErrorMessage(formErrors, fieldName));
  };

  const handleCollaboratorEmail = (value: string) => {
    setInValidEmailError(false);
    setCollaboratorEmail(value.trim());
  };

  const handleRemoveCollaborator = (event: any, i: number) => {
    const newList = collaborators.filter((item, index) => index !== i);
    setCollaborators(newList);
  };

  const addCollaborator = () => {
    if (validateEmail(collaboratorEmail)) {
      setCollaborators((prevData) => [...prevData, collaboratorEmail]);
      setCollaboratorEmail('');
    } else {
      setInValidEmailError(true);
    }
  };

  const CustomDatePickerInput = (props: React.HTMLProps<HTMLInputElement>, ref: React.Ref<HTMLInputElement>) => (
    <ButtonGroup
      className={`customDatePicker ${props.value ? '' : 'has-placeholder'}`}
      size="sm"
      isAttached
      variant="outline"
      onClick={props.onClick}
      ref={ref}
      w="100%"
    >
      <Button
        justifyContent="flex-start"
        mr="-px"
        isFullWidth
        height="40px"
        borderColor={`${colors.dividerGray}`}
        bg={`${colors.neutralGray['100']}`}
        aria-label="start-date-picker"
      >
        {props.value ? props.value : ''}
      </Button>
      <IconButton
        aria-label="calendar"
        height="40px"
        borderColor={`${colors.dividerGray}`}
        bg={`${colors.neutralGray['100']}`}
        icon={<IoMdCalendar width={30} height={30} />}
      />
    </ButtonGroup>
  );

  const formValidation = (formData: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { error } = schema.validate(formData);

    if (error) {
      setFormErrors(errorFormat(error.details));
      return false;
    }

    setFormErrors([]);
    return true;
  };

  const addTimeToDate = (sDate: Date, eDate: Date) => {
    const eMoment = moment(eDate);
    return moment(sDate).set('minutes', eMoment.get('minutes')).set('hours', eMoment.get('hours')).set('seconds', 0).toDate();
  };

  const handleSendInvite = async () => {
    const formData = {
      title,
      appointmentTypes,
      timeZone: selectedTimeZone,
      startDate,
      startTime,
      endTime,
      collaborators,
    };

    if (formValidation(formData) && !_isNil(startDate) && !_isNil(startTime) && !_isNil(endTime)) {
      const fromDate = moment.tz(addTimeToDate(startDate, startTime), selectedTimeZone).toDate();
      const toDate = moment.tz(addTimeToDate(startDate, endTime), selectedTimeZone).toDate();
      const formPayLoad = {
        fromDate,
        toDate,
        appointmentTypes,
        bookingCapacity: 1,
        adhoc: true,
        title,
        location: selectedCandidate?.businessCompleteAddress,
        candidateId: selectedCandidate?.id,
        collaborators,
      };
      setIsLoading(true);
      await saveSchedule(formPayLoad)
        .then((data) => {
          // istanbul ignore else
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          if (data.code === 'interview.exists') {
            toast({
              title: t('messaging:actions.adHoc.confirmationModal.interviewExist'),
              status: 'error',
              duration: 3000,
              isClosable: true,
            });
          }
        })
        .finally(() => {
          onClose();
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (selectedCandidate) {
      setTitle(
        `${getTranslatedValue(selectedCandidate?.jobTitle || /* istanbul ignore next */ {}, i18n.language)}@${
          selectedCandidate?.businessCompleteAddress
        }_${selectedCandidate?.firstName}_${selectedCandidate?.lastName}`,
      );
    }
  }, [selectedCandidate]);

  return (
    <Modal id="AdHocInterviewModal" isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('messaging:actions.adHoc.confirmationModal.header')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box className="formWrapper" css={[reactDatePickerCSS, adhocFormCSS]}>
            <FormControl mb={6} isInvalid={hasErrorMessage(formErrors, 'title')} isRequired>
              <FormLabel>{t('messaging:actions.adHoc.confirmationModal.title')}</FormLabel>
              <Input type="text" value={title} data-testid="adhoc-interview-title" onChange={(e) => setTitle(e.target.value)} />
              <FormErrorMessage>{getErrorMessage(formErrors, 'title')}</FormErrorMessage>
            </FormControl>
            <Flex alignItems="center" mb={6}>
              <Box className="iconBox" mr={2}>
                <MdBorderAll />
              </Box>
              <Box className="formBox" w="100%">
                <FormControl isInvalid={hasErrorMessage(formErrors, 'appointmentTypes')} isRequired>
                  <RadioGroup
                    name="interviewType"
                    id="interviewTypeLabel"
                    css={[IndustryRadiosStyle, AdHocRaioStyle]}
                    colorScheme="blue"
                  >
                    <Flex direction="row" wrap="wrap" justify="space-between" width="100%">
                      {interviewTypes.map((interviewType) => {
                        return (
                          <Radio
                            onChange={(e) => {
                              setAppointmentTypes([e.target.value as AppointmentType]);
                              setFormErrors(clearErrorMessage(formErrors, 'appointmentTypes'));
                            }}
                            value={interviewType.value as AppointmentType}
                            key={interviewType.value}
                            mr={2}
                            mt={0}
                            data-testid={`adhoc-interview-${interviewType.value}`}
                          >
                            <Box>{loadInterviewIcon(interviewType.value)}</Box>
                            {getTranslatedValue(interviewType.label, i18next.language)}
                          </Radio>
                        );
                      })}
                    </Flex>
                  </RadioGroup>
                  <FormErrorMessage>{getErrorMessage(formErrors, 'appointmentTypes')}</FormErrorMessage>
                </FormControl>
              </Box>
            </Flex>
            <Flex alignItems="center" mb={6}>
              <Flex alignItems="center" w="50%" mr={4}>
                <Box className="iconBox" mr={2}>
                  <RiCalendar2Line />
                </Box>
                <Box className="formBox" w="100%">
                  <FormControl isInvalid={hasErrorMessage(formErrors, 'startDate')} isRequired>
                    <Box css={customDatePickerCss}>
                      <DatePicker
                        customInput={React.createElement(React.forwardRef(CustomDatePickerInput))}
                        selected={startDate}
                        locale={i18next.language}
                        dateFormat="P"
                        minDate={moment().toDate()}
                        onChange={(date) => handleStartDate('startDate', date as Date)}
                        className="customDatePicker"
                        data-testid="adhoc-interview-start-date"
                      />
                    </Box>
                    <FormErrorMessage>{getErrorMessage(formErrors, 'startDate')}</FormErrorMessage>
                  </FormControl>
                </Box>
              </Flex>
              <Flex alignItems="center" w="50%">
                <Box className="iconBox" mr={2}>
                  <MdLanguage />
                </Box>
                <Box className="formBox" w="100%">
                  <FormControl isInvalid={hasErrorMessage(formErrors, 'timeZone')} isRequired>
                    <Select
                      placeholder="Select option"
                      border={`1px solid ${colors.dividerGray}`}
                      bg={`${colors.neutralGray['100']}`}
                      data-testid="adhoc-interview-timezone"
                      onChange={(e) => {
                        setSelectedTimeZone(e.target.value);
                        setFormErrors(clearErrorMessage(formErrors, 'timeZone'));
                      }}
                    >
                      {formatTimeZones().map((tz) => (
                        <option value={tz.value} key={tz.value}>
                          {tz.label}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>{getErrorMessage(formErrors, 'timeZone')}</FormErrorMessage>
                  </FormControl>
                </Box>
              </Flex>
            </Flex>
            <Flex alignItems="center" mb={6}>
              <Flex alignItems="center" w="50%" mr={4}>
                <Box className="iconBox" mr={2}>
                  <MdOutlineSchedule />
                </Box>
                <Box className="formBox" w="100%" css={customTimePickerCss}>
                  <FormControl isInvalid={hasErrorMessage(formErrors, 'startTime')} isRequired>
                    <Flex
                      className="time-input-group"
                      data-testid="startTime"
                      alignItems="center"
                      border={`1px solid ${colors.dividerGray}`}
                      bg={`${colors.neutralGray['100']}`}
                    >
                      <DatePicker
                        selected={startTime}
                        onChange={(date) => handleStartTimeOfEvent('startTime', date as Date)}
                        minTime={
                          moment(startDate).format('DD-MMM-YYYY') === moment().format('DD-MMM-YYYY')
                            ? moment().toDate()
                            : /* istanbul ignore next */ moment(startDate).startOf('day').toDate()
                        }
                        maxTime={moment(startDate).endOf('day').toDate()}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={10}
                        timeCaption={t('messaging:actions.adHoc.confirmationModal.startTime')}
                        dateFormat="p"
                        placeholderText="H:MM AM/PM"
                      />
                      <MdOutlineSchedule />
                    </Flex>
                    <FormErrorMessage>{getErrorMessage(formErrors, 'startTime')}</FormErrorMessage>
                  </FormControl>
                </Box>
              </Flex>
              <Flex alignItems="center" w="50%">
                <Box className="iconBox" mr={2}>
                  {t('messaging:actions.adHoc.confirmationModal.to')}
                </Box>
                <Box className="formBox" w="100%" css={customTimePickerCss}>
                  <FormControl isInvalid={hasErrorMessage(formErrors, 'endTime')} isRequired>
                    <Flex
                      className="time-input-group"
                      data-testid="endTime"
                      alignItems="center"
                      border={`1px solid ${colors.dividerGray}`}
                      bg={`${colors.neutralGray['100']}`}
                    >
                      <DatePicker
                        selected={endTime}
                        minTime={moment(startTime).add(10, 'minutes').toDate()}
                        openToDate={startTime ? moment(startTime).toDate() : moment().toDate()}
                        maxTime={moment(startDate).endOf('day').toDate()}
                        onChange={(date) => handleEndTimeOfEvent('endTime', date as Date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={10}
                        timeCaption={t('messaging:actions.adHoc.confirmationModal.endTime')}
                        dateFormat="p"
                        placeholderText="H:MM AM/PM"
                      />
                      <MdOutlineSchedule />
                    </Flex>
                    <FormErrorMessage>{getErrorMessage(formErrors, 'endTime')}</FormErrorMessage>
                  </FormControl>
                </Box>
              </Flex>
            </Flex>

            <Box>
              <Accordion defaultIndex={[0]} allowMultiple>
                <AccordionItem py={5} px={0}>
                  <h2>
                    <AccordionButton px={0}>
                      <Box as="span" flex="1" textAlign="left" fontWeight="bold" fontSize="16px">
                        {t('messaging:actions.adHoc.confirmationModal.addCollaborators')}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} px={0}>
                    <Box>
                      <Flex size="sm" mb={3}>
                        <Input
                          type="email"
                          value={collaboratorEmail}
                          onChange={(e) => handleCollaboratorEmail(e.target.value)}
                          placeholder="Enter Email Address"
                          data-testid="adhoc-interview-collaborator-input"
                          borderTopRightRadius={0}
                          borderBottomRightRadius={0}
                          isRequired
                        />
                        <Box>
                          <Button colorScheme="blue" data-testid="collaborator-add-button" onClick={() => addCollaborator()}>
                            {t('messaging:actions.adHoc.confirmationModal.add')}
                          </Button>
                        </Box>
                      </Flex>
                      {inValidError && (
                        <Text fontSize="sm" color="red.500" mb={2}>
                          {t('messaging:actions.adHoc.confirmationModal.invalidEmail')}
                        </Text>
                      )}
                    </Box>
                    <Box>
                      {collaborators.map((collaborator, index) => (
                        <Tag
                          size="sm"
                          borderRadius="5px"
                          variant="solid"
                          color="#333"
                          background="#fff"
                          border="1px solid"
                          borderColor="gray.50"
                          key={`${collaborator}`}
                          marginRight="5px"
                        >
                          <TagLabel>{collaborator}</TagLabel>
                          <TagCloseButton
                            data-testid={`collaborator-${index}`}
                            onClick={(e) => handleRemoveCollaborator(e, index)}
                          />
                        </Tag>
                      ))}
                    </Box>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Box>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            {t('messaging:actions.adHoc.confirmationModal.cancelButton')}
          </Button>
          <Button
            colorScheme="blue"
            data-testid="adhoc-interview-sent-invite-button"
            onClick={() => handleSendInvite()}
            isLoading={isLoading}
          >
            {t('messaging:actions.adHoc.confirmationModal.sendButton')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
