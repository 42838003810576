export default {
  video: 'Video',
  phone: 'Audio',
  inPerson: 'In Person',
  eventForm: {
    successTitle: 'Success !',
    errorTitle: 'Error !',
    errorMessage: 'Please fill all fields before saving',
    saveMessage: 'Event saved successfully',
    updateMessage: 'Event updated successfully',
    groupEventTitle: 'Group Events',
    eventTitle: 'Event Title',
    createEvent: 'Create Event',
    events: 'Events',
    eventDetails: 'Event Details',
    groupEventDetails: 'Group Event Details',
    startVideoInterview: 'Start Video Interview',
    startVideoCall: 'Start Video Call',
    rescheduleEvent: 'Reschedule Event',
    potentialCandidates: 'Potential Candidates',
    sendMessage: 'Send Message',
    date: 'Date',
    timeCaption: 'Time',
    startTime: 'Start Time',
    endTime: 'End Time',
    recruitersLabel: 'Recruiters',
    recruiterHint: 'Please select recruiters',
    emailHint: 'Please enter email',
    titleHint: 'Please enter event title',
    detailHint: 'Please enter detail of event',
    candidatesLabel: 'Candidates',
    description: 'Description',
    eventDescriptionLabel: `Event Description`,
    saveEvent: 'Save Event',
    cancel: 'Cancel',
    close: 'Close',
    interviewLabel: 'Interview',
    interviewerLabel: 'Interviewer',
    nameLabel: 'Name',
    experienceLabel: 'Experience',
    monthLabel: 'Month',
    weekLabel: 'Week',
    dayLabel: 'Day',
    todayLabel: 'Today',
  },
  eventListView: {
    tooltip: `Event can only be modified {allowDeletionMinutes} minutes before start time`,
    expiredEvent: `In the past`,
  },
  availabilityForm: {
    schedule: 'Schedule',
    myPositions: 'My Positions',
    myPositionsToolTip: 'You can view booked interviews per position using this filter',
    myAvailabilityLabel: 'Create Availability',
    appointmentCalendarLabel: 'Interview Calendar',
    appointmentTypeLabel: 'Interview Type',
    appointmentDateLabel: 'Interview Date',
    saveAvailability: 'Save Availability',
    saveMessage: 'Interview saved successfully',
    updateMessage: 'Interview updated successfully',
    bookingCapacity: 'Booking Capacity (In-person only)',
  },
  calendar: {
    totalBooked: 'Total Booked',
    availability: 'Availability',
    meetings: 'Meeting(s)',
    bookingCapacity: 'Booking Capacity:',
    individualInterview: 'Individual Interviews',
    noInterviews: 'No Interviews',
    noGroupEvents: 'No Group Events',
    view: 'View',
    hrs: 'hrs',
  },
  booking: {
    interviewHeader: 'Interview Details',
    cancelEvent: 'Cancel Interview',
    experience: 'More than 10 years',
    location: 'Interview Location',
    accept: 'Accept',
    decline: 'Decline',
    description:
      'Once the Interview is cancelled the Interview will be removed from the calender and slot will be available for another Interview',
    warningDescription: 'The interview you are trying to cancel is with a different recruiter.',
    interviewCancelReasons: 'Interview cancel reasons',
    interviewCancelReasonRequired: 'Interview cancel reason is required',
  },
  interview: {
    location: 'Interview Location',
    rateBtn: 'Rate Interview',
    longDuration: 'More than 10 years',
  },
  otherCalendar: {
    otherCalendarLabel: 'Other Calendars',
    addNewTooltip: 'Add New Calendar',
    selectCalendarToConnect: 'Select calendar to connect',
    deleteConfirmationLabel: 'Are you sure you want to delete this calendar?',
    noData: 'No calendar found.',
    eventDetail: 'Event Details',
    owner: 'Organiser',
    meetBtnLabel: 'Join Event',
    googleBtnLabel: 'Google Calendar',
    outlookBtnLabel: 'Outlook Calendar',
    deleteConfirmationLabelWithEmail: `We are experiencing issues fetching events for {{ emailValue }}. Do you want to remove it from your calendar?`,
  },
  availabilityDetail: {
    drawerTitle: 'Availability Details',
    interviewType: 'Types of Interviews That Can Be Booked',
    bookingCapacity: 'Booking Capacity',
    bookingCapacityHelpText: 'Number of candidates per in-person interview',
    bookedInterviews: 'Booked Interviews',
    noInterviewsMessage: ' There are no interviews booked for this availability period.',
    deleteAvailability: 'Delete Availability',
    confirmationMessageToDeleteAvailabilityWithOutBookings: 'Are you sure you want to delete this availability slot?',
    warningMessageToDeleteAvailabilityWithOutBookings:
      'The availability period you are trying to delete belongs to a different recruiter.',
    waringMessageWhenUserTriesToDeletePassedAvailability: 'Availability starting in the past cannot be deleted.',
    confirmationMessageToDeleteAvailabilityWithBookings:
      'Deleting this availability slot will cancel the interviews booked during that time. Are you sure you want to delete this availability slot?',
  },
  user: {
    googleCalendar: {
      alreadyIntegrated: 'Google calendar already integrated.',
    },
    microsoftTokens: {
      invalidAuthCode: 'Invalid Auth Code',
    },
    microsoftCalendar: {
      alreadyAdded: 'Microsoft calendar already added.',
    },
  },
  collaborators: {
    interviewLink: 'Interview Link',
    inviteCollaborators: 'Invite Co-interviewer',
    link: 'Link',
    linkCopied: 'Invitation link copied ',
    shortCode: 'Verification Code',
    addCollaborators: 'Invite Co-interviewers',
    addCollaboratorsInfo: 'Co-interviewers can join the interview and interact with the candidate.',
    email: 'Email',
    invite: 'Invite',
    copy: 'Copy',
    cancel: 'Cancel',
    linkSend: 'Invitation link sent',
    linkSendError: 'Could not send invitation link!',
    inviteeEmails: 'Co-interviewer(s) Invited',
    userAlreadyExists: 'The invitation link could not be sent.',
    interviewlink: 'Interview Link',
  },
};
