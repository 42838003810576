import React, { useContext, useEffect, useState } from 'react';
import _noop from 'lodash/noop';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Candidate } from '../../../firebase/firestore/documents/candidate';
import { useSelectedEngagedCandidate } from '../MessagingViewContext';
import { useSeekerCandidates } from './query/useSeekerCandidates';
import { MESSAGING_CANDIDATE_TABS, MESSAGING_TABS, MessagingTabsParams } from '../../../routes/constants';
import { useStoreState } from '../../../models/hooks';
import { isCandidateOnDashboard, isCandidatePage } from '../../../utils/candidatesUtils';

export enum SeekerContainerTabItems {
  Profile = 'profile',
  Messaging = 'messaging',
  Questions = 'questions',
  Notes = 'notes',
  History = 'history',
  Info = 'info',
}

export type SeekerContainerTabContextValueType = {
  selectedTab: SeekerContainerTabItems;
  setSelectedTab: (selectedTab: SeekerContainerTabItems) => void;
};

export type SeekerContainerSelectedCandidateContextValueType = {
  selectedCandidate?: Readonly<Candidate>;
  setSelectedCandidate: (candidate: Readonly<Candidate>) => void;
};

export type SeekerContainerDataContextValueType = {
  candidates?: Candidate[];
};

export type SeekerContainerContextValueType = SeekerContainerTabContextValueType &
  SeekerContainerSelectedCandidateContextValueType &
  SeekerContainerDataContextValueType;

export const SEEKER_CONTAINER_CONTEXT_DEFAULT_VALUE: SeekerContainerContextValueType = {
  selectedTab: SeekerContainerTabItems.Messaging,
  setSelectedTab: _noop,
  setSelectedCandidate: _noop,
  candidates: undefined,
  selectedCandidate: undefined,
};

const SeekerContainerContext = React.createContext<SeekerContainerContextValueType>(SEEKER_CONTAINER_CONTEXT_DEFAULT_VALUE);

const mapTabParamsToKey = (tab: string) => {
  switch (tab) {
    case SeekerContainerTabItems.Messaging:
      return SeekerContainerTabItems.Messaging;
    case SeekerContainerTabItems.Questions:
      return SeekerContainerTabItems.Questions;
    case SeekerContainerTabItems.Profile:
      return SeekerContainerTabItems.Profile;
    case SeekerContainerTabItems.Notes:
      return SeekerContainerTabItems.Notes;
    case SeekerContainerTabItems.History:
      return SeekerContainerTabItems.History;
    default:
      return SEEKER_CONTAINER_CONTEXT_DEFAULT_VALUE.selectedTab;
  }
};

export const SeekerContainerContextProvider: React.FC = ({ children }) => {
  const selectedEngagedCandidate = useSelectedEngagedCandidate();
  const { tab } = useParams<MessagingTabsParams>();
  const [selectedTab, setSelectedTab] = useState<SeekerContainerTabItems>(mapTabParamsToKey(tab));
  const [selectedCandidateId, setSelectedCandidateId] = useState<string>();
  const location = useLocation();
  const currentPath = location?.pathname?.split('/')[1];
  useSeekerCandidates(selectedEngagedCandidate?.seeker, currentPath);
  const candidates: Candidate[] = useStoreState((s) => s.candidate.selectedCandidates);

  useEffect(() => {
    setSelectedCandidateId(selectedEngagedCandidate?.id);
  }, [selectedEngagedCandidate, setSelectedCandidateId]);

  const selectTab = (newSelectedTab: SeekerContainerTabItems) => {
    if (!selectedCandidateId) return;
    // istanbul ignore next

    if (isCandidatePage()) {
      window.history.pushState(null, '', MESSAGING_CANDIDATE_TABS(selectedCandidateId, newSelectedTab));
    } else if (isCandidateOnDashboard()) {
      setSelectedTab(newSelectedTab);
      return;
    } else {
      window.history.pushState(null, '', MESSAGING_TABS(selectedCandidateId, newSelectedTab));
    }

    setSelectedTab(newSelectedTab);
  };

  const selectCandidate = (candidate: Candidate) => {
    // istanbul ignore next
    if (isCandidatePage()) {
      window.history.pushState(null, '', MESSAGING_CANDIDATE_TABS(candidate.id, selectedTab));
      setSelectedCandidateId(candidate.id);
    } else if (isCandidateOnDashboard()) {
      setSelectedCandidateId(candidate.id);
    } else {
      window.history.pushState(null, '', MESSAGING_TABS(candidate.id, selectedTab));
      setSelectedCandidateId(candidate.id);
    }
  };
  const selectedCandidate = selectedCandidateId ? candidates?.find(({ id }) => id === selectedCandidateId) : undefined;

  return (
    <SeekerContainerContext.Provider
      value={{
        selectedTab,
        setSelectedTab: selectTab,
        selectedCandidate,
        setSelectedCandidate: selectCandidate,
        candidates,
      }}
    >
      {children}
    </SeekerContainerContext.Provider>
  );
};

export const useSeekerContainerContext = (): SeekerContainerContextValueType => useContext(SeekerContainerContext);
export const useSelectedTab = (): SeekerContainerTabContextValueType => useContext(SeekerContainerContext);
