/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { ChangeEvent } from 'react';
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Textarea,
} from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { IconContext } from 'react-icons/lib';
import { IoIosRemoveCircle } from 'react-icons/io';
import { useStoreActions, useStoreState } from '../../../models/hooks';
import { confirmationModalHeaderCSS } from '../../messaging/seeker/header/actionsDropdown/ConfirmationModal.styles';
import colors from '../../../styles/colors';
import { useCurrentUserProfile } from '../../../app/hooks/useCurrentUserProfile';
import { RemoteConfigKey } from '../../../firebase/remoteConfig';
import { getTranslatedValue } from '../../../utils/localizedString';
import { DISMISSAL_REASONS } from '../../../firebase/firestore/constants';
import useLookupsByType from '../../../app/hooks/useLookupsByType';

export type DismissConfirmationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  positionId: string;
  seekerId: string;
  handleActionAfterDismiss: (seekerId: string) => void;
};

export const DismissConfirmationModal = ({
  isOpen,
  onClose,
  positionId,
  seekerId,
  handleActionAfterDismiss,
}: DismissConfirmationModalProps): JSX.Element => {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);
  const { currentUserProfile } = useCurrentUserProfile();
  const dismissCandidate = useStoreActions((actions) => actions.messaging.dismissCandidate);
  const [noteContent, setNoteContent] = React.useState('');
  const [noteContentTextArea, setNoteContentTextArea] = React.useState('');
  const [showTextArea, setShowTextArea] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(false);
  const isDismissalReasonEnabled = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_DISMISSAL_REASON];
  });

  const dismissalReaons = useLookupsByType(DISMISSAL_REASONS);

  const handleDismissCandidate = async (pId: string, sId: string, recruiterId: string, dismissalReason?: string) => {
    try {
      setIsLoading(true);
      await dismissCandidate({
        positionId: pId,
        seekerId: sId,
        recruiterId,
        dismissalReason,
      });
      setNoteContent('');
      setNoteContentTextArea('');
      handleActionAfterDismiss(seekerId);
      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateAppointmentRequest = async () => {
    if (!currentUserProfile) return;

    if (isDismissalReasonEnabled) {
      if (noteContent === 'Others' && noteContentTextArea === '') {
        setErrorMessage(true);
      } else if (noteContent) {
        setErrorMessage(false);
        await handleDismissCandidate(
          positionId,
          seekerId,
          currentUserProfile.id,
          showTextArea ? noteContentTextArea : noteContent,
        );
      } else {
        setErrorMessage(true);
      }
    } else {
      await handleDismissCandidate(positionId, seekerId, currentUserProfile.id);
    }
  };

  const updateNoteContent = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setNoteContentTextArea(e.target.value);
    setErrorMessage(false);
  };

  const handleClose = () => {
    setNoteContent('');
    setNoteContentTextArea('');
    setErrorMessage(false);
    onClose();
  };

  return (
    <Modal id="DismissConfirmationModal" isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false} size="lg">
      <ModalOverlay>
        <ModalContent>
          <ModalHeader css={confirmationModalHeaderCSS} className="modal-header">
            <Flex direction="row" alignItems="center">
              <Flex alignItems="center" direction="column" justify="center" className="blue-circle-icon">
                <IconContext.Provider value={{ color: colors.white }}>
                  <IoIosRemoveCircle size={14} />
                </IconContext.Provider>
              </Flex>
              <Box width={2} />
              {t('messaging:actions.dismiss.confirmationModal.header')}
            </Flex>
          </ModalHeader>
          <ModalBody>
            {isDismissalReasonEnabled
              ? t('messaging:actions.dismiss.confirmationModal.dismissalReasonEnabledBody')
              : t('messaging:actions.dismiss.confirmationModal.body')}
            {isDismissalReasonEnabled && (
              <Box mt={4}>
                <RadioGroup
                  value={noteContent}
                  onChange={(rsn) => {
                    if (rsn === 'Others') {
                      setNoteContent('Others');
                      setShowTextArea(true);
                    } else {
                      setErrorMessage(false);
                      setShowTextArea(false);
                      setNoteContent(rsn as string);
                      setNoteContentTextArea('');
                    }
                  }}
                >
                  <Stack>
                    {dismissalReaons.map((item) => (
                      <Radio
                        key={item.id}
                        value={getTranslatedValue(item.label, i18n.language)}
                        isDisabled={isLoading}
                        data-testid="dismissalReason"
                      >
                        {getTranslatedValue(item.label, i18n.language)}
                      </Radio>
                    ))}
                    <Radio value="Others" isDisabled={isLoading}>
                      {t('messaging:actions.dismiss.confirmationModal.others')}
                    </Radio>
                  </Stack>
                </RadioGroup>
                {showTextArea && (
                  <Textarea
                    value={noteContentTextArea}
                    data-testid="reasonForDismissal"
                    placeholder={t('messaging:actions.dismiss.confirmationModal.reasonForDismissal')}
                    mt={4}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) => updateNoteContent(e)}
                  />
                )}
              </Box>
            )}
            {errorMessage && (
              <Box fontStyle="italic" mt={3} mb={3} color={colors.red[500]}>
                {t('messaging:actions.dismiss.confirmationModal.errorMsg')}
              </Box>
            )}
          </ModalBody>
          <ModalFooter>
            <Button data-testid="CancelButton" colorScheme="red" mr={3} onClick={handleClose} disabled={isLoading}>
              {t('messaging:actions.dismiss.confirmationModal.cancelButton')}
            </Button>
            <Button
              data-testid="ConfirmButton"
              colorScheme="blue"
              onClick={handleCreateAppointmentRequest}
              disabled={isLoading}
              isLoading={isLoading}
            >
              {t('messaging:actions.dismiss.confirmationModal.sendButton')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};
