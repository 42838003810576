/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Fragment, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Heading,
  Text,
  Input,
  InputGroup,
  InputLeftAddon,
  HStack,
  PinInput,
  PinInputField,
  NumberInput,
  NumberInputField,
  useToast,
  Link,
} from '@chakra-ui/core';
import firebase from 'firebase/app';
import { Trans, useTranslation } from 'react-i18next';
import colors from '../../../../styles/colors';
import { useStoreActions } from '../../../../models/hooks';
import {
  fullWidthBtn,
  loginBox,
  loginButtonStyle,
  loginForm,
  loginFormBox,
  loginFormTopText,
  smbLoginBox,
  smbLoginFormBox,
} from './LoginForm.styles';
import { Label } from '../../../common';
import { useAuthenticationStatus } from '../../../../app/hooks/useAuthenticationStatus';
import { AuthenticationStatus } from '../../../../app/models/appModel';
import { phoneFormat } from '../../../../utils/PhoneUtils';
import { ActiveTabEnum } from '../../userProfileInfoType';

export type LoginFormProps = {
  activeTab: ActiveTabEnum;
  isSMB?: boolean;
};

const LoginForm = ({ activeTab, isSMB }: LoginFormProps): JSX.Element => {
  const { t } = useTranslation('login');
  const toast = useToast();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState('1');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [showConfirmationCodeForm, setShowConfirmationCodeForm] = useState(false);
  const [confirmationCodeError, setConfirmationCodeError] = useState(false);
  const [appVerifier, setAppVerifier] = useState<firebase.auth.RecaptchaVerifier | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pinCodeAttempt, setPinCodeAttempt] = useState(0);
  const [countryCodeError, setCountryCodeError] = useState('');
  const parse = (value: string) => `+${countryCode}${value.replace(/\D/g, '')}`;

  const signInWithPhoneNumber = useStoreActions((actions) => actions.auth.signInWithPhoneNumber);
  const confirmPhoneNumber = useStoreActions((actions) => actions.auth.confirmPhoneNumber);

  const authenticationStatus = useAuthenticationStatus();

  const getCountryShortCode = (code: string) => {
    switch (code) {
      case '1':
        return 'US';
      case '503':
        return 'SV';
      case '977':
        return 'NP';
      default:
        return undefined;
    }
  };

  const handlePhoneNumberInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formattedValue = phoneFormat(event.target.value, getCountryShortCode(countryCode));
    setPhoneNumber(formattedValue);
  };

  const handleCountryCodeInputChange = (value: string) => {
    setCountryCode(value);
    setPhoneNumber('');
    setCountryCodeError('');
  };

  const handleConfirmationCodeInputChange = (codeValue: string) => setConfirmationCode(codeValue);

  const isValidateCountryCode = () => {
    if (process.env.REACT_APP_SUPPORTED_COUNTRY_CODE && process.env.REACT_APP_SUPPORTED_COUNTRY_CODE.includes(countryCode)) {
      return true;
    }
    setCountryCodeError(t('wrongCountryCode'));
    return false;
  };

  const submitPhoneNumber = () => {
    if (!isValidateCountryCode()) {
      return;
    }
    setIsLoading(true);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    signInWithPhoneNumber({
      phoneNumber: parse(phoneNumber),
      appVerifier: appVerifier as firebase.auth.RecaptchaVerifier,
    })
      .then(() => {
        setShowConfirmationCodeForm(true);
      })
      .catch((error: { code: string; message: string }) => {
        toast({
          title: error.code,
          description: error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const resendCodeButton = () => {
    setConfirmationCode('');
    setConfirmationCodeError(false);
    setShowConfirmationCodeForm(false);
    setPinCodeAttempt(0);
  };

  const submitConfirmationCode = async (verificationCode: string) => {
    setIsLoading(true);
    try {
      await confirmPhoneNumber(verificationCode);
    } catch {
      if (pinCodeAttempt <= 3) {
        setConfirmationCodeError(true);
        setPinCodeAttempt(pinCodeAttempt + 1);
      } else {
        resendCodeButton();
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (AuthenticationStatus.INVALID_USER === authenticationStatus) {
      setPhoneNumber('');
      setConfirmationCode('');
      setShowConfirmationCodeForm(false);
    }
  }, [authenticationStatus]);

  useEffect(() => {
    setAppVerifier(
      new firebase.auth.RecaptchaVerifier('recaptcha-container', {
        size: 'invisible',
      }),
    );
  }, []);

  const renderPhoneNumberForm = () => {
    return (
      <Fragment>
        {activeTab === ActiveTabEnum.SIGN_UP && (
          <Box mb={6} css={loginFormTopText}>
            <Heading fontSize="2xl" color="gray.900">
              {t('signUpSendCodeTitle')}
            </Heading>
            <Heading fontSize="lg" color="gray.600" whiteSpace="initial">
              {t('signUpSendCodeSubTitle')}
            </Heading>
            <Label fontSize="md" color="gray.400" whiteSpace="initial" mb={4}>
              {t('sendCodeSignUpSubtitle')}
            </Label>
          </Box>
        )}
        {activeTab === ActiveTabEnum.LOGIN && (
          <Box mb={isSMB ? 2 : 6} css={loginFormTopText}>
            <Heading fontSize="2xl" color="gray.900">
              {t('sendCodeTitle.2')}
            </Heading>
            {!isSMB && (
              <Heading fontSize="lg" color="gray.600" whiteSpace="initial">
                {t('sendCodeTitle.1')}
                {t('sendCodeTitle.3')}
              </Heading>
            )}

            <Label fontSize="md" color="gray.400" whiteSpace="initial" mb={4}>
              {t('sendCodeSubtitle')}
            </Label>
          </Box>
        )}
        <Box mb={8}>
          <Box mb={2} css={isSMB ? smbLoginFormBox : loginFormBox}>
            <InputGroup>
              <InputLeftAddon
                bg="White"
                borderLeft={`1px solid ${colors.gray['100']}`}
                borderTop={`1px solid ${colors.gray['100']}`}
                borderBottom={`1px solid ${colors.gray['100']}`}
                p={0}
                width="75px"
              >
                <NumberInput onChange={handleCountryCodeInputChange} value={countryCode} position="relative" max={999}>
                  <Box as="span" position="absolute" top="7px" left="10px">
                    +
                  </Box>
                  <NumberInputField
                    maxLength={3}
                    paddingLeft="20px"
                    borderTopRightRadius={0}
                    borderBottomRightRadius={0}
                    borderColor={`${colors.gray['100']}`}
                    data-testid="CountryCodeInput"
                  />
                </NumberInput>
              </InputLeftAddon>
              <Input
                className="input-type"
                key="PhoneNumberInput"
                autoFocus
                data-testid="PhoneNumberInput"
                value={phoneNumber}
                onChange={handlePhoneNumberInputChange}
                type="tel"
                maxLength={getCountryShortCode(countryCode) === 'SV' ? 9 : 14}
                placeholder={t('phoneNumberInputPlaceholder')}
                onKeyPress={(event) => {
                  if (event.key === 'Enter' && phoneNumber.length >= 10) {
                    submitPhoneNumber();
                  }
                }}
              />
            </InputGroup>

            {isSMB && (
              <Text>
                <Trans i18nKey="login:termsOfService">
                  By signing up for AppyHere, you are agreeing to our
                  <Link
                    color="blue.500"
                    href="https://www.iubenda.com/privacy-policy/34262405/full-legal"
                    isExternal
                    rel="noopener noreferrer"
                  >
                    terms of service
                  </Link>
                  .
                </Trans>
              </Text>
            )}

            <Box paddingLeft={isSMB ? 0 : 3}>
              <Button
                data-testid="SubmitPhoneNumberButton"
                onClick={submitPhoneNumber}
                css={isSMB ? [loginButtonStyle, fullWidthBtn] : loginButtonStyle}
                disabled={phoneNumber.length < 9 || isLoading}
                isLoading={isLoading}
              >
                {t('sendCodeButton')}
              </Button>
            </Box>
          </Box>
          <Text fontSize="sm" color="red.500">
            {countryCodeError}
          </Text>
        </Box>
        {isSMB ? (
          <Box>
            {t('privacyTerms1')}
            <br />
            {t('privacyTerms2')}
          </Box>
        ) : (
          <Box>
            {activeTab === ActiveTabEnum.LOGIN && (
              <Label whiteSpace="initial" color="gray.600">
                {t('sendCodeFooterLabel.1')}
                <strong>{t('sendCodeFooterLabel.2')}</strong>
                {t('sendCodeFooterLabel.3')}
              </Label>
            )}
            <a href="https://hire.appyhere.com/" target="_blank" rel="noopener noreferrer">
              <Button data-testid="ResendCodeButton" w="auto" size="xs" variant="link" color={colors.blue.default}>
                <i>{t('sendCodeFooterButton')}</i>
              </Button>
            </a>
          </Box>
        )}
      </Fragment>
    );
  };

  const renderConfirmationCodeForm = () => {
    return (
      <Fragment>
        <Box css={loginFormTopText} mb={5}>
          <Label size="lg">{t('loginTitle')}</Label>
          <Label size="base" color={colors.blue.default}>
            {t('loginSubtitle')}
          </Label>
        </Box>

        <Box mb={4}>
          <Box css={isSMB ? smbLoginFormBox : loginFormBox}>
            <HStack data-testid="ConfirmationCodeInput">
              <PinInput
                key="ConfirmationCodeInput"
                autoFocus
                value={confirmationCode}
                onChange={handleConfirmationCodeInputChange}
                onComplete={
                  /* istanbul ignore next */ async (value) => {
                    await submitConfirmationCode(value);
                  }
                }
              >
                <PinInputField type="number" />
                <PinInputField type="number" />
                <PinInputField type="number" />
                <PinInputField type="number" />
                <PinInputField type="number" />
                <PinInputField type="number" />
              </PinInput>
            </HStack>

            <Box paddingLeft={isSMB ? 0 : 3}>
              <Button
                data-testid="LoginButton"
                onClick={() => submitConfirmationCode(confirmationCode)}
                css={isSMB ? [loginButtonStyle, fullWidthBtn] : loginButtonStyle}
                disabled={confirmationCode.length < 6 || isLoading}
                isLoading={isLoading}
                loadingText={activeTab === ActiveTabEnum.LOGIN ? t('login') : t('continue')}
              >
                {activeTab === ActiveTabEnum.LOGIN ? t('login') : t('continue')}
              </Button>
            </Box>
          </Box>

          {confirmationCodeError && (
            <Box paddingTop={3} data-testid="confirmationError">
              <Label color={colors.red['500']} bold size="sm">
                {t('wrongCode')}
              </Label>
            </Box>
          )}
        </Box>

        <Box>
          <Label>
            <i>{t('loginFooterLabel')}</i>
          </Label>
          <Button data-testid="ResendCodeBtn" size="xs" variant="link" onClick={resendCodeButton} color={colors.blue.default}>
            <i>{t('loginFooterButton')}</i>
          </Button>
        </Box>
      </Fragment>
    );
  };

  return (
    <Box data-testid="LoginForm" css={isSMB ? [loginBox, smbLoginBox] : loginBox} rounded="lg">
      <Box css={loginForm} py={6}>
        {!showConfirmationCodeForm ? renderPhoneNumberForm() : renderConfirmationCodeForm()}
      </Box>
      <div id="recaptcha-container" />
    </Box>
  );
};

export default LoginForm;
