/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import { Badge, Box, Button, Flex, List, ListItem, Spinner, Text, useDisclosure, useToast } from '@chakra-ui/core';
import moment from 'moment-timezone';
import _orderBy from 'lodash/orderBy';
import _isNil from 'lodash/isNil';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import colors from '../../../styles/colors';
import { notificationListCSS, tabButtonList } from '../Header.styles';
import { LeftArrow, RightArrow } from './Arrows';
import './hideScrollBar.css';
import useNotifications from './useNotifications';
import { NotificationTypes } from '../../../firebase/firestore/documents/notificationTypes';
import { Notification, NotificationInvitationAction } from '../../../firebase/firestore/documents/notification';
import { useStoreActions, useStoreState } from '../../../models/hooks';
import { NotificationTypeEnum } from './NotificationTypeEnum';
import { GroupInvitationConfirmationModal } from './GroupInvitationConfirmationModal';
import { InterviewView } from './InterviewView';
import { AvailabilityAlert } from './AvailabilityAlert';
import { BackgroundCheckNotificationItem } from './BackgroundCheckNotificationItem';
import useBackgroundChecks from '../../hooks/useBackgroundChecks';
import { RemoteConfigKey } from '../../../firebase/remoteConfig';
import { OthersView } from './OthersView';
import { MESSAGING_TABS } from '../../../routes/constants';
import { SeekerContainerTabItems } from '../../../modules/messaging/seeker/SeekerContainerContext';
import { useMessagingViewContext } from '../../../modules/messaging/MessagingViewContext';
import { Lookups } from '../../../firebase/firestore/documents/lookups';

export type NotificationViewProps = {
  unreadNotificationNumber: (num: number) => void;
  onClose: () => void;
};

export const NotificationView = ({ unreadNotificationNumber, onClose }: NotificationViewProps): JSX.Element => {
  const accountStore = useStoreState((s) => s.app.accounts);
  const [selectedNotification, setSelectedNotification] = React.useState<Notification>();
  const [invitationAction, setInvitationAction] = React.useState<NotificationInvitationAction>();
  const [groupEventEnabled, setGroupEvenEnabled] = useState<boolean | undefined>(false);
  const [activeTab, setActiveTab] = useState<NotificationTypeEnum>(NotificationTypeEnum.INTERVIEWS);
  const notifications = useNotifications(activeTab, 10);

  const currentUser = useStoreState((state) => state.app.user);
  const currentAccount = useStoreState((state) => state.app.accounts);
  const history = useHistory();

  const enableGroupEventNotificationFeature: boolean = useStoreState((state) => {
    return !!state.remoteConfig[RemoteConfigKey.ENABLE_GROUP_EVENT_NOTIFICATION_FEATURE];
  });

  const enableBackgroundCheckNotificationFeature: boolean = useStoreState((state) => {
    return !!state.remoteConfig[RemoteConfigKey.ENABLE_BACKGROUND_CHECK_NOTIFICATION_FEATURE];
  });

  const enableHireFormNotificationFeature: boolean = useStoreState((state) => {
    return !!state.remoteConfig[RemoteConfigKey.ENABLE_HIRE_FORM_NOTIFICATION_FEATURE];
  });

  const { t, i18n } = useTranslation();
  const toast = useToast();
  const { setSelectedEngagedCandidate, setSelectedEngagedSeeker } = useMessagingViewContext();
  const candidatesList = useStoreState((s) => s.candidate.allCandidates);
  const { isOpen: isInvitationModalOpen, onOpen: onInvitationModalOpen, onClose: onInvitationModalClose } = useDisclosure();

  const { notificationAcceptInvitation, notificationDeclineInvitation, readNotification } = useStoreActions(
    (actions) => actions.topBarNotification,
  );

  const readNotificationInvitation = async (notificationId: string) => {
    await readNotification({ notificationId });
  };

  const [activeNotifications, setActiveNotifications] = useState<Notification[]>([]);

  const [showList, setShowList] = useState<string[]>([]);

  const backgroundChecks = useBackgroundChecks();
  const soquijCheck = {
    id: 'request_soquij',
    label: {
      en: 'SOQUIJ',
      fr: 'SOQUIJ',
    },
  } as Lookups;
  const acceptNotificationInvitation = async (groupEventId: string | undefined, notificationId: string) => {
    if (groupEventId) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const response: any = await notificationAcceptInvitation({ groupEventId });
      if (response) {
        toast({
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
          title: response.message,
          status: 'info',
          duration: 1000,
          isClosable: true,
        });
      }
      await readNotificationInvitation(notificationId);
    }
  };

  const declineNotificationInvitation = async (groupEventId: string | undefined, notificationId: string) => {
    /* istanbul ignore else */
    if (groupEventId) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const response = await notificationDeclineInvitation({ groupEventId });
      /* istanbul ignore else */
      if (response) {
        toast({
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
          title: response.message,
          status: 'info',
          duration: 1000,
          isClosable: true,
        });
      }
      await readNotificationInvitation(notificationId);
    }
  };

  const notificationInvitationConfirmation = (notification: Notification, action: NotificationInvitationAction) => {
    setSelectedNotification(notification);
    setInvitationAction(action);
    onInvitationModalOpen();
  };

  const confirmInvitationAction = (notification: Notification | undefined, action: NotificationInvitationAction | undefined) => {
    /* istanbul ignore else */
    if (notification) {
      if (action === NotificationInvitationAction.ACCEPT) {
        acceptNotificationInvitation(notification.groupEventId, notification.id).finally(() => {
          onInvitationModalClose();
        });
      } else {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        declineNotificationInvitation(notification.groupEventId, notification.id).finally(() => {
          onInvitationModalClose();
        });
      }
    }
  };

  useEffect(() => {
    const newNotifications = notifications?.filter((notif) => notif.new).length;

    if (newNotifications) {
      unreadNotificationNumber(newNotifications);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications, unreadNotificationNumber]);

  useEffect(() => {
    setShowList([]);
    if (activeTab === NotificationTypeEnum.INTERVIEWS) {
      setActiveNotifications([]);
    } else if (activeTab === NotificationTypeEnum.OTHERS) {
      const sortedOtherNotifications = _orderBy(notifications, 'sentAt', ['desc']);
      setActiveNotifications(sortedOtherNotifications);
    } else if (activeTab === NotificationTypeEnum.REQUISITION || activeTab === NotificationTypeEnum.AUDIO_LANGUAGE_EVALUATION) {
      const sortedOtherNotifications = _orderBy(notifications, 'sentAt', ['desc']);
      setActiveNotifications(sortedOtherNotifications);
    } else if (activeTab === NotificationTypeEnum.HIRE_FORM || activeTab === NotificationTypeEnum.MODERN_HIRE_FORM) {
      const sortedOtherNotifications = _orderBy(notifications, 'sentAt', ['desc']);
      const filteredNotification = sortedOtherNotifications.filter(
        (notification) =>
          notification.candidate && candidatesList?.engagedCandidates.map((c) => c.id).includes(notification.candidate),
      );
      setActiveNotifications(filteredNotification);
    } else {
      const actNotifications = _orderBy(notifications, 'sentAt', ['desc']).filter((notification) =>
        activeTab === NotificationTypeEnum.GROUP_EVENT
          ? notification.notificationType === NotificationTypes.GROUP_EVENT_INVITATION
          : notification.notificationType === NotificationTypes.BACKGROUND_CHECKED,
      );
      setActiveNotifications(actNotifications);
    }
  }, [candidatesList, notifications, activeTab]);

  useEffect(() => {
    if (accountStore) {
      if (accountStore?.configuration?.groupEventEnabled) {
        setGroupEvenEnabled(accountStore?.configuration?.groupEventEnabled);
      } else {
        setGroupEvenEnabled(false);
      }
    } else {
      setGroupEvenEnabled(false);
    }
  }, [accountStore]);

  const groupEventNotification = (notificationData: Notification) => {
    return (
      <ListItem
        key={notificationData.id}
        d="flex"
        alignItems="start"
        px={5}
        py={4}
        borderBottom="1px solid #EBEBEB"
        onClick={() => readNotificationInvitation(notificationData.id)}
        className={notificationData.new ? '' : 'read-notification'}
      >
        <Box w="100%">
          <Flex alignItems="center" justifyContent="space-between">
            <Text fontSize="sm" fontWeight="700">
              {notificationData.title}
            </Text>
            {moment().diff(notificationData.groupEventDate, 'minutes') >= 0 ? (
              <Badge className="badge">{t('calendar:eventListView.expiredEvent')}</Badge>
            ) : (
              ''
            )}
          </Flex>
          <Text fontSize="sm">{notificationData.message}</Text>
          <Text fontSize="xs" color="#A0AEC0">
            {moment(notificationData.groupEventDate).format('lll')}
          </Text>
          {!_isNil(notificationData.groupEventId) && !(moment().diff(notificationData.groupEventDate, 'minutes') >= 0) && (
            <Flex
              alignItems="center"
              justifyContent="space-between"
              bgColor="#E1F4FD"
              borderWidth="1px"
              borderStyle="dashed"
              borderColor="#049BE5"
              py={3}
              px={4}
              mt={2}
            >
              <Text fontWeight="700" fontSize="sm">
                {t('notification:invitation')}
                {`: `}
                {notificationData.additionalContextData &&
                  (notificationData.additionalContextData?.invitationStatus === NotificationInvitationAction.ACCEPT ? (
                    <Text as="span" color="#5C995E">
                      {t('notification:accepted')}
                    </Text>
                  ) : (
                    <Text as="span" color="#B33636">
                      {t('notification:declined')}
                    </Text>
                  ))}
              </Text>
              <Box>
                <Button
                  colorScheme="blue"
                  size="xs"
                  data-testid={`Accept-${notificationData.id}-btn`}
                  d={
                    notificationData.additionalContextData?.invitationStatus === NotificationInvitationAction.ACCEPT
                      ? 'none'
                      : 'inline-block'
                  }
                  onClick={() =>
                    notificationData.additionalContextData
                      ? notificationInvitationConfirmation(notificationData, NotificationInvitationAction.ACCEPT)
                      : acceptNotificationInvitation(notificationData.groupEventId, notificationData.id)
                  }
                >
                  {t('notification:accept')}
                </Button>
                <Button
                  size="xs"
                  data-testid={`Decline-${notificationData.id}-btn`}
                  ml={3}
                  d={
                    notificationData.additionalContextData?.invitationStatus === NotificationInvitationAction.DECLINE
                      ? 'none'
                      : 'inline-block'
                  }
                  onClick={() =>
                    notificationData.additionalContextData
                      ? notificationInvitationConfirmation(notificationData, NotificationInvitationAction.DECLINE)
                      : declineNotificationInvitation(notificationData.groupEventId, notificationData.id)
                  }
                >
                  {t('notification:decline')}
                </Button>
              </Box>
            </Flex>
          )}
        </Box>
      </ListItem>
    );
  };

  const selectedCallback = async (notificationItem: Notification) => {
    await readNotificationInvitation(notificationItem.id);
    if (notificationItem.candidate) {
      const selectedCandidate = candidatesList?.engagedCandidates.filter((c) => c.id === notificationItem.candidate)[0];
      setSelectedEngagedCandidate(selectedCandidate);
      setSelectedEngagedSeeker(selectedCandidate && { seekerId: selectedCandidate.seeker, candidateId: selectedCandidate.id });
      /* istanbul ignore else */
      if (selectedCandidate) history.push(MESSAGING_TABS(selectedCandidate.id, SeekerContainerTabItems.Messaging));
    }
    onClose();
  };

  return (
    <Box css={tabButtonList} data-testid="NotificationsListContainer">
      <Box borderBottom="1px solid #EBEBEB" overflow="auto" px={1} py={4} fontSize="sm">
        <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
          <Box
            px={3}
            py={1}
            itemID="candidateMenuItem"
            onClick={() => setActiveTab(NotificationTypeEnum.INTERVIEWS)}
            data-testid="notificationViewInterviewBtn"
            className={`tab-button ${activeTab === NotificationTypeEnum.INTERVIEWS ? 'activeTab' : ''}`}
          >
            {t('notification:subTabs.candidates')}
          </Box>
          <Box
            px={3}
            py={1}
            itemID="audioLanguageEvaluationMenuItem"
            data-testid="audioLanguageEvaluationBtn"
            onClick={() => setActiveTab(NotificationTypeEnum.AUDIO_LANGUAGE_EVALUATION)}
            className={`tab-button ${activeTab === NotificationTypeEnum.AUDIO_LANGUAGE_EVALUATION ? 'activeTab' : ''}`}
          >
            {t('notification:subTabs.languageEvaluation')}
          </Box>
          {!!currentAccount?.configuration?.certnEnabled && enableBackgroundCheckNotificationFeature ? (
            <Box
              px={3}
              py={1}
              itemID="backgroundCheckMenuItem"
              data-testid="notificationViewCheckBtn"
              onClick={() => setActiveTab(NotificationTypeEnum.BACKGROUND_CHECK)}
              className={`tab-button ${activeTab === NotificationTypeEnum.BACKGROUND_CHECK ? 'activeTab' : ''}`}
            >
              {t('notification:subTabs.backgroundCheck')}
            </Box>
          ) : (
            <Box />
          )}
          {groupEventEnabled && enableGroupEventNotificationFeature ? (
            <Box
              px={3}
              py={1}
              itemID="groupEventMenuItem"
              data-testid="notificationViewGropBtn"
              onClick={() => setActiveTab(NotificationTypeEnum.GROUP_EVENT)}
              className={`tab-button ${activeTab === NotificationTypeEnum.GROUP_EVENT ? 'activeTab' : ''}`}
            >
              {t('notification:subTabs.groupEvent')}
            </Box>
          ) : (
            <Box />
          )}
          {enableHireFormNotificationFeature && accountStore?.configuration?.hireFormEnabled ? (
            <Box
              px={3}
              py={1}
              itemID="groupEventMenuItem"
              data-testid="notificationViewHireFormBtn"
              onClick={() => setActiveTab(NotificationTypeEnum.HIRE_FORM)}
              className={`tab-button ${activeTab === NotificationTypeEnum.HIRE_FORM ? 'activeTab' : ''}`}
            >
              {t('notification:subTabs.hireForm')}
            </Box>
          ) : (
            <Box />
          )}
          {accountStore?.configuration?.requisitionEnabled ? (
            <Box
              px={3}
              py={1}
              itemID="requisitionIdMenuItem"
              data-testid="requisitionIdBtn"
              onClick={() => setActiveTab(NotificationTypeEnum.REQUISITION)}
              className={`tab-button ${activeTab === NotificationTypeEnum.REQUISITION ? 'activeTab' : ''}`}
            >
              {t('notification:subTabs.requisition')}
            </Box>
          ) : (
            <Box />
          )}
          <Box
            px={3}
            py={1}
            itemID="otherMenuItem"
            data-testid="notificationViewOthersBtn"
            onClick={() => setActiveTab(NotificationTypeEnum.OTHERS)}
            className={`tab-button ${activeTab === NotificationTypeEnum.OTHERS ? 'activeTab' : ''}`}
          >
            {t('notification:subTabs.activities')}
          </Box>
        </ScrollMenu>
      </Box>
      {currentUser?.employerData?.[currentUser.account]?.availabilityWaitingCandidates !== undefined && <AvailabilityAlert />}

      {activeTab === NotificationTypeEnum.INTERVIEWS && (
        <InterviewView onClose={onClose} readNotificationInvitation={readNotificationInvitation} />
      )}
      {activeTab === NotificationTypeEnum.OTHERS && <OthersView notifications={activeNotifications} />}

      {activeTab !== NotificationTypeEnum.INTERVIEWS && activeTab !== NotificationTypeEnum.OTHERS && (
        <Box>
          <List css={notificationListCSS}>
            {_isNil(notifications) ? (
              <Box d="flex" p={8} justifyContent="center">
                <Spinner size="md" color={colors.blue.default} data-testid="notificationSpinner" />
              </Box>
            ) : (
              <Box>
                {activeNotifications.length > 0 &&
                  activeNotifications.map((notification) => {
                    if (notification.notificationType === NotificationTypes.GROUP_EVENT_INVITATION) {
                      return groupEventNotification(notification);
                    }
                    return BackgroundCheckNotificationItem({
                      notification,
                      readNotificationInvitation,
                      backgroundChecks: [...backgroundChecks, soquijCheck],
                      addToShowAll: (id) => setShowList([...showList, id]),
                      showAll: showList.includes(notification.id),
                      getTitle: (name?: string) => t('notification:certn.title', { candidate: name }),
                      selectedCallback,
                      locale: i18n.language,
                    });
                  })}
                {activeNotifications.length <= 0 && (
                  <ListItem key="1" borderBottom="1px solid #EBEBEB" px={5} py={4}>
                    <Box>
                      <Text fontSize="sm" fontWeight="700" data-testid="noNotificationText">
                        {t('notification:noNotification')}
                      </Text>
                    </Box>
                  </ListItem>
                )}
              </Box>
            )}
          </List>
        </Box>
      )}
      <GroupInvitationConfirmationModal
        isOpen={isInvitationModalOpen}
        onClose={onInvitationModalClose}
        notification={selectedNotification}
        invitationAction={invitationAction}
        confirmationAction={confirmInvitationAction}
      />
    </Box>
  );
};
