import { Thunk, thunk } from 'easy-peasy';
import { HttpStoreModel } from '../../../http/models/httpModel';
import { AccountController } from '../../../controllers/accountController';
import HttpClientWithRetry from '../../../http/client/HttpClientWithRetry';
import { FavouriteSeeker } from '../../../firebase/firestore/documents/user';

export type AccountStoreModel = {
  account: AccountModel;
};
export interface AccountModel {
  getFavoriteSeekersList: Thunk<
    AccountModel,
    { accountId: string; userId: string },
    void,
    HttpStoreModel,
    Promise<FavouriteSeeker[]>
  >;
  saveNote: Thunk<AccountModel, { accountId: string; positionId: string; seekerId: string; note: string }, void, HttpStoreModel>;
  updateNote: Thunk<
    AccountModel,
    { accountId: string; positionId: string; seekerId: string; noteIndex: number; note?: string },
    void,
    HttpStoreModel
  >;
  deleteNote: Thunk<
    AccountModel,
    { accountId: string; positionId: string; seekerId: string; noteIndex: number },
    void,
    HttpStoreModel
  >;
  saveOfferMadeNote: Thunk<
    AccountModel,
    {
      positionId: string;
      seekerId: string;
      offerMade: string;
      salary: string;
      salaryUnit: string;
      customSalaryUnit?: string;
      sendMessageToCandidates?: boolean;
      requisitionId?: string;
      updatedPositionId?: string;
      updatedBusinessId?: string;
      autoDismiss?: boolean;
    },
    void,
    HttpStoreModel
  >;
  saveOfferRejectedNote: Thunk<
    AccountModel,
    { positionId: string; seekerId: string; offerRejectedDetails: string | null },
    void,
    HttpStoreModel
  >;
  addSeekerToFavorite: Thunk<AccountModel, { accountId: string; userId: string; seekerId: string }, void, HttpStoreModel>;
  removeSeekerFromFavorite: Thunk<AccountModel, { accountId: string; userId: string; seekerId: string }, void, HttpStoreModel>;
  updateHireWebHookUrlInAccount: Thunk<AccountModel, { accountId: string; link: string }, void, HttpStoreModel>;
  getAllFollowedPositionOfAccount: Thunk<AccountModel, { accountId: string }, void, HttpStoreModel>;
}

export const accountStoreModel: AccountModel = {
  getFavoriteSeekersList: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new AccountController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.getFavoriteSeekersList(payload.accountId, payload.userId);
    return response?.data as FavouriteSeeker[];
  }),

  saveNote: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new AccountController(getStoreState().http.client as HttpClientWithRetry);
    await controller.saveNote(payload.accountId, payload.positionId, payload.seekerId, payload.note);
  }),

  updateNote: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new AccountController(getStoreState().http.client as HttpClientWithRetry);
    await controller.updateNote(payload.accountId, payload.positionId, payload.seekerId, payload.noteIndex, payload.note);
  }),

  deleteNote: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new AccountController(getStoreState().http.client as HttpClientWithRetry);
    await controller.deleteNote(payload.accountId, payload.positionId, payload.seekerId, payload.noteIndex);
  }),

  addSeekerToFavorite: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new AccountController(getStoreState().http.client as HttpClientWithRetry);
    await controller.addSeekerToFavorite(payload.accountId, payload.userId, payload.seekerId);
  }),

  removeSeekerFromFavorite: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new AccountController(getStoreState().http.client as HttpClientWithRetry);
    await controller.removeSeekerFromFavorite(payload.accountId, payload.userId, payload.seekerId);
  }),

  saveOfferMadeNote: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new AccountController(getStoreState().http.client as HttpClientWithRetry);
    await controller.saveOfferMadeNote(
      payload.positionId,
      payload.seekerId,
      payload.offerMade,
      payload.salary,
      payload.salaryUnit,
      payload.customSalaryUnit,
      payload.sendMessageToCandidates,
      payload.requisitionId,
      payload.updatedPositionId,
      payload.updatedBusinessId,
      payload.autoDismiss,
    );
  }),

  saveOfferRejectedNote: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new AccountController(getStoreState().http.client as HttpClientWithRetry);
    await controller.saveOfferRejectedNote(payload.positionId, payload.seekerId, payload.offerRejectedDetails);
  }),
  updateHireWebHookUrlInAccount: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new AccountController(getStoreState().http.client as HttpClientWithRetry);
    await controller.updateHireWebHookUrlInAccount(payload.accountId, payload.link);
  }),

  getAllFollowedPositionOfAccount: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new AccountController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.getAllFollowedPositionOfAccount(payload.accountId);
    return response;
  }),
};
