import { RequisitionResponseType } from '../../../modules/dashboard/requisitionOverview/requisitionByLocation/RequisitionByLocationType';
import { RequisitionInsightsTableDataType } from '../../../modules/dashboard/requisitionOverview/requisitionInsightsTable/RequisitionInsightsTableType';

export interface Requisition {
  requisitionId: string;
  locationId: string;
  jobId: string;
  seasonal?: boolean;
  guaranteedHours?: number;
  wage: RequisitionSalary;
  status?: RequisitionStatus;
  effectiveDate?: Date;
  fullTime?: boolean;
  partTimeHours?: number;
  maxHours?: number;
  minHours?: number;
  endDate?: Date;
  shifts?: Shifts;
  note?: string;
  positionId?: string;
}

export enum RequisitionStatus {
  OPEN = 'OPEN',
  CANCELLED = 'CANCELLED',
  FILLED = 'FILLED',
  PROCESSING = 'PROCESSING',
}
export enum SalaryUnits {
  HOURLY = 'HOURLY',
  ANNUAL = 'ANNUAL',
  CUSTOM = 'CUSTOM',
}

export interface ShiftDetail {
  start_1: string;
  end_1: string;
  isOvernightShift?: boolean;
}

export interface Shifts {
  [day: string]: ShiftDetail;
}

export interface RequisitionSalary {
  unit: SalaryUnits.HOURLY | SalaryUnits.ANNUAL | SalaryUnits.CUSTOM | string;
  customUnit?: string;
  value: number;
  maxValue?: number;
  visible?: boolean;
}

export interface RiskRequisitionCount {
  unfilled: number;
  atRisk: number;
  overDue: number;
  positionImpacted: number;
  locationImpacted: number;
}

export const RiskRequisiitonCountDefault: RiskRequisitionCount = {
  unfilled: 0,
  atRisk: 0,
  overDue: 0,
  locationImpacted: 0,
  positionImpacted: 0,
};

export interface RiskRequisitionDetail {
  data: {
    requisitionsDetail: RequisitionInsightsTableDataType[];
    requisitionsBusinessDetail: RequisitionResponseType;
    requisitionsOverallCount: RiskRequisitionCount;
  };
}
