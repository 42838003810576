import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/core';
import { Column } from 'react-table';
import { useTranslation } from 'react-i18next';
import colors from '../../../styles/colors';
import RequisitionByLocation from './requisitionByLocation/RequisitionByLocation';
import {
  RequisitionFilterTabNames,
  RequisitionInsightsTableDataType,
  RequisitionStatusEnum,
} from './requisitionInsightsTable/RequisitionInsightsTableType';
import RequisitionInsightsTable from './requisitionInsightsTable/RequisitionInsightsTable';
import { TablePaginationFooter } from '../../../app/components/TablePaginationFooter';
import { RequisitionCountViewTopBar } from './requisitionCounts/RequisitionCounts';
import { useStoreActions, useStoreState } from '../../../models/hooks';
import { RequisitionResponseType } from './requisitionByLocation/RequisitionByLocationType';
import { RiskRequisiitonCountDefault, RiskRequisitionCount } from '../../../firebase/firestore/documents/requisition';

export const RequisitionOverviewView = () => {
  const { t } = useTranslation('requisition');
  const [currentPage, setCurrentPage] = useState(1);
  const [requisitionInsightsTableData, setRequisitionInsightsTableData] = useState<RequisitionInsightsTableDataType[]>([]);

  const [requisitionsBusinessDetail, setRequisitionsBusinessDetail] = useState<RequisitionResponseType>([]);

  const [requisitionsOverallCount, setRequisitionsOverallCount] = useState<RiskRequisitionCount>(RiskRequisiitonCountDefault);

  const [currentFilteredItems, setCurrentFilteredItems] = useState<RequisitionInsightsTableDataType[]>([]);

  const [isRiskRequisitionDetailLoading, setIsRiskRequisitionDetailLoading] = useState(false);

  const { getRiskRequisitionDetail } = useStoreActions((actions) => actions.requisition);

  const [currentActiveTabName, setCurrentActiveTabName] = useState(RequisitionFilterTabNames.UNFILLED);

  const accountId = useStoreState((state) => state.app.accounts?.id);

  const headerInfoFromTranslation = {
    requisitionId: t('riskRequisitionDetailTable.header.requisitionId'),
    createdUpdatedDate: t('riskRequisitionDetailTable.header.createdUpdated'),
    status: t('riskRequisitionDetailTable.header.status'),
    hireBy: t('riskRequisitionDetailTable.header.hireBy'),
    locationAndPosition: t('riskRequisitionDetailTable.header.locationPosition'),
  };

  const requisitionInsightsTableColumns: Column<RequisitionInsightsTableDataType>[] = [
    {
      Header: headerInfoFromTranslation.requisitionId,
      accessor: 'requisitionId',
    },
    {
      Header: headerInfoFromTranslation.status,
      accessor: 'riskStatus',
    },

    {
      Header: headerInfoFromTranslation.createdUpdatedDate,
      accessor: 'createdAt',
    },

    {
      Header: headerInfoFromTranslation.hireBy,
      accessor: 'effectiveDate',
    },

    {
      Header: headerInfoFromTranslation.locationAndPosition,
      accessor: 'businessName',
    },
  ];

  const onGetRiskRequisitionDetail = useCallback(
    async (account: string) => {
      setIsRiskRequisitionDetailLoading(true);
      const response = await getRiskRequisitionDetail({ accountId: account });
      setIsRiskRequisitionDetailLoading(false);
      setRequisitionInsightsTableData(response?.data.requisitionsDetail);
      setRequisitionsBusinessDetail(response?.data.requisitionsBusinessDetail);
      setRequisitionsOverallCount(response?.data?.requisitionsOverallCount);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // istanbul ignore next
  const getFilteredRequisitionData = (requisitionStatus: RequisitionFilterTabNames, data: RequisitionInsightsTableDataType[]) => {
    return data.reduce((acc: RequisitionInsightsTableDataType[], eachData) => {
      if (
        requisitionStatus === RequisitionFilterTabNames.AT_RISK &&
        eachData.riskStatus.includes(RequisitionStatusEnum.AT_RISK)
      ) {
        acc.push({ ...eachData, riskStatus: [RequisitionStatusEnum.AT_RISK] });
      } else if (
        requisitionStatus === RequisitionFilterTabNames.OVERDUE &&
        eachData.riskStatus.includes(RequisitionStatusEnum.OVERDUE)
      ) {
        acc.push({ ...eachData, riskStatus: [RequisitionStatusEnum.OVERDUE] });
      } else if (requisitionStatus === RequisitionFilterTabNames.UNFILLED) {
        acc.push({ ...eachData });
      }
      return acc;
    }, []);
  };

  useEffect(() => {
    /* istanbul ignore else */
    if (accountId) {
      /* eslint-disable @typescript-eslint/no-floating-promises */
      onGetRiskRequisitionDetail(accountId);
    }
  }, [onGetRiskRequisitionDetail, accountId]);

  const filteredMemoedTableData = useMemo(() => {
    return getFilteredRequisitionData(currentActiveTabName, requisitionInsightsTableData);
  }, [currentActiveTabName, requisitionInsightsTableData]);

  return (
    <>
      <Text p={4} fontWeight="bold" fontSize={16} color={colors.gray[800]} mb={4}>
        {t('requisitionRiskDashboard')}
      </Text>
      <Flex direction="column">
        <Box p={4} pt={0} w="100%" className="countBar">
          <Flex flexWrap="wrap" width="100%" background={colors.white} borderRadius="md" p={5}>
            <RequisitionCountViewTopBar
              requisitionsOverallCount={requisitionsOverallCount}
              isCountLoading={isRiskRequisitionDetailLoading}
              sendCurrentRequisitionToParent={setCurrentActiveTabName}
            />
          </Flex>
        </Box>
        <Flex p={4} pt={0}>
          <Box width="67%" background={colors.white} marginRight={4} borderRadius="md">
            <Box background={colors.white} px={4} py={6} borderTopRadius={6}>
              <Text fontSize={16} fontWeight="bold">
                {t('requisitionInsights')}
              </Text>
            </Box>

            <RequisitionInsightsTable
              isLoading={isRiskRequisitionDetailLoading}
              columns={requisitionInsightsTableColumns}
              data={currentFilteredItems}
            />
            <TablePaginationFooter
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              finalListOfItems={filteredMemoedTableData}
              setCurrentItemsList={setCurrentFilteredItems}
              showingResultsTranslation={(variable: string) => t('requisition:pagination.showingResult', variable)}
            />
          </Box>
          <Box width="33%">
            <RequisitionByLocation
              requisitionsBusinessDetail={requisitionsBusinessDetail}
              isRiskRequisitionBusinessDetailLoading={isRiskRequisitionDetailLoading}
            />
          </Box>
        </Flex>
      </Flex>
    </>
  );
};
