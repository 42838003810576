// istanbul ignore file
/** @jsx jsx */
import { jsx } from 'theme-ui';

import { Box } from '@chakra-ui/core';

export default function SmbPositionManagementView() {
  return (
    <Box>
      <h1>SMB Position Management view</h1>
    </Box>
  );
}
