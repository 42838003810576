import moment from 'moment-timezone';
import { Shifts } from '../firebase/firestore/documents/requisition';

export const isRequisitionAvailable = (
  effectiveDate: Date | undefined,
  requisitionActiveThresholdDaysAccount?: number,
  requisitionActiveThresholdDaysPosition?: number,
) => {
  if (!effectiveDate) {
    return true;
  }

  const parsedDate = moment(effectiveDate);
  const today = moment();

  if (requisitionActiveThresholdDaysPosition !== undefined) {
    return parsedDate.isSameOrBefore(today.add(requisitionActiveThresholdDaysPosition, 'days'), 'day');
  }

  if (requisitionActiveThresholdDaysAccount !== undefined) {
    return parsedDate.isSameOrBefore(today.add(requisitionActiveThresholdDaysAccount, 'days'), 'day');
  }

  return true; // Default to true if no thresholds are specified
};
export const compareTimes = (time1: string, time2: string): number => {
  const [hour1, minute1] = time1.split(':').map(Number);
  const [hour2, minute2] = time2.split(':').map(Number);
  return hour1 + minute1 / 60 - (hour2 + minute2 / 60);
};

// eslint-disable-next-line consistent-return
export const getShiftIntersectionWithCandidate = (requisitionShift?: Shifts, candidateShift?: Shifts, nightShift?: Shifts) => {
  if (requisitionShift && Object.keys(requisitionShift).length && (candidateShift || nightShift)) {
    const intersectingShift: Shifts = {};
    let start: string;
    let end: string;

    // eslint-disable-next-line no-restricted-syntax
    for (const day in requisitionShift) {
      /* istanbul ignore next */
      // eslint-disable-next-line no-continue
      if (!requisitionShift[day]) continue;

      // if the requisitionShift spans past midnight
      const isRequisitionNightShift = compareTimes(requisitionShift[day].start_1, requisitionShift[day].end_1) > 0;
      const compareShift = isRequisitionNightShift ? nightShift : candidateShift;

      if (compareShift && compareShift[day]) {
        start =
          compareTimes(requisitionShift[day].start_1, compareShift[day].start_1) > 0
            ? requisitionShift[day].start_1
            : compareShift[day].start_1;
        end =
          compareTimes(requisitionShift[day].end_1, compareShift[day].end_1) > 0
            ? compareShift[day].end_1
            : requisitionShift[day].end_1;
        // for handling midnight span
        /* istanbul ignore next */
        if (compareTimes(start, end) >= 0) {
          if (compareTimes(compareShift[day].start_1, compareShift[day].end_1) > 0) {
            start = isRequisitionNightShift ? compareShift[day].start_1 : requisitionShift[day].start_1;
            end = isRequisitionNightShift ? requisitionShift[day].end_1 : compareShift[day].end_1;
          } else if (isRequisitionNightShift) {
            start = requisitionShift[day].start_1;
            end = compareShift[day].end_1;
          } else {
            // There is no intersection between two times
            // eslint-disable-next-line no-continue
            continue;
          }
        }
        intersectingShift[day] = { start_1: start, end_1: end };
      }
    }
    return intersectingShift;
  }
};
