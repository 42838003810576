export enum HistoryTypes {
  BACKGROUND_CHECK = 'BACKGROUND_CHECK',
  ALL_HISTORY = 'ALL_HISTORY',
  HIRE_FORM = 'HIRE_FORM',
  MODERN_HIRE = 'MODERN_HIRE',
  APPOINTMENT = 'APPOINTMENT',
  OFFER = 'OFFER',
  CANDIDATE = 'CANDIDATE',
  AUDIO_LANGUAGE_EVALUATION = 'AUDIO_LANGUAGE_EVALUATION',
  NOT_LOOKING_FOR_WORK = 'NOT_LOOKING_FOR_WORK',
  DEACTIVATED = 'DEACTIVATED',
}

export enum HistoryViewTypes {
  COMPACT = 'COMPACT',
  TIMELINE = 'TIMELINE',
}
