export default {
  myProfile: 'My Profile',
  editProfile: 'Edit your profile',
  personalInformation: 'Information',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email address',
  update: 'Update',
  firstNameRequired: 'First Name is required',
  lastNameRequired: 'Last Name is required',
  emailNameRequired: 'Email is required',
  emailFormatIncorrect: 'Email must be a valid format',
  profileUpdatedSuccessfully: 'Profile Updated Successfully',
  profileUpdatedFailed: 'Something went wrong! Could not update profile.',
  profilePictureUpdated: `Profile picture updated successfully.`,
  profilePictureUpdateFail: 'Your profile picture could not be updated.',
  profileImageFormatFail: 'Your profile picture must be in PNG or JPEG format.',
  profileImageSizeFail: 'Your profile picture must be less than 1 MB.',
  appliedPositions: 'Positions Candidate Has Applied To',
  firstNameMin: 'First name must be at least 2 characters',
  firstNameMax: 'First name should not exceed 30 characters',
  lastNameMin: 'Last name must be at least 2 characters',
  lastNameMax: 'Last name should not exceed 30 characters',
  preferences: 'Preferences',
  filterCandidatesByDays: 'Filter candidates by days',
  days: `days`,
  maxLimitErrorMessage: `Maximum limit reached`,
  approxCandidateTooltipMsg: `Approximate Candidate Count`,
};
