import _deburr from 'lodash/deburr';
import _map from 'lodash/map';
import _groupBy from 'lodash/groupBy';
import _orderBy from 'lodash/orderBy';
import _find from 'lodash/find';
import _head from 'lodash/head';
import _sortBy from 'lodash/sortBy';
import _max from 'lodash/max';
import moment from 'moment-timezone';
import escapeStringRegexp from 'escape-string-regexp';
import i18next from 'i18next';
import { CandidateCategory, CandidateStatus, EngagedCandidate } from '../../../../firebase/firestore/documents/candidate';
import { orderCandidatesByLastSeekerMessageAt } from '../../../../services/CandidateService';
import { CandidateItemNode, NodeType, ParentItemNode, TreeGroup } from './treeGroup';
import SeekerListSortType from '../filters/seekerListSortType';
import SeekerListGroupType from '../filters/seekerListGroupType';
import { getTranslatedValue } from '../../../../utils/localizedString';

const orderedCandidates = (candidates: Readonly<EngagedCandidate>[]): Readonly<EngagedCandidate>[] =>
  orderCandidatesByLastSeekerMessageAt(candidates);

export type SelectedSeekerEngagedCandidate = { seekerId: string; candidateId: string };

export class SeekersPresenter {
  private readonly candidateListItems = orderedCandidates(this.engagedCandidates);

  constructor(
    public readonly engagedCandidates: Readonly<EngagedCandidate>[],
    public readonly currentUserId: string,
    private readonly minutesBeforeInactivity: number,
    public readonly dismissedCandidate?: Readonly<EngagedCandidate>[],
  ) {}

  getSeekersList(
    locale: string,
    sortType: SeekerListSortType = SeekerListSortType.CHRONOLOGICAL,
    searchQuery = '',
    groupType: SeekerListGroupType = SeekerListGroupType.NONE,
    offerRejectFilter = '',
  ): TreeGroup[] {
    const items = SeekersPresenter.activeSeekers(this.candidateListItems);

    const unarchivedSeekers = SeekersPresenter.unarchivedSeekers(items, offerRejectFilter);
    const filteredItems = SeekersPresenter.filter(unarchivedSeekers, searchQuery);
    return SeekersPresenter.groupAndSort(
      filteredItems,
      groupType,
      sortType,
      this.currentUserId,
      this.minutesBeforeInactivity,
      locale,
    );
  }

  getGhostedSeekersList(
    locale: string,
    sortType: SeekerListSortType = SeekerListSortType.CHRONOLOGICAL,
    searchQuery = '',
    groupType: SeekerListGroupType = SeekerListGroupType.NONE,
  ): TreeGroup[] {
    const items = SeekersPresenter.ghostedSeekers(this.candidateListItems);
    const filteredItems = SeekersPresenter.filter(items, searchQuery);
    return SeekersPresenter.groupAndSort(
      filteredItems,
      groupType,
      sortType,
      this.currentUserId,
      this.minutesBeforeInactivity,
      locale,
    );
  }

  // getAgedSeekersList(
  //   locale: string,
  //   sortType: SeekerListSortType = SeekerListSortType.CHRONOLOGICAL,
  //   searchQuery = '',
  //   groupType: SeekerListGroupType = SeekerListGroupType.NONE,
  //   agedTimePeriod = 2,
  // ): TreeGroup[] {
  //   const items = SeekersPresenter.agedSeekers(this.candidateListItems, agedTimePeriod);
  //   const filteredItems = SeekersPresenter.filter(items, searchQuery);
  //   return SeekersPresenter.groupAndSort(
  //     filteredItems,
  //     groupType,
  //     sortType,
  //     this.currentUserId,
  //     this.minutesBeforeInactivity,
  //     locale,
  //   );
  // }

  getArchivedSeekersList(
    locale: string,
    sortType: SeekerListSortType = SeekerListSortType.CHRONOLOGICAL,
    searchQuery = '',
    groupType: SeekerListGroupType = SeekerListGroupType.NONE,
  ): TreeGroup[] {
    const items = SeekersPresenter.archivedSeekers(this.candidateListItems);
    const filteredItems = SeekersPresenter.filter(items, searchQuery);
    return SeekersPresenter.groupAndSort(
      filteredItems,
      groupType,
      sortType,
      this.currentUserId,
      this.minutesBeforeInactivity,
      locale,
    );
  }

  getDismissedSeekersList(
    locale: string,
    sortType: SeekerListSortType = SeekerListSortType.CHRONOLOGICAL,
    searchQuery = '',
    groupType: SeekerListGroupType = SeekerListGroupType.NONE,
  ): TreeGroup[] {
    const items = this.dismissedCandidate;
    const filteredItems = SeekersPresenter.filter(items || [], searchQuery);
    return SeekersPresenter.groupAndSort(
      filteredItems,
      groupType,
      sortType,
      this.currentUserId,
      this.minutesBeforeInactivity,
      locale,
    );
  }

  private static activeSeekers = (seekers: EngagedCandidate[]): EngagedCandidate[] =>
    seekers.filter((seeker: EngagedCandidate) => seeker.ghostingStatus?.status !== 'GHOSTED');

  /**
   * filter all seeker without category "OTHER" but if offerRejectedFilter is passed it ignore category OTHER
   * @param seekers
   * @param offerRejectedFilter
   */
  private static unarchivedSeekers = (seekers: EngagedCandidate[], offerRejectedFilter: string): EngagedCandidate[] => {
    if (offerRejectedFilter) {
      return seekers;
    }
    return seekers.filter((seeker: EngagedCandidate) => seeker.computedStatus?.category !== CandidateCategory.Other);
  };

  private static archivedSeekers = (seekers: EngagedCandidate[]): EngagedCandidate[] => {
    return seekers.filter(
      (seeker: EngagedCandidate) =>
        seeker.computedStatus?.category === CandidateCategory.Other && seeker.computedStatus.status !== CandidateStatus.Dismissed,
    );
  };

  private static ghostedSeekers = (seekers: EngagedCandidate[]): EngagedCandidate[] =>
    seekers.filter((seeker: EngagedCandidate) => {
      return seeker.ghostingStatus?.status === 'GHOSTED';
    });

  // private static agedSeekers = (seekers: EngagedCandidate[], agedTimePeriod: number): EngagedCandidate[] =>
  //   seekers.filter((seeker: EngagedCandidate) => {
  //     const date1 = moment();
  //     const date2 = moment(seeker.ghostingStatus?.lastUpdatedDate);
  //     return seeker.ghostingStatus?.status === 'GHOSTED' && date1.diff(date2, 'day') >= agedTimePeriod;
  //   });

  private static filter = (seekers: EngagedCandidate[], filterQuery: string): EngagedCandidate[] => {
    const words = filterQuery.split(/\s+/);
    return seekers.filter((seeker: EngagedCandidate) =>
      words.every((word) =>
        // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
        _deburr(`${seeker.firstName} ${seeker.lastName}`).match(new RegExp(escapeStringRegexp(_deburr(word)), 'i')),
      ),
    );
  };

  private static groupAndSort = (
    seekers: EngagedCandidate[],
    groupType: SeekerListGroupType,
    sortType: SeekerListSortType,
    currentUserId: string,
    minutesBeforeInactivity: number,
    locale: string,
  ): TreeGroup[] => {
    const positionsUnreadCount = (candidates: EngagedCandidate[], currentUserProfileId: string): number => {
      return candidates.filter((candidate: EngagedCandidate) => {
        return candidate.accountNotification?.status?.[currentUserProfileId]?.read === false;
      }).length;
    };

    const getMaxFromList = (list: Array<Date | undefined>) => {
      const latestDate = list.filter((d) => d !== undefined).map((d) => moment(d));
      return moment.max(latestDate);
    };

    const getLatestDate = (seeker: EngagedCandidate) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (seeker.lastMessageAt?.fromSeeker || seeker?.forSorting) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        return getMaxFromList([seeker.lastMessageAt?.fromSeeker, seeker?.forSorting]);
      }
      return moment().subtract(1, 'year').toDate();
    };

    const getFullName = (fName: string, lName: string) => {
      return `${fName.trim()} ${lName.trim()}`;
    };

    const sortEngagedCandidates = (seekerList: EngagedCandidate[]): EngagedCandidate[] => {
      return _orderBy(
        seekerList,
        sortType === SeekerListSortType.ALPHABETICAL
          ? (item) => getFullName(item.firstName.toLowerCase(), item.lastName.toLowerCase())
          : (item) => getLatestDate(item),
        sortType === SeekerListSortType.ALPHABETICAL ? ['asc'] : ['desc'],
      );
    };

    const sortedCandidateMapped = (candidateMapped: TreeGroup[]) => {
      return _sortBy(candidateMapped, ['candidateName']);
      // if (sortType === SeekerListSortType.ALPHABETICAL) {
      //   return _sortBy(candidateMapped, ['candidateName']);
      // }
      // return _sortBy(candidateMapped, ['lastActivity']);
    };

    const sortedPositionMapped = (positionMapped: TreeGroup[]) => {
      return _sortBy(positionMapped, (item) => getTranslatedValue(item.item.title, i18next.language).toLowerCase());
      // if (sortType === SeekerListSortType.ALPHABETICAL) {
      //   return _sortBy(positionMapped, (item) => getTranslatedValue(item.item.title, i18next.language).toLowerCase());
      // }
      // return _orderBy(positionMapped, (item) => item.item.lastActivity, ['desc']);
    };

    const sortedPositionMappedNotLocalized = (positionMapped: TreeGroup[]) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
      return _sortBy(positionMapped, (item) => item.item.title.toLowerCase());
      // if (sortType === SeekerListSortType.ALPHABETICAL) {
      //   // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
      //   return _sortBy(positionMapped, (item) => item.item.title.toLowerCase());
      // }
      // return _orderBy(positionMapped, (item) => item.item.lastActivity, ['desc']);
    };

    const sortedBusinessLocationMapped = (businessLocationMapped: TreeGroup[]) => {
      return _sortBy(businessLocationMapped, (item) => (item.item.title as string).toLowerCase());
      // if (sortType === SeekerListSortType.ALPHABETICAL) {
      //   return _sortBy(businessLocationMapped, (item) => (item.item.title as string).toLowerCase());
      // }
      // return _orderBy(businessLocationMapped, (item) => item.item.lastActivity, ['desc']);
    };

    const getLatestDateFromMappedCandidate = (candidateMapped: TreeGroup[]) => {
      return _max(candidateMapped.map((candidateItem) => candidateItem.item.lastActivity));
    };

    const groupByBusinessLocation = (seekerList: EngagedCandidate[]): TreeGroup[] => {
      const businessGrouped = _groupBy(seekerList, 'businessName');

      const businessLocationMapped = _map(businessGrouped, (businessSeekers, bKey) => {
        const business = businessGrouped[bKey][0];

        const positionGrouped = _groupBy(businessSeekers, 'position');

        const positionMapped = _map(positionGrouped, (positionSeekers, pKey) => {
          const position = positionGrouped[pKey][0];
          const sortedPositionSeekers = sortEngagedCandidates(positionSeekers);

          const groupedSeekers = _groupBy(sortedPositionSeekers, 'seeker');
          const candidateMapped = _map(groupedSeekers, (groupedSeeker, sKey) => {
            const seeker = groupedSeekers[sKey][0];

            return {
              key: `${pKey}_${bKey}_${sKey}`,
              type: NodeType.CANDIDATE,
              item: {
                candidate: seeker,
                candidateName: `${seeker.firstName} ${seeker.lastName}`,
                lastActivity: getLatestDate(seeker),
                currentId: currentUserId,
                notificationBadgeValue: positionsUnreadCount(seekers, currentUserId),
                minutesBeforeInactivity,
              } as CandidateItemNode,
            } as TreeGroup;
          });

          return {
            key: `${bKey}_${pKey}`,
            type: NodeType.CHILD,
            item: {
              title: position.jobTitle,
              childCount: candidateMapped.length,
              lastActivity: getLatestDateFromMappedCandidate(candidateMapped),
            } as ParentItemNode,
            nodes: sortedCandidateMapped(candidateMapped),
          } as TreeGroup;
        });

        return {
          key: `${bKey}`,
          type: NodeType.PARENT,
          item: {
            title: business.businessName,
            childCount: positionMapped.length,
            lastActivity: getLatestDateFromMappedCandidate(positionMapped),
          } as ParentItemNode,
          nodes: sortedPositionMapped(positionMapped),
        } as TreeGroup;
      });

      return sortedBusinessLocationMapped(businessLocationMapped);
    };

    const groupByPosition = (seekerList: EngagedCandidate[]): TreeGroup[] => {
      const positionGrouped = _groupBy(seekerList, (e) => {
        return getTranslatedValue(e.jobTitle, locale);
      });

      const positionMapped = _map(positionGrouped, (positionSeekers, pKey) => {
        const position = positionGrouped[pKey][0];

        const businessGrouped = _groupBy(positionSeekers, 'businessName');
        const businessMapped = _map(businessGrouped, (businessSeekers, bKey) => {
          const business = businessGrouped[bKey][0];
          const sortedBusinessSeekers = sortEngagedCandidates(businessSeekers);

          const groupedSeekers = _groupBy(sortedBusinessSeekers, 'seeker');
          const candidateMapped = _map(groupedSeekers, (groupedSeeker, sKey) => {
            const seeker = groupedSeekers[sKey][0];

            return {
              key: `${pKey}_${bKey}_${sKey}`,
              type: NodeType.CANDIDATE,
              item: {
                candidate: seeker,
                currentId: currentUserId,
                notificationBadgeValue: positionsUnreadCount(seekers, currentUserId),
                lastActivity: getLatestDate(seeker),
                minutesBeforeInactivity,
              } as CandidateItemNode,
            } as TreeGroup;
          });

          return {
            key: `${pKey}_${bKey}`,
            type: NodeType.CHILD,
            item: {
              title: business.businessName,
              childCount: candidateMapped.length,
              lastActivity: getLatestDateFromMappedCandidate(candidateMapped),
            } as ParentItemNode,
            nodes: sortedCandidateMapped(candidateMapped),
          } as TreeGroup;
        });

        return {
          key: `${pKey}`,
          type: NodeType.PARENT,
          item: {
            title: position.jobTitle,
            childCount: businessMapped.length,
            lastActivity: getLatestDateFromMappedCandidate(businessMapped),
          } as ParentItemNode,
          nodes: sortedPositionMappedNotLocalized(businessMapped),
        } as TreeGroup;
      });
      return sortedPositionMapped(positionMapped);
    };

    const groupByCandidate = (seekerList: EngagedCandidate[]): TreeGroup[] => {
      const sortedSeekers = sortEngagedCandidates(seekerList);

      const groupedSeekers = _groupBy(sortedSeekers, 'seeker');
      return _map(groupedSeekers, (groupedSeeker, sKey) => {
        const seeker = groupedSeekers[sKey][0];

        return {
          key: sKey,
          type: NodeType.CANDIDATE,
          item: {
            candidate: seeker,
            currentId: currentUserId,
            notificationBadgeValue: positionsUnreadCount(seekers, currentUserId),
            minutesBeforeInactivity,
          } as CandidateItemNode,
        } as TreeGroup;
      });
    };

    switch (groupType) {
      case SeekerListGroupType.BUSINESS_LOCATION:
        return groupByBusinessLocation(seekers);
      case SeekerListGroupType.POSITION:
        return groupByPosition(seekers);
      case SeekerListGroupType.NONE:
        return groupByCandidate(seekers);
      // istanbul ignore next
      default:
        return [];
    }
  };

  getFirstEngagedCandidate(candidateId: string): Readonly<EngagedCandidate> | undefined {
    return _find(this.candidateListItems, ({ id }) => id === candidateId) || _head(this.candidateListItems);
  }
}
